import React from 'react'
import * as Wrapper from './styles'
import { SampleAccordianContent } from '../SampleAccordianContent'
import { ReportDetailsHeader } from '../ReportDetailsHeader'
import { ProgressIndicator } from '@veneer/core'

export const PolicyItemAssesedCard = (props) => {
  const { textInfo, title, data, isLoading } = props

  return (
    <Wrapper.ReportDetailsCard data-testid="reports_Details_Policy_Item_Assesed_Component">
      <Wrapper.ReportDetailsTitleCard data-testid="reports_Details_Policy_Item_Assesed_Title_Component">
        <ReportDetailsHeader
          data-testid="reports_Details_Policy_Item_Assesed_Title"
          id="reports_Details_Policy_Item_Assesed_Title"
          title={title}
          hideButton={true}
        />
      </Wrapper.ReportDetailsTitleCard>

      {isLoading ? (
        <ProgressIndicator
          data-testid="reports_Details_Progress_Indicator"
          id="reports_Details_Progress_Indicator"
          style={{ padding: '3% 50%' }}
          appearance={'circular'}
        />
      ) : (
        <Wrapper.PolicyContainer data-testid="reports_Details_Policy_Item_Assesed_Content">
          <Wrapper.TextInfo
            data-testid="reports_Details_Policy_Item_Assesed_Content_Text"
            id="reports_Details_Policy_Item_Assesed_Content_Text"
          >
            <>{textInfo}</>
          </Wrapper.TextInfo>{' '}
          <SampleAccordianContent
            data-testid="reports_Details_Policy_Item_Assesed_Content_SubAccordian"
            id="reports_Details_Policy_Item_Assesed_Content_SubAccordian"
            data={data}
          />{' '}
        </Wrapper.PolicyContainer>
      )}
    </Wrapper.ReportDetailsCard>
  )
}
