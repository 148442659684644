import React, { useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ThemeProvider, Search,  Button,  IconSearch } from '@veneer/core'
import * as Wrapper from './styles'

export const SearchList = ({
  handleSearch,
  searchText,
  setSearchText,
  setCurrentPage,
  isLoading = false,
  errorDisabled = false,
  isMobileView = false,
  ...props
}) => {
  const { t } = useI18n()

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleButtonClick = () => {
    props.setIsSearchOpen(prevIsSetOpen => !prevIsSetOpen)
    setIsFormVisible(prevIsFormVisible => !prevIsFormVisible);
  };

  const searchFunctionFormMobileView = () => {
    return (
      <Wrapper.ReportSearchBoxMobileView id="reports-search-box-section-mobile">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (searchText.length >= 3) {
              handleSearch(searchText)
            } else if (searchText === '') {
              handleSearch('')
            }
            setCurrentPage(1)
          }}
          data-testid="reports-search-form"
          style={{
            width: '272px'
          }}
        >
          <ThemeProvider density="high">
            <Search
              id="controlled-search"
              data-testid="reports-search-box"
              placeholder={t('ecp-global-reports.search', 'Search')}
              disabled={isLoading || errorDisabled}
              value={searchText}
              onChange={setSearchText}
              onClear={() => {
                setSearchText('')
                handleSearch('')
              }}
            />
          </ThemeProvider>
        </form>
      </Wrapper.ReportSearchBoxMobileView>
    )
  }

  const searchFunctionFormNormalView = () => {
    return (
      <Wrapper.ReportSearchBoxNormalView id="reports-search-box-section">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (searchText.length >= 3) {
              handleSearch(searchText)
            } else if (searchText === '') {
              handleSearch('')
            }
            setCurrentPage(1)
          }}
          data-testid="reports-search-form"
        >
          <ThemeProvider density="high">
            <Search
              id="controlled-search"
              data-testid="reports-search-box"
              placeholder={t('ecp-global-reports.search', 'Search')}
              disabled={isLoading || errorDisabled}
              value={searchText}
              onChange={setSearchText}
              onClear={() => {
                setSearchText('')
                handleSearch('')
              }}
            />
          </ThemeProvider>
        </form>
      </Wrapper.ReportSearchBoxNormalView>
    )
  }


  return (
    <div>
      {isMobileView ? (
        <Button  
          appearance="tertiary"
          leadingIcon={<IconSearch />}
          onClick={handleButtonClick}
        />
      ) : searchFunctionFormMobileView()}
      {(isFormVisible && isMobileView) && searchFunctionFormNormalView()}
    </div>
  );
};



