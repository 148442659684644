//Shareable component for all the preview Main card reports
import React, { useState } from 'react'
import { Accordion } from '@veneer/core'

export const ReportDetailsAccordion = (props) => {
  const { content } = props

  const [data, setData] = useState(content)

  const handleExpand = (event, index, item) => {
    const updatedData = [...data]
    updatedData[index].expanded = true
    setData(updatedData)
  }

  const handleCollapse = (event, index, item) => {
    const updatedData = [...data]
    updatedData[index].expanded = false
    setData(updatedData)
  }

  return (
    <Accordion
      data-testid="device_Details_Accordian"
      appearance="dropShadow"
      key="accordion1"
      items={data}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
    />
  )
}
