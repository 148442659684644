import styled from 'styled-components'

export const ReportDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReportDetailsTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
`

export const ButtonContainer = styled(ReportDetailsHeader)`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
`
