import { DeviceCacheApiClient } from '@jarvis/web-stratus-client'

export class DevicesClient {
  stack
  baseUrl
  authProvider
  constructor(baseUrl, authProvider) {
    this.baseUrl = baseUrl + '/v1'
    this.authProvider = authProvider
  }
  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }

  async getDevices(deviceOptions) {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.list({
      params: deviceOptions
    })
    if (this.isStatusCodeOk(response.status)) return response.data

    throw response.statusText
  }

  async getDevicesByID(deviceId) {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.show(deviceId)
    if (this.isStatusCodeOk(response.status)) return response.data
    throw response.statusText
  }
}
