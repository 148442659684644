const REPORT_READ_SCOPE = 'ws-hp.com/reportsvc/report.READ'
const REPORT_WRITE_SCOPE = 'ws-hp.com/reportsvc/report.WRITE'
const REPORT_DELETE_SCOPE = 'ws-hp.com/reportsvc/report.DELETE'
const COLLECTION_READ_SCOPE = 'ws-hp.com/collections/contents.collections.READ'
const PARTNERLINK_READ_SCOPE = 'ws-hp.com/partnerlinksvc/subscription.READ'

// const REPORT_OWNER = 'ws-hp.com/smcloud/reports.OWNER'

export const READ_SCOPES = [{ scope: REPORT_READ_SCOPE }]
export const WRITE_SCOPES = [{ scope: REPORT_WRITE_SCOPE }]
export const DELETE_SCOPES = [{ scope: REPORT_DELETE_SCOPE }]
export const JOBHISTORY_READ_SCOPES = [{ scope: PARTNERLINK_READ_SCOPE }]
export const COLLECTIONS_READ_SCOPES = [{ scope: COLLECTION_READ_SCOPE }]
