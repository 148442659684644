import styled from 'styled-components'

export const EditReportDeviceGroupCard = styled.div`
  width: 932px;
  min-height: 73px;
  // margin-left: 24px;
  margin-top: 32px;
`

export const EditReportDeviceGroupHeader = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 4px;
`

export const EditReporteDivider = styled.div`
  border-bottom: 1px solid #ebebeb;
`

export const EditReporteDeviceGroupContent = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
  // margin-bottom: 24px;
  word-break: break-word;
`
