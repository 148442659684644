import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'

import { ReportDetailsAccordion } from '../ReportDetailsAccordion'
import { ReportDetailsDesc } from '../ReportDetailsDesc'
import { ReportDetailsTopCardHeader } from '../ReportDetailsTopCardHeader'

import * as Wrapper from './styles'
import { getDateAndTime } from '../../../../utils/commonMethods'
// import Tooltip from '@veneer/core/dist/scripts/tooltip'
// import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { ProgressIndicator } from '@veneer/core'
import GeneralContext from '../../../../contexts/GeneralContext'

export const SummaryTopCard = (props) => {
  const { locale } = useContext(GeneralContext)
  const { t } = useI18n()
  const {
    reportDetails,
    deviceDetails,
    groupDetails,
    policyDetails,
    customerName,
    isLoading
  } = props
  const { reportId, reportName, fileName, generatedTime, category } =
    reportDetails || {}
  const { serialNumber, status, policy, deviceName, image } =
    deviceDetails || {}

  const downloadData = [
    {
      id: reportId,
      type: reportName,
      category: category,
      generatedTime: generatedTime,
      name: fileName + '-' + deviceName,
      deviceName: deviceName
    }
  ]
  const reportDatas = [
    {
      title: t('ecp-global-reports.reportDetails.report', 'Report Type'),
      desc: (
        <div
          data-testid="Summary_ReportName"
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: '4px'
          }}
        >
          {t(`ecp-global-reports.reportsType.${reportName}`, '')}{' '}
          {/* <Tooltip
            data-testid="Summary_ReportName_Tooltip"
            content={t(`ecp-global-reports.reportsType.${reportName}`, '')}
            id="tooltip"
            placement="trailing"
          >
            <IconInfo size={24} color="colorGray5" />
          </Tooltip> */}
        </div>
      )
    },
    {
      title: t('ecp-global-reports.reportDetails.generated', 'Generated'),
      desc: getDateAndTime(generatedTime || null, locale)
    },
    {
      title: t('ecp-global-reports.reportDetails.deviceGroup', 'Device Group'),
      desc: groupDetails?.reportGroupInfo
    },
    {
      title: t('ecp-global-reports.reportDetails.customer', 'Customer'),
      desc: customerName
    }
  ]
  const deviceDatas = [
    {
      key: 'serialnumber',
      title: t('ecp-global-reports.tableHeader.serialNumber', 'Serial Number'),
      desc: serialNumber || '--'
    },

    {
      key: 'assessmentstatus',
      title: t(
        'ecp-global-reports.tableHeader.assessmentStatus',
        'Assessment Status'
      ),
      desc: t(`ecp-global-reports.risks.${status}`, '--'),
      id: status
    },
    {
      key: 'policy',
      title: t('ecp-global-reports.tableHeader.policy', 'Policy'),
      desc: policy
    }
  ]
  const content = [
    {
      header: {
        centralArea: (
          <ReportDetailsTopCardHeader
            title={deviceName}
            downloadData={downloadData}
            hideButton={false}
            showXlsx={false}
          />
        )
      },
      content: (
        <Wrapper.ReportDetailsDescContent>
          <ReportDetailsDesc
            reportDatas={reportDatas}
            reportDeviceDatas={deviceDatas}
            policyDetails={policyDetails}
            reportDataImg={image}
          />
        </Wrapper.ReportDetailsDescContent>
      ),
      expanded: true,
      id: 'data-testid="Summary_Details_Accordion"'
    }
  ]

  return !isLoading ? (
    <ReportDetailsAccordion
      data-testid="reports_Details_TopCard"
      id="reports_Details_TopCard"
      content={content}
    />
  ) : (
    <ProgressIndicator
      data-testid="reports_Details_Progress_Indicator"
      id="reports_Details_Progress_Indicator"
      style={{ padding: '3% 50%' }}
      appearance={'circular'}
    />
  )
}
