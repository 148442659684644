import styled from 'styled-components'

export const RDAccordionTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`
export const ReportDetailsChartDesc = styled.div``

export const ReportDetailsChartDescTitle = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #2b2b2b;
  text-align: center;
  margin-bottom: 8px;
`
export const ReportDetailsChartDescContent = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`

export const ReportDetailsChartCard = styled.div`
  min-width: 40%;
`

export const ReportDetailsCard = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1212px) and (min-width: 992px),
    (max-width: 950px) {
    flex-wrap: wrap;
  }
  /* grid-template-columns: 1fr 3px 1fr; */
  gap: 24.5px;
`
export const ReportDetailsChartCardDivider = styled.div`
  width: 8px;
  background: #e9e9e9;
  border-radius: 1.5px;
`
