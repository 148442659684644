import { useReports } from '../hooks/useReports'
import { useContext, useEffect, useState } from 'react'
import GeneralContext from '../contexts/GeneralContext'
import { useI18n } from '@jarvis/react-portal-addons'

export const useGetPolicyDetails = (reportId, deviceId) => {
  const { t } = useI18n()
  const [policyDetails, setPolicyDetails] = useState({
    policyString: '',
    policyTooltip: ''
  })
  const [policyLoading, setPolicyLoading] = useState(true)
  const { stack } = useContext(GeneralContext)
  const { downLoadingReports } = useReports()

  const getPolicyDetails = async () => {
    await downLoadingReports(reportId, stack)
      .then((response) => {
        const policyData = JSON.parse(response[0]).deviceList.filter(
          (e) => e.deviceDetails?.deviceId === deviceId
        )
        let policyString = ''
        let policyCount = 0
        Object.keys(policyData[0].assessmentStatus.policyIdToDetails).forEach(
          (key, i) => {
            policyCount = policyCount + 1
            policyString =
              policyString +
              (policyString !== '' ? ', ' : '') +
              policyData[0].assessmentStatus.policyIdToDetails[key].policyName
          }
        )
        setPolicyDetails({
          policyString: `${
            policyString.split(', ')[0].length < 35
              ? policyString.split(', ')[0]
              : policyString.split(', ')[0].substring(0, 35) + '...'
          } ${
            policyString.split(', ').length > 1
              ? `& ${policyCount - 1} ${t(
                  'ecp-global-reports.generate.deviceGroupSelect.more',
                  'more'
                )}`
              : ''
          }`,
          policyTooltip: policyString.replace(
            /(.*)\,/gm,
            `$1 ${t('ecp-global-reports.common.and', 'and')}`
          )
        })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setPolicyLoading(false)
      })
  }

  useEffect(() => {
    getPolicyDetails()
  }, [])

  return { policyDetails, policyLoading }
}
