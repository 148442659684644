import { useContext } from 'react'
import GeneralContext from '../contexts/GeneralContext'

import pie from './pie.json'
import dev from './dev.json'
import prod from './prod.json'
import stage from './stage.json'

interface ReportType {
  id: string
  enable: boolean
  sample: {
    fileName: string
    imageHeight: number
    enable: boolean
  }
  reportRange: {
    enable: boolean
  }
  scope: string
}

interface ReportCategory {
  id: string
  label: string
  enable: boolean
  permissions: {
    deviceCache?: boolean
    scope: string
  }
  reportTypes: ReportType[]
  reportName: {
    charactersExcluded: string[]
    enable: boolean
  }
}

interface DeviceGroup {
  scope: string
  enable: boolean
}

interface Schedule {
  scope: string
  enable: boolean
}

interface Email {
  scope: string
  enable: boolean
}

interface Config {
  reportCategories: ReportCategory[]
  deviceGroup: DeviceGroup
  schedule: Schedule
  email: Email
}

const Config = () => {
  const { stack } = useContext(GeneralContext)
  const config: Config[] = [dev, pie, stage, prod]

  return config[stack]
}
export default Config
