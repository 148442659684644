import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ProgressIndicator, Card } from '@veneer/core'
import { useParams } from 'react-router-dom'

import { PolicyItemAssesedAccordian } from '../Common/PolicyItemAssesedAccordian'
import { Footer } from '../Common/Footer'

import { useReports } from '../../../hooks/useReports'
import GeneralContext from '../../../contexts/GeneralContext'
import { ReportDetailsCard } from '../Common/ReportDetailsCard'
import { selectedGroupsFormat } from '../../../utils/genericMethods'
import { setBreadcrumbs } from '../../../utils/commonMethods'
import * as Wrapper from './styles'

export const PolicyItemsAssessed = () => {
  const { t } = useI18n()
  const { stack, breadcrumbs, navigation } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId } = useParams()
  const [policyData, setPolicyData] = useState([])
  const [reportDetails, setReportDetails] = useState(null)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [customerName, setcustomerName] = React.useState('--')
  const [policyReportData, setPolicyReportData] = useState([])
  const [collectionInfo, setCollectionInfo] = useState([])

  const isData = (data) => {
    return !(data.length === 1 && data[0].totalDeviceCount === 0)
  }

  const reloadDeviceList = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        JSON.parse(response[0])?.collectionInfo &&
          setCollectionInfo(JSON.parse(response)?.collectionInfo)
        JSON.parse(response[0])?.customerName &&
          setcustomerName(JSON.parse(response)?.customerName)
        if (isData(response)) {
          setPolicyData(createTableData(response))
          setPolicyReportData(response)
        } else {
          setPolicyData([])
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
        // setBreadcrumb(
        //   ReportDetailsBreadcrumData(response.fileName || response.reportName)
        // )
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }
  const createTableData = (settings) => {
    const data = {
      reportData: settings.map((element) => {
        return JSON.parse(element)
      })
    }
    return policySettingsData(data.reportData)
  }

  const policySettingsData = (settings) => {
    var policyData = []

    const data = settings[0]?.policySettingsReportList || settings
    data.map((policy) => {
      const CatId: any = Object.keys(policy)[0]
      policyData.push({
        centralArea: (
          <span>{t(`ecp-global-reports.tableHeader.${CatId}`, '')}</span>
        ),
        endArea: <></>,
        expanded: true,
        innerItems: PolicyDeviceStatus(policy, CatId)
      })
    })
    return policyData
  }

  const PolicyDeviceStatus = (status, CatId) => {
    var innerItemData = []

    status[CatId].forEach((item) => {
      item.deviceStatus.forEach((deviceStatus) => {
        innerItemData.push({
          col1:
            item?.settingId &&
            t(`ecp-global-reports.settings.${item.settingId}.name`, ''),
          col2: `${t('ecp-global-reports.tableHeader.count', 'Count')}: ${
            deviceStatus.count
          }`,
          risk: deviceStatus.assessmentStatus
        })
      })
    })
    return innerItemData
  }

  const policySettingsDataExport = (settingsExport) => {
    const dataExport = {
      reportData: settingsExport.map((element) => {
        return JSON.parse(element)
      })
    }
    const settings = dataExport.reportData
    var policyData = []

    const data = settings[0]?.policySettingsReportList || settings
    data.map((policy) => {
      var temp: any = []
      const CatId: any = Object.keys(policy)[0]
      temp = policyDeviceStatusExport(policy, CatId)
      temp.map((itemData) => policyData.push(itemData))
    })
    return policyData
  }

  const policyDeviceStatusExport = (status, CatId) => {
    var innerItemData = []

    status[CatId].forEach((item) => {
      item.deviceStatus.forEach((deviceStatus) => {
        const riskid =
          deviceStatus.assessmentStatus !== 'passed'
            ? deviceStatus.assessmentStatus + 'Risk'
            : deviceStatus.assessmentStatus
        innerItemData.push({
          policyName: t(`ecp-global-reports.tableHeader.${CatId}`, ''),
          risk: t(`ecp-global-reports.risks.${riskid}`, ''),
          itemName:
            item?.settingId &&
            t(`ecp-global-reports.settings.${item.settingId}.name`, ''),
          count: deviceStatus.count
        })
      })
    })
    return innerItemData
  }

  const headers = [
    {
      key: 'policyName',
      label: t('ecp-global-reports.tableHeader.policy', 'Policy')
    },
    {
      key: 'risk',
      label: t(
        'ecp-global-reports.tableHeader.assessmentStatus',
        'Assessment Status'
      )
    },
    {
      key: 'itemName',
      label: 'Item'
    },
    {
      key: 'count',
      label: t('ecp-global-reports.tableHeader.count', 'Count')
    }
  ]

  useEffect(() => {
    reloadDeviceList()
    reloadReportDetails()
  }, [reportId])

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  return (
    <Wrapper.RDContainer data-testid="policy_Items_Assessed">
      {reportDetailsLoading || loading ? (
        <Card
          border="dropShadow"
          content={
            (
              <ProgressIndicator
                data-testid="reports_Progress_Indicator"
                style={{ padding: '3% 50%' }}
                appearance={'circular'}
              />
            ) as any
          }
        />
      ) : !loading ? (
        <ReportDetailsCard
          data-testid="policy_Items_Assessed_Top_Card"
          reportDetails={reportDetails}
          deviceGroupData={deviceGroupData}
          customerName={customerName}
          headers={headers}
          exportData={policySettingsDataExport(policyReportData)}
          imgDisplay={false}
        />
      ) : (
        ''
      )}
      <div>
        {loading ? (
          <ProgressIndicator
            data-testid="reports_Progress_Indicator"
            style={{ padding: '3% 50%' }}
            appearance={'circular'}
          />
        ) : (
          <PolicyItemAssesedAccordian
            data-testid="policy_Items_Assessed_Count_Card"
            textInfo={t(
              'ecp-global-reports.policyItemAssessed.info',
              'This list provides a count of recommendations and passes for policy items of all devices within the selected group. It includes all assessed policies. The items are grouped by security feature (authentication, protect the device, etc.).'
            )}
            data={policyData}
            title={t(
              'ecp-global-reports.policyItemAssessed.title',
              'Policy items assessed count'
            )}
          />
        )}
      </div>
      <Wrapper.RDSubContainer data-testid="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
