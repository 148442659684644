import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'

import { DevicesAssessedSummary } from './DevicesAssessed/Summary'
import { RemediationSummary } from './Remediation/Summary'
import { RecommendationsSummary } from './Recommendations/Summary'

import { useDevices } from '../../hooks/useDevices'
// import { ReportDeviceBreadcrumData } from '../../utils/consts'
import { useReports } from '../../hooks/useReports'
import GeneralContext from '../../contexts/GeneralContext'
import { ProgressIndicator } from '@veneer/core'
import { setBreadcrumbs } from '../../utils/commonMethods'

export const ReportSummary = () => {
  const { t } = useI18n()
  const { navigation, breadcrumbs, stack, authProvider } =
    useContext(GeneralContext)
  // const { setBreadcrumb } = useContext(GeneralContext)
  const { reportType, reportId, deviceId, deviceReportId, reportCategory } =
    useParams()
  const { fetchDevicesByID } = useDevices(stack, authProvider)
  const { fetchReportsByID } = useReports()

  const [reportDetails, setReportDetails] = useState(null)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [deviceDetails, setDeviceDetails] = useState(null)
  const [deviceName, setDeviceName] = useState(null)
  // const [component, setComponent] = useState(null)

  const reloadDeviceDetails = async () => {
    await fetchDevicesByID(deviceId)
      .then((device) => {
        let groupNames = ''
        device?.groups?.forEach((group) => {
          groupNames = groupNames.concat(group.label + ' ')
        })
        setDeviceName(device?.identity?.makeAndModel?.name)
        setDeviceDetails({
          deviceName: device?.identity?.makeAndModel?.name,
          image:(device?.images && device?.images[1]?.url),
          serialNumber: device?.identity?.serialNumber,
          groups: groupNames,
          status:
            device?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe?.result,
          policy:
            device?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe
              ?.securityPolicy
        })
      })
      .catch((e) => {
        setDeviceDetails({})
        setDeviceName('Unknown')
        console.log(e)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(deviceReportId)
      .then((response) => {
        setReportDetails(response)
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }

  useEffect(() => {
    if (!reportDetailsLoading && deviceName !== null) {
      setBreadcrumbs(breadcrumbs, navigation, t, {
        name: reportDetails.fileName,
        reportCategory,
        reportType,
        reportId,
        deviceName
      })
    }
  }, [reportDetailsLoading, deviceName])

  const fetchComponent = () => {
    if (!reportDetailsLoading && deviceName !== null) {
      switch (reportType) {
        case 'devicesAssessmentSummary':
          // setComponent(
          return (
            <DevicesAssessedSummary
              deviceDetails={deviceDetails}
              reportDetails={{
                ...reportDetails,
                reportName: 'deviceAssessmentDetails'
              }}
            />
          )
          // )
          break
        case 'devicesRemediationSummary':
          // setComponent(
          return (
            <RemediationSummary
              deviceDetails={deviceDetails}
              reportDetails={{
                ...reportDetails,
                reportName: 'deviceRemediationDetails'
              }}
            />
          )
          // )
          break
        case 'devicesRecommendationSummary':
          // setComponent(
          return (
            <RecommendationsSummary
              deviceDetails={deviceDetails}
              reportDetails={{
                ...reportDetails,
                reportName: 'deviceRecommendationDetails'
              }}
            />
          )
          // )
          break
        default:
          // setComponent(
          return (
            <div>
              {t(
                'ecp-global-reports.toastNotification.notValidReport',
                'Report Type is not valid'
              )}
            </div>
          )
        // )
      }
    }
  }

  useEffect(() => {
    reloadDeviceDetails()
    reloadReportDetails()
  }, [deviceId, reportId])

  return reportDetailsLoading || deviceName === null ? (
    <ProgressIndicator style={{ padding: '3% 50%' }} appearance={'circular'} />
  ) : (
    fetchComponent()
  )
}
