import React, { useContext } from 'react'
import { setBreadcrumbs } from '../../utils/commonMethods'
import { useI18n } from '@jarvis/react-portal-addons'

import { ReportEditor } from './EditReportScreen'

import GeneralContext from '../../contexts/GeneralContext'
import * as Wrapper from './styles'

export const EditReportDetails = () => {
  const { t } = useI18n()
  const { navigation, breadcrumbs } = useContext(GeneralContext)
  setBreadcrumbs(breadcrumbs, navigation, t)
  return (
    <Wrapper.GenerateDetailsComponent>
      <ReportEditor />
    </Wrapper.GenerateDetailsComponent>
  )
}
