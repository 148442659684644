import React, { useState } from 'react'
import { ListItem, List } from '@veneer/core'
import highchartsInterface, {
  ChartProvider,
  Chart
} from '@veneer/highcharts-interface'
import { useI18n } from '@jarvis/react-portal-addons'
import { Colors } from '../../../utils/consts'
import * as Wrapper from './styles'

function countTotalOfCategories(categories) {
  let total = 0
  for (let i = 0; i < categories.length; i++) {
    total += categories[i].CategoryCount
  }
  return total
}
const Donut = (props) => {
  const { data, type } = props
  const { t } = useI18n()
  const displayLegend = data[0].Legends
  const donutText = data[0].DonutDiplayText
  const donuSubText = data[0].DonutDiplaySubText
  const categories = data[0].CategoryList
  const dataForDonut = []
  const totalCountOfAllCategories = countTotalOfCategories(categories)
  const donutColors = []
  const Square = (color) => {
    return (
      <Wrapper.ReportsDonutPieSymbol
        data-testid="reports-pie-legends-Symbol"
        style={{
          backgroundColor: color
        }}
      />
    )
  }

  const modified = []
  for (let i = 0; i < categories.length; i++) {
    let item
    const categoryItem = (
      <>
        {categories[i].CategoryTitle && categories[i].CategoryTitle !== '' ? (
          <Wrapper.ReportsDonutDisplayCount>
            {categories[i].DisplayCount
              ? categories[i].CategoryTitle + ': ' + categories[i].CategoryCount
              : categories[i].CategoryTitle}
          </Wrapper.ReportsDonutDisplayCount>
        ) : null}
      </>
    )

    modified.push(categoryItem)
    for (let j = 0; j < categories[i].data.length; j++) {
      const dataItem = categories[i].data[j]
      const itemTotal =
        dataItem.Count == undefined ? undefined : parseInt(dataItem.Count, 10)
      dataForDonut.push([dataItem.ItemDisplayText, itemTotal])
      donutColors.push(dataItem.Color)
      const percent =
        totalCountOfAllCategories > 0
          ? (itemTotal * 100) / totalCountOfAllCategories
          : 0
      item = (
        <Wrapper.ReportsChartList>
          <ListItem
            key={dataItem.ItemDisplayText}
            className="list-item"
            style={{ borderBottom: 0, padding: 0 }}
            leadingArea={Square(dataItem.Color)}
            centerArea={
              <Wrapper.ReportsListItem1>
                {`
                ${dataItem.ItemDisplayText}: ${
                  itemTotal === undefined
                    ? '--'
                    : `${percent.toFixed(2)}% (${itemTotal})`
                }
                `}
              </Wrapper.ReportsListItem1>
            }
            trailingArea={''}
          />
        </Wrapper.ReportsChartList>
      )
      modified.push(item)
      for (let k = 0; k < dataItem.SubItem.length; k++) {
        const subItem = dataItem.SubItem[k]
        const subItemCount =
          subItem.SubItemCount == undefined
            ? undefined
            : parseInt(subItem.SubItemCount, 10)
        const percent =
          totalCountOfAllCategories > 0
            ? (subItemCount * 100) / totalCountOfAllCategories
            : 0
        const subItemModified = (
          <div>
            <ListItem
              key={subItem.ItemDisplayText}
              className="list-item"
              style={{ borderBottom: 0, padding: '0 0 12px 0' }}
              leadingArea={Square('transparent')}
              centerArea={
                <Wrapper.ReportsListItem2>
                  {`${subItem.ItemDisplayText}: ${
                    subItemCount === undefined
                      ? '--'
                      : `${percent.toFixed(2)}% (${subItemCount})`
                  }`}
                </Wrapper.ReportsListItem2>
              }
              trailingArea={''}
            />
          </div>
        )
        modified.push(subItemModified)
      }
    }
    const space = <Wrapper.ReportsSpacing />
    modified.push(space)
  }

  return (
    <Wrapper.ReportsDonutComponent data-testid="reports_Donut_Component">
      <Wrapper.ReportsPDFDonut>
        <Wrapper.ReportsChart type={type}>
          <Wrapper.ReportsDonutPieChartComponent data-testid="reports_Pie_Chart_Component">
            <Wrapper.ReportsDonutPieChart data-testid="reports_Pie_Chart">
              <ChartProvider
                chartInterface={highchartsInterface}
                data-testid="reports_Pie_Chart_Provider"
              >
                {totalCountOfAllCategories > 0 ? (
                  <Chart
                    data-testid="reports_Pie_Chart_WithData"
                    options={{
                      chart: {
                        type: 'donut',
                        height: 200,
                        width: 200,
                        margin: -70
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      colors: [...donutColors],
                      plotOptions: {
                        series: {
                          animation: true,
                          enableMouseTracking: true
                        },
                        pie: {
                          states: {
                            // hover: {
                            //   brightness: 0,
                            //   halo: {
                            //     opacity: 0,
                            //     size: 0
                            //   }
                            // },
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: donutText,

                        style: {
                          fontSize: '40px',
                          lineHeight: '48px',
                          color: Colors.gray10
                        }
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          position: 'static',
                          fontSize: '14px',
                          top: '48px',
                          lineHeight: '20px',
                          height: '40px',
                          color: Colors.gray5
                        }
                      },
                      series: [
                        {
                          name: t(
                            'ecp-global-reports.common.noOfDevice',
                            'No. of device'
                          ),
                          data: dataForDonut
                        }
                      ],

                      segmentShowStroke: false
                    }}
                  />
                ) : (
                  <Chart
                    data-testid="reports_Pie_Chart_withNoDevices"
                    options={{
                      chart: {
                        type: 'donut',
                        height: 200,
                        width: 200,
                        margin: -70
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      plotOptions: {
                        series: {
                          animation: false
                        },
                        pie: {
                          colors: [Colors.gray1],
                          states: {
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: donutText,
                        style: {
                          fontSize: '40px',
                          lineHeight: '48px',
                          color: Colors.gray10
                        }
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          position: 'static',
                          fontSize: '14px',
                          top: '48px',
                          lineHeight: '20px',
                          height: '40px',
                          color: Colors.gray5
                        }
                      },
                      series: [
                        {
                          name: t(
                            'ecp-global-reports.common.noDevice',
                            'No Device'
                          ),
                          data: [['notassessed', 1]]
                        }
                      ],
                      tooltip: {
                        formatter: () => false
                      }
                    }}
                  />
                )}
              </ChartProvider>
            </Wrapper.ReportsDonutPieChart>
          </Wrapper.ReportsDonutPieChartComponent>

          {displayLegend === 'true' ? (
            <Wrapper.ReportsDonutLegendsList data-testid="reports_Pie_Chart_Legends">
              <List data-testid="reports_Pie_Chart_Legends_List">
                <Wrapper.ReportsDonutLegends type={type}>
                  {modified}
                </Wrapper.ReportsDonutLegends>
              </List>
            </Wrapper.ReportsDonutLegendsList>
          ) : null}
        </Wrapper.ReportsChart>
      </Wrapper.ReportsPDFDonut>
    </Wrapper.ReportsDonutComponent>
  )
}
export default Donut
