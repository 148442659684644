import styled from 'styled-components'
import { Button } from '@veneer/core'
import { layout, typography } from '@veneer/tokens/dist/tokens'

export const EditReportHeader = styled.div``

export const EditReportTitle = styled.p`
  position: static;
  width: 972px;
  height: 36px;
  left: 0px;
  top: 0px;
  font-size: 28px;
  line-height: 36px;
  color: #212121;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const GenerateReportsCardContent = styled.div`
  padding: 24px;
  height: 100%;
`

export const EditReportSubTitle = styled.p`
  position: static;
  width: 972px;
  height: 24px;
  left: 0px;
  top: 44px;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 8px;
  margin-bottom: 19px;
`
export const Container = styled.div`
  margin: 24px;
`
export const GenerateDetailsComponent = styled.div`
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
`

export const ReportContentsHeader = styled.h3`
  font-size: 12px;
  line-height: 16px;
  color: #404040;
`

export const ReportContents = styled.div`
  font-family: ${typography.family0};
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`

export const EditReportTextBox = styled.div`
  position: static;
  width: 456px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 2px 0px;
  & * {
    min-height: none;
    max-height: 100%;
  }
`

export const EditReportNameCard = styled.div`
  width: 456px;
  height: 58px;
`

export const EditReportTypeCard = styled.div`
  width: 456px;
  height: 40px;
  margin-bottom: 16px;
`

export const EditReportDetailsCard = styled.div`
  width: 456px;
  // margin-top: 24px;
  // margin-left: 24px;
`

export const EditReportMainCard = styled.div`
  position: absolute;
  width: 972px;
  height: 336px;
  left: 0px;
  top: 3px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: 12px;
`

export const ReportsGenerateContextualFooterMenu = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: block;
  margin: 0;
  height: 60px;
  right: 0;
  z-index: 3;
  background: #ebf9ff;
  li {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 1px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: flex-end;
    padding: 12px ${layout.size6};
    padding-left: 284px;
    position: relative;
    width: 100%;
    background: #ebf9ff;
  }
`

export const EditReportContextualCancelButton = styled(Button)`
  height: 36px;
  width: 112px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 8px, 20px, 8px, 20px;
`

export const EditReportContextualEditButton = styled(Button)`
  height: 36px;
  width: 112px;
  left: 10px;
  top: 0px;
  border-radius: 8px;
  padding: 8px, 20px, 8px, 20px;
`

export const EditReportDeviceGroupCard = styled.div`
  position: static;
  width: 932px;
  height: 72px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
`

export const EditReportDeviceGroupDivider = styled.div`
  border-top: 2px solid #ebebeb;
  margin-top: 4px;
`

export const EditReportDeviceGroupHeader = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`

export const EditReportDeviceGroupContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  margin-top: 18px;
`
export const EditReportNameHeader = styled.p`
  width: 456px;
  height: 20px;
  margin-bottom: 2px;

  font-size: 16px;
  line-height: 20px;
  color: #404040;
  display: flex;
`

export const EditReportCategoryCard = styled.p`
  width: 456px;
  height: 40px;
  margin-bottom: 16px;
`

export const Loader = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`
