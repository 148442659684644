import React from 'react'
import Reports from '../src/components/Reports'
import { ShellProps } from './types/shell'
import packageInfo from '../package.json'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/react-ecp-endpointsecurity-reports'

  return (
    <section id={namespace}>
      <Reports
        id={packageInfo.name}
        {...props}
        authProvider={v1.authProvider}
        navigation={v1.navigation}
        breadCrumbs={v1.breadcrumbs}
        events={v1.events}
        search={props.search || true}
        export={props.export || true}
        allDashboard={props.allDashboard || true}
      />
    </section>
  )
}
