import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { IconMinusCircle } from '@veneer/core'

import { DeviceGroupsTreeView } from '../../DeviceGroups/DeviceGroupsTreeView'
import { ReportsOutlineBtn } from '../../ReportsOutlineBtn'
import { ScopeContext } from '../../../scopes/ScopeContext'

import useGetCollectionsOfCollectionClient from '../../../hooks/useGetCollectionsOfCollectionClient'
import * as Wrapper from './styles'
import {
  fetchSelectedGroups,
  totalGroupsInfo,
  totalGroupsDevicesInfo,
  FooterContent
} from './helper'

const DeviceGroup = (props) => {
  const { groupIds, setGroupIds, error, config } = props
  const { t } = useI18n()
  const { verifyScope } = useContext(ScopeContext)

  const [disable, setDisable] = useState(!config.enable)
  const [openModal, setOpenModal] = useState(false)
  const [selectedGroupName, setSelectedGroupName] = useState<any>([])
  const [groupNames, setGroupNames] = useState<any>([])
  const [selectedButton, setSelectedButton] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState(props.groupIds)
  const [parentGroupId, setParentGroupId] = useState([])

  const {
    data: groups,
    error: errorForGroups,
    isFetching: isFetchingForGroups,
    totalCount: totalCountForGroups,
    fetchDeviceGroups
  } = useGetCollectionsOfCollectionClient()

  useEffect(() => {
    verifyScope(config.scope).then((isAccess) => {
      setDisable(!(isAccess && config.enable))
      isAccess && fetchDeviceGroups()
    })
  }, [])

  useEffect(() => {
    if (groups != null && groups.length > 0) {
      const allGroup = groups.find((item) => item.name === 'All')
      if (allGroup) {
        setParentGroupId([allGroup.id])
        if (!config.enable) {
          setGroupIds([allGroup.id])
        }
      }
    }
  }, [groups])
  return (
    <Wrapper.SectionContainer>
      <Wrapper.SectionTitle
        id="reports_Generate_DeviceGroup_Label"
        data-testid="reports_Generate_DeviceGroup_Label"
      >
        {t('ecp-global-reports.reportDetails.deviceGroup', 'Device Group') +
          ' *'}
      </Wrapper.SectionTitle>

      <Wrapper.SectionTitleDivider />

      <Wrapper.DeviceGroupSubHeader
        data-testid="reports_Generate_DeviceGroup_Sublabel"
        id="reports_Generate_DeviceGroup_Sublabel"
      >
        <span id="group_info_text" data-testid="group_info_text">
          {t(
            'ecp-global-reports.generate.deviceGroupSelect.subHeader',
            'Select device groups to generate a report.'
          )}
        </span>

        <Wrapper.DeviceGroupsubContent>
          <ReportsOutlineBtn
            id="reports_Generate_DeviceGroup_Button"
            data-testid="reports_Generate_DeviceGroup_Button"
            text={t(
              'ecp-global-reports.generate.deviceGroupSelect.title',
              'Select Device Group'
            )}
            disabled={disable}
            onClick={() => setOpenModal(true)}
          />
          <Wrapper.GroupModal
            id="modal-id"
            show={openModal}
            onClose={() => setOpenModal(false)}
            closeOnBlur={false}
            footer={
              <FooterContent
                setSelectedButton={setSelectedButton}
                setOpenModal={setOpenModal}
                selectedNodes={selectedNodes}
                setSelectedNodes={setSelectedNodes}
                groupIds={groupIds}
                setGroupIds={setGroupIds}
                selectedGroupName={selectedGroupName}
                setGroupNames={setGroupNames}
                t={t}
              />
            }
            title={t(
              'ecp-global-reports.generate.deviceGroupSelect.title',
              'Select Device Group'
            )}
          >
            <p>
              {t(
                'ecp-global-reports.generate.deviceGroupSelect.subHeader',
                'Select device groups to generate a report.'
              )}
            </p>
            <DeviceGroupsTreeView
              groups={groups}
              error={errorForGroups}
              isFetching={isFetchingForGroups}
              totalCount={totalCountForGroups}
              setSelectedGroupName={(val) => setSelectedGroupName(val)}
              selectedNodes={selectedNodes}
              setSelectedNodes={(val) => setSelectedNodes(val)}
              groupIds={groupIds}
              parentGroupId={parentGroupId}
            />
          </Wrapper.GroupModal>

          <Wrapper.DeviceGrouptext
            data-testid="reports_Generate_DeviceGroup_Selected_Label"
            id="reports_Generate_DeviceGroup_Selected_Label"
          >
            {selectedButton &&
              `${totalGroupsInfo(groupNames, groups, t)} ${t(
                'ecp-global-reports.generate.deviceGroupSelect.selectedGroups',
                'selected'
              )} `}
          </Wrapper.DeviceGrouptext>
        </Wrapper.DeviceGroupsubContent>

        {error && !selectedButton && (
          <div
            style={{
              marginTop: '16px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconMinusCircle filled color="#cc2c00" size={20} />{' '}
            <span style={{ marginLeft: '4px' }}>
              {t(
                'ecp-global-reports.generate.deviceGroupSelect.errorTitle',
                'Select device groups'
              )}
            </span>
          </div>
        )}

        {selectedButton && (
          <Wrapper.GroupDetails
            data-testid="reports_Generate_DeviceGroup_Selected"
            id="reports_Generate_DeviceGroup_Selected"
          >
            {`${fetchSelectedGroups(groupNames, t)} ${totalGroupsDevicesInfo(
              groupNames,
              groups,
              t
            )}`}
          </Wrapper.GroupDetails>
        )}
      </Wrapper.DeviceGroupSubHeader>
    </Wrapper.SectionContainer>
  )
}
export default DeviceGroup
