import styled from 'styled-components'

export const ReportDownloadTitle = styled.h3`
  margin-bottom: 20px;
`
export const PDFDownloadContainer = styled.div`
  max-width: 100%;
  padding: 0px 12px 0px 8px;
`

export const ReportDetails = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const ReportText = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  word-break: break-all;
  max-width: 80%;
  width: 80%;
`

export const ReportStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  max-width: 18%;
  width: 80px;
  display: flex;
  justify-content: flex-start;
`

export const ProgressContainer = styled.div`
  padding: 5px 0;
  margin-bottom: 8px;
`


