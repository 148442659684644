import React, { useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from '../DeviceGroup/styles'
import { TextBox } from '@veneer/core'

const ReportRange = ({
  reportType,
  reportRangeError,
  setReportRangeError,
  reportrange,
  setReportRange,
  error
}) => {
  const { t } = useI18n()
  const [hide, setHide] = useState(true)
  const [reportRangeValueError, setReportRangeValueError] = useState(error)

  useEffect(() => {
    setHide(true)
    if (reportType?.reportRange) {
      setHide(!reportType.reportRange?.enable)
    }
  }, [reportType])

  return hide ? null : (
    <Wrapper.SectionContainer>
      <Wrapper.SectionTitle
        id="reports_Generate_DeviceGroup_Label"
        data-testid="reports_Generate_DeviceGroup_Label"
      >
        {t('ecp-global-reports.reportDetails.reportRange', 'Report Range') +
          ' *'}
      </Wrapper.SectionTitle>
      <Wrapper.SectionTitleDivider />
      <Wrapper.DeviceGroupSubHeader
        data-testid="reports_Generate_DeviceGroup_Sublabel"
        id="reports_Generate_DeviceGroup_Sublabel"
      >
        <Wrapper.ReportRangeContent>
          <Wrapper.ReportRangeText
            reportRangeValueError={reportRangeValueError}
            error={error}
            reportRangeError={reportRangeError}
          >
            {t('ecp-global-reports.generate.reportRange.last', 'Last')}
          </Wrapper.ReportRangeText>
          <Wrapper.RangeInput>
            <TextBox
              className="input-report-range"
              data-testid="reports_Generate_DeviceGroup_ReportRange"
              type="number"
              disabled={hide}
              {...(error && {
                helperText: t(
                  'ecp-global-reports.toastNotification.reportRangeError',
                  'Enter range between 1 to 90 days'
                )
              })}
              value={reportrange}
              error={reportRangeError}
              onChange={(e) => {
                const days = parseInt(e)
                setReportRange(days)
                days > 0 && days < 91
                  ? setReportRangeError(false)
                  : setReportRangeError(true)
              }}
              onFocus={() => {
                if (error) setReportRangeValueError(true)
              }}
              onBlur={() => {
                if (error && !reportRangeError) setReportRangeValueError(false)
              }}
              min="1"
              max="90"
            />
          </Wrapper.RangeInput>
          <Wrapper.ReportRangeText
            reportRangeValueError={reportRangeValueError}
            error={error}
            reportRangeError={reportRangeError}
            trailing={true}
          >
            {t('ecp-global-reports.generate.reportRange.range', 'days (1-90)')}
          </Wrapper.ReportRangeText>
        </Wrapper.ReportRangeContent>
      </Wrapper.DeviceGroupSubHeader>
    </Wrapper.SectionContainer>
  )
}

export default ReportRange
