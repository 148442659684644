import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProgressIndicator } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'

import { FleetAssessmentMainCard } from './FleetAssessmentMainCard'
import { DeviceAssessmentSummaryCard } from './DeviceAssessmentSummaryCard'
// import { IdentityCertificateAssessmentCard } from './IdentityCertificateAssessmentCard'
// import { FirmwareSecurityAssessmentCard } from './FirmwareSecurityAssessmentCard'
import { Footer } from '../Common/Footer'

import { useReports } from '../../../hooks/useReports'
// import { ReportDetailsBreadcrumData } from '../../../utils/consts'
import GeneralContext from '../../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { selectedGroupsFormat } from '../../../utils/genericMethods'
import { setBreadcrumbs } from '../../../utils/commonMethods'

export const ExecutiveFleetAssessmentSummary = () => {
  const { stack, breadcrumbs, navigation } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId } = useParams()
  const { t } = useI18n()
  const [reportDetails, setReportDetails] = useState(null)
  const [assessmentloading, setAssessmentLoading] = useState(true)
  const [customerName, setcustomerName] = React.useState('--')
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [assessmentSummaryData, setAssessmentSummaryData] = useState(null)
  const [collectionInfo, setCollectionInfo] = useState([])

  const isData = (array) => {
    return (
      array.length === 2 &&
      array[0].total !== null &&
      array[1].total !== null &&
      array[0].fleetRiskLevel !== null &&
      array[1].fleetRiskLevel !== null
    )
  }

  const reloadAssessmentDetails = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        JSON.parse(response[0])?.collectionInfo &&
          setCollectionInfo(JSON.parse(response)?.collectionInfo)
        JSON.parse(response[0])?.customerName &&
          setcustomerName(JSON.parse(response)?.customerName)
        const res =
          JSON.parse(response[0])?.assessmentSummaryList ||
          response.map((element) => {
            return JSON.parse(element)
          })
        isData(res) && setAssessmentSummaryData(res)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setAssessmentLoading(false)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
        // setBreadcrumb(
        //   ReportDetailsBreadcrumData(response.fileName || response.reportName)
        // )
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }
  useEffect(() => {
    setAssessmentLoading(true)
    setReportDetailsLoading(true)
    reloadReportDetails()
    reloadAssessmentDetails()
  }, [reportId])

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  return (
    <Wrapper.RDContainer data-testid="executive_Fleet_Assessment_Summary">
      <Wrapper.RDSubContainer data-testid="executive_Fleet_Assessment_Summary_Top_Card_Component">
        {reportDetailsLoading || assessmentloading ? (
          <ProgressIndicator
            data-testid="reports_Progress_Indicator"
            style={{ padding: '3% 50%' }}
            appearance={'circular'}
          />
        ) : (
          <FleetAssessmentMainCard
            data-testid="executive_Fleet_Assessment_Top_Card"
            reportDetails={reportDetails}
            data={assessmentSummaryData}
            deviceGroupData={deviceGroupData}
            customerName={customerName}
            imgDisplay={false}
          />
        )}
      </Wrapper.RDSubContainer>

      {assessmentloading ? (
        <ProgressIndicator
          data-testid="reports_Progress_Indicator"
          style={{ padding: '3% 50%' }}
          appearance={'circular'}
        />
      ) : (
        <>
          <Wrapper.RDSubContainer data-testid="Executive_Fleet_Device_Assessment_SummaryCard_Component">
            <DeviceAssessmentSummaryCard
              data-testid="Executive_Fleet_Device_Assessment_SummaryCard"
              data={assessmentSummaryData}
            />
          </Wrapper.RDSubContainer>
          <Wrapper.RDSubContainer data-testid="reports_Footer">
            <Footer data-testid="reports_Footer_Info" />
          </Wrapper.RDSubContainer>
        </>
      )}

      {/* <Wrapper.RDSubContainer data-testid="Executive_Fleet_Identity_Certificate_AssessmentCard_Component">
        <IdentityCertificateAssessmentCard data-testid="Executive_Fleet_Identity_Certificate_AssessmentCard"/>
      </Wrapper.RDSubContainer>

      <Wrapper.RDSubContainer data-testid="Executive_Fleet_Firmware_Security_AssessmentCard_Component">
        <FirmwareSecurityAssessmentCard data-testid="Executive_Fleet_Firmware_Security_AssessmentCard"/>
      </Wrapper.RDSubContainer> */}
    </Wrapper.RDContainer>
  )
}
