import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import { ProgressIndicator } from '@veneer/core'

import * as Wrapper from './styles'
import { useReports } from '../../../hooks/useReports'
import GeneralContext from '../../../contexts/GeneralContext'

export const EditReportDeviceGroup = (props) => {
  const { downLoadingReports } = useReports()
  const { stack } = useContext(GeneralContext)
  const { reportId } = useParams()
  const { t } = useI18n()

  const [loading, setLoading] = useState(true)
  const [reportGroups, setReportGroups] = useState('')

  const fetchSelectedGroups = (collectionInfo) => {
    let filteredList = collectionInfo?.filter((collections) => {
      return collections?.collectionName !== 'All'
    })
    let deviceCount = filteredList.reduce((r, e) => {
      return r + e.devicesCount
    }, 0)
    let groupData = filteredList.reduce(
      (res, elm, i) =>
        i < filteredList.length - 1
          ? res + (i === 0 ? elm.collectionName : ', ' + elm.collectionName)
          : res + (i === 0 ? elm.collectionName : ' and ' + elm.collectionName),
      ''
    )
    return `${groupData} (${filteredList.length} ${
      filteredList.length === 1
        ? t('ecp-global-reports.common.group', 'group')
        : t('ecp-global-reports.common.groups', 'groups')
    }, ${deviceCount}  ${
      deviceCount === 1
        ? t('ecp-global-reports.common.device', 'device')
        : t('ecp-global-reports.common.devices', 'devices')
    })`
  }

  useEffect(() => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        const collectionInfo = JSON.parse(response[0])?.collectionInfo
        if (collectionInfo) {
          setReportGroups(
            collectionInfo?.length ? fetchSelectedGroups(collectionInfo) : null
          )
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Wrapper.EditReportDeviceGroupCard>
      <Wrapper.EditReportDeviceGroupHeader
        id="reports_Edit_DeviceGroup_Label"
        data-testid="reports_Edit_DeviceGroup_Label"
      >
        {t('ecp-global-reports.reportDetails.deviceGroupReq', 'Device Group *')}
      </Wrapper.EditReportDeviceGroupHeader>

      <Wrapper.EditReporteDivider />
      {props.reportDetailsLoading || loading ? (
        <ProgressIndicator
          appearance="circular"
          id="reports_Edit_DeviceGroup_Progress"
          data-testid="reports_Edit_DeviceGroup_Progress"
        />
      ) : (
        <Wrapper.EditReporteDeviceGroupContent
          id="reports_Edit_DeviceGroup"
          data-testid="reports_Edit_DeviceGroup"
        >
          {reportGroups}
        </Wrapper.EditReporteDeviceGroupContent>
      )}
    </Wrapper.EditReportDeviceGroupCard>
  )
}
