import React, { useContext, useEffect, useRef, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { savePDF } from '@progress/kendo-react-pdf'
import ExecutiveSummaryHTML from './ExecutiveSummaryHTML'
import JobHistoryExecutiveSummaryHtml from './JobHistoryExecutiveSummary'
import ExecutiveAssessmentSummaryHTML from './ExecutiveAssessmentSummaryHTML'
import PolicyItemsAssessedHTML from './PolicyItemsAssessedHTML'
import SummaryHTML from './SummaryHTML'
import DeviceDetailsHTML from './DeviceDetailsHTML'
import GeneralContext from '../../contexts/GeneralContext'
import { useReports } from '../../hooks/useReports'
import {
  getDateAndTimePDF,
  getFontFamily,
  getdownloadDateFormat
} from '../../utils/commonMethods'
import { selectedGroupsFormat } from '../../utils/genericMethods'
import { useToast } from '@veneer/core'
import * as Wrapper from './style'

export const SingleDownloadPDf = (props) => {
  const { t } = useI18n()
  const { stack, locale } = useContext(GeneralContext)
  const { downLoadingReports } = useReports()
  const [html, setHtml] = useState(null)
  const htmlRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const { addToast } = toast

  useEffect(() => {
    const list = props.downloadData
    list.forEach((row, i) => {
      list[i].downloaded = 'InProgress'

      downLoadingReports(row.id, stack)
        .then((report) => {
          const collectionInfo = report.length
            ? JSON.parse(report[0])?.collectionInfo
            : []

          const deviceGroupData = collectionInfo?.length
            ? selectedGroupsFormat(collectionInfo, t)
            : null

          const customerName = report.length
            ? JSON.parse(report[0])?.customerName.toString().length > 35
              ? JSON.parse(report[0])
                  ?.customerName.toString()
                  .substring(0, 35) + '...'
              : JSON.parse(report[0])?.customerName
            : '--'
          switch (row?.category?.toLowerCase()) {
            case 'security':
              switch (row.type) {
                case 'executiveSummary': {
                  const reportJSON = {
                    reportData: JSON.parse(report[0]),
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }
                  setHtml(ExecutiveSummaryHTML(reportJSON, t, locale))
                  break
                }
                case 'executiveAssessmentSummary': {
                  const reportJSON = {
                    reportData:
                      JSON.parse(report[0])?.assessmentSummaryList ||
                      report.map((element) => {
                        return JSON.parse(element)
                      }),
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }
                  setHtml(ExecutiveAssessmentSummaryHTML(reportJSON, t, locale))
                  break
                }
                case 'policyItemsAssessed': {
                  const reportJSON = {
                    reportData:
                      JSON.parse(report[0])?.policySettingsReportList ||
                      report.map((element) => {
                        return JSON.parse(element)
                      }),
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }
                  setHtml(PolicyItemsAssessedHTML(reportJSON, t))
                  break
                }
                case 'devicesRemediationSummary':
                case 'devicesAssessmentSummary':
                case 'devicesNotAssessed':
                case 'devicesRecommendationSummary': {
                  const reportJSON = {
                    reportData: report.length
                      ? JSON.parse(report[0])?.deviceList ||
                        report.map((element) => {
                          return JSON.parse(element)
                        })
                      : [],
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }

                  setHtml(SummaryHTML(reportJSON, t, locale))
                  break
                }
                case 'deviceAssessmentDetails':
                case 'deviceRemediationDetails':
                case 'deviceRecommendationDetails': {
                  const reportJSON = {
                    reportData: JSON.parse(report[0]),
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    deviceName: row.deviceName,
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }
                  setHtml(DeviceDetailsHTML(reportJSON, t, locale))
                  break
                }
              }
              break
            case 'jobs':
              switch (row.type) {
                case 'executiveSummary':
                case 'jobSummary': {
                  const reportJSON = {
                    reportData: JSON.parse(report[0]),
                    reportType: row.type,
                    generatedTime: getDateAndTimePDF(row.generatedTime, locale),
                    generatedDay: row.generatedTime,
                    noOfDays: row.noOfDays,
                    deviceGroup: deviceGroupData,
                    customerName: customerName
                  }
                  setHtml(JobHistoryExecutiveSummaryHtml(reportJSON, t, locale))
                  break
                }
              }
              break
            default:
              break
          }

          const element = htmlRef.current || document.body
          savePDF(element, {
            paperSize: 'Letter',
            fileName: `${row.name}_${getdownloadDateFormat(row.generatedTime)}`,
            imageResolution: 360,
            keepTogether: '.keep-together-section',
            pageTemplate: PageTemplate
          })
          props.setSinglePDFDownload(false)
        })
        .catch((error) => {
          console.log('download err', error)
          addToast({
            id: 'download-single-pdf',
            type: 'negative',
            text: t(
              'ecp-global-reports.toastNotification.downloadingFailureText',
              {
                reportName: row.name,
                defaultValue: `Failed to prepare ${row.name} to download.`
              }
            ),
            timeout: 3
          })
        })
    })
  }, [])

  const PageTemplate = (props) => {
    return (
      <>
        <Wrapper.ReportPDFPagination>
          {`${t('ecp-global-reports.page', 'Page')} ${props.pageNum} ${t(
            `ecp-global-reports.executiveSummary.of`,
            'of'
          )} ${props.totalPages}`}
        </Wrapper.ReportPDFPagination>

        <Wrapper.ReportPDFDisclaimer>
          <p>
            {t(
              'ecp-global-reports.disclaimer',
              "This report is provided for general comparison only. The information contained is based on manufacturer's published and internal specifications, and proprietary data and algorithms. The information is not guaranteed accurate by HP Development Company. Users can customize the security policies used in the analysis, which will affect the results. Actual results may vary."
            )}
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              height="6px"
              width="6px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 276.715 276.715"
            >
              <g>
                <path d="M138.357,0C62.066,0,0,62.066,0,138.357s62.066,138.357,138.357,138.357s138.357-62.066,138.357-138.357   S214.648,0,138.357,0z M138.357,258.715C71.992,258.715,18,204.723,18,138.357S71.992,18,138.357,18   s120.357,53.992,120.357,120.357S204.723,258.715,138.357,258.715z" />
                <path d="M194.798,160.903c-4.188-2.677-9.753-1.454-12.432,2.732c-8.694,13.593-23.503,21.708-39.614,21.708   c-25.908,0-46.985-21.078-46.985-46.986s21.077-46.986,46.985-46.986c15.633,0,30.2,7.747,38.968,20.723   c2.782,4.117,8.375,5.201,12.496,2.418c4.118-2.782,5.201-8.377,2.418-12.496c-12.118-17.937-32.262-28.645-53.882-28.645   c-35.833,0-64.985,29.152-64.985,64.986s29.152,64.986,64.985,64.986c22.281,0,42.759-11.218,54.778-30.009   C200.208,169.147,198.985,163.582,194.798,160.903z" />
              </g>
            </svg>{' '}
            {t(
              'ecp-global-reports.copyright',
              'Copyright 2024 HP Development Company, L.P.'
            )}
          </p>
        </Wrapper.ReportPDFDisclaimer>
      </>
    )
  }
  return (
    <div
      style={{
        position: 'absolute',
        left: '-10000px',
        top: 0,
        fontFamily: getFontFamily(locale)
      }}
    >
      <div ref={htmlRef}>{html}</div>
    </div>
  )
}
