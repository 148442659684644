import styled from 'styled-components'

export const RDContainer = styled.div`
  padding: 24px;
  background-color: #f8f8f8;
`

export const ReportListTable = styled.div``

export const ReportListSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-bottom: 12px;
  width: 100%;
`

export const ReportListSubHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
`
export const RDSubContainer = styled.div`
  margin-top: 24px;
`
