import styled from 'styled-components'
import { Select } from '@veneer/core'

export const GenerateReportInputContainer = styled.div`
@media only screen and (max-width: 550px) {
  width: 70%
}
  margin-bottom: ${(props) => (props.error ? '30px' : '25px')};
  height: 58px;
  width: 456px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
`
export const GenerateReportInputLabel = styled.p`
  width: 456px;
  height: 20px;
  margin-bottom: 2px;

  font-size: 16px;
  line-height: 20px;
  color: #404040;
  display: flex;
`

export const GenerateReportSelectOption = styled(Select)`
  > div {
    background-color: #ffffff;
    border: 1px solid #adadad;
    box-sizing: border-box;
    border-radius: 8px;
  }
  width: initial;
  flex-grow: 0;
  height: 36px;
  width: 456px;
  border-radius: 0px;
  & * {
    box-sizing: border-box;
    min-height: none;
    max-height: 100%;
  }
`
