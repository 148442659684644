import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { IconDocumentPlus, IconEllipsis } from '@veneer/core'
import GeneralContext from '../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { ScopeContext } from '../../scopes/ScopeContext'
import { Button, ContextualMenu, MenuList } from '@veneer/core'

export const ReportsGenerateButton = (props) => {
  const { errorDisabled } = props
  const { navigation } = useContext(GeneralContext)
  const { WRITE_SCOPE } = useContext(ScopeContext)
  const { t } = useI18n()

  const generateButton = (expanded: boolean) => {
    return (
      WRITE_SCOPE && (
        <Wrapper.ReportsGenerateButton
          id="reports-generate-button"
          data-testid="reports-generate-button"
          leadingIcon={<IconDocumentPlus />}
          aria-label="Profile"
          onClick={() => navigation.push('/reports/generate-report')}
          disabled={errorDisabled}
          expanded={expanded}
        >
          {t('ecp-global-reports.button.generate', 'Generate')}
        </Wrapper.ReportsGenerateButton>
      )
    )
  }

  const cMenu = (
    <ContextualMenu
      anchorNode={
        <Button appearance="secondary" leadingIcon={<IconEllipsis />} />
      }
    >
      <MenuList>
        <div>{generateButton(true)}</div>
      </MenuList>
    </ContextualMenu>
  )

  return (
    <>
      {props.isMobileView && cMenu}
      {!props.isMobileView && generateButton(false)}
    </>
  )
}
