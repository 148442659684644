import styled from 'styled-components'
import { TreeView } from '@veneer/core'

export const Wrapper = styled.div`
@media only screen and (max-width: 550px) {
min-width: 0px;
}
  min-width: 300px;
  height: 100%;
`

export const UpperArea = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TreeViewWrapper = styled.div`
  margin-top: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 20px;
  padding: 10px;
`

export const DeviceGroupsLoading = styled.div`
  height: 5rem;
  border-radius: 0.5rem;
  padding: 20px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ErrorMessage = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TreeViewBorder = styled.div`
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: auto;
  height: 100%;
  margin-bottom: 32px;
`

export const TreeStyled = styled(TreeView)`
  span {
    word-break: break-word;
    white-space: nowrap;
  }
  .total-children {
    background-color: rgba(33, 33, 33, 0.1);
    border: 1px solid transparent;
  }
`
