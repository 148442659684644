import styled from 'styled-components'

export const ReportColourStatus1 = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
  background-color: rgb(48, 159, 47);
`

export const ReportColourStatus2 = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
  background-color: #027AAE;
`

export const ReportColourStatus3 = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
  background-color: rgb(173, 173, 173);
`

export const ReportColourStatus4 = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
  background-color: rgb(190, 19, 19);
`

export const statusRenderer = styled.div`
  display: flex;
  align-items: center;
`
