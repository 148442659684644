import React from 'react'
import { Colors } from '../../utils/consts'
import * as Wrapper from './style'
import { IconWarningAlt } from '@veneer/core'
import { Tablei18nText } from '../../utils/consts'
import HpLogo from './HpLogo'

const PolicyItemsAssessedHTML = (data, t) => {
  const isData = (data) => {
    return data.length > 0
  }
  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />

        <Wrapper.ReportsBannerTitle>
          <Wrapper.BannerTextContainer>
            <>
              <span data-testid="reports-pdf-bannerTitle">
                <span>
                  {t(
                    'ecp-global-reports.pdfHeader',
                    'Command Center Security Report:'
                  )}
                </span>
              </span>
            </>
            <div style={{ flex: '1' }}>
              <Wrapper.ReportsBannerSubTitle data-testid="reports-pdf-bannerSubTitle">
                {t(
                  'ecp-global-reports.reportsType.policyItemsAssessed',
                  'Policy Items Assessed'
                )}
              </Wrapper.ReportsBannerSubTitle>
            </div>
          </Wrapper.BannerTextContainer>
          <Wrapper.ReportsBannerTitle3>
            <Wrapper.ReportsSummaryPDFGenerationTime data-testid="generationTime">
              {data.generatedTime}
            </Wrapper.ReportsSummaryPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 41
                  ? '20px'
                  : '0'
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: ${data.deviceGroup}`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p style={{ wordBreak: 'break-word' }}>
            {`${t('ecp-global-reports.reportDetails.customer', 'Customer')}: ${
              data.customerName
            }`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>

      <Wrapper.ReportsPDFIndicator>
        <Wrapper.ReportsDataNullCheck data-testid="reportData-null-check">
          {`${t(
            'ecp-global-reports.policyItemAssessed.title',
            'Policy items assessed count'
          )}`}
        </Wrapper.ReportsDataNullCheck>

        <Wrapper.ReportsPDFRiskIndicatorText>
          <p data-testid="reports-riskIndicator-Passed">
            <Wrapper.ReportsRiskDot Color={Colors.passed} />
            {t(`ecp-global-reports.risks.passed`, 'Passed')}
          </p>

          <p data-testid="reports-riskIndicator-lowRisk">
            <Wrapper.ReportsRiskDot Color={Colors.low} />
            {t('ecp-global-reports.risks.lowRisk', 'Low Risk')}
          </p>

          <p data-testid="reports-riskIndicator-mediumRisk">
            <Wrapper.ReportsRiskDot Color={Colors.medium} />
            {t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')}
          </p>

          <p data-testid="reports-riskIndicator-highRisk">
            <Wrapper.ReportsRiskDot Color={Colors.high} />
            {t('ecp-global-reports.risks.highRisk', 'High Risk')}
          </p>
        </Wrapper.ReportsPDFRiskIndicatorText>
      </Wrapper.ReportsPDFIndicator>
      {isData(data.reportData) ? (
        data.reportData.map((category) => {
          const CatId = Object.keys(category)[0]
          const settings = Object.values(category)[0]
          return (
            <Wrapper.ReportsActivitytable>
              <Wrapper.ReportsActivitytableHead>
                <tr>
                  <Wrapper.ReportsActivitytableData
                    style={{ paddingLeft: '15px' }}
                  >
                    {t(`ecp-global-reports.tableHeader.${CatId}`, '')}
                  </Wrapper.ReportsActivitytableData>
                  <td></td>
                </tr>
              </Wrapper.ReportsActivitytableHead>

              <tbody>
                {settings.map((setting) => {
                  return setting?.deviceStatus.map((state) => {
                    return (
                      <tr>
                        <Wrapper.ReportsActivitytableData
                          data-testid="ReportsActivitytableData"
                          style={{
                            paddingBottom: '1px'
                          }}
                        >
                          <Wrapper.PolicySettingName>
                            <Wrapper.ReportRiskIndicator
                              risk={state.assessmentStatus}
                            />
                            <span style={{ wordBreak: 'break-word' }}>
                              {setting?.settingId &&
                                t(
                                  `ecp-global-reports.settings.${setting.settingId}.name`
                                )}
                            </span>
                          </Wrapper.PolicySettingName>
                        </Wrapper.ReportsActivitytableData>

                        <Wrapper.ReportsActivitytableData>
                          {`${t('ecp-global-reports.tableHeader.count')}: ${
                            state.count
                          }`}
                        </Wrapper.ReportsActivitytableData>
                      </tr>
                    )
                  })
                })}
              </tbody>
            </Wrapper.ReportsActivitytable>
          )
        })
      ) : (
        <Wrapper.ReportsActivitytable>
          <Wrapper.ReportsActivitytableHead>
            <tr>
              <Wrapper.ReportsActivitytableData style={{ paddingLeft: '15px' }}>
                -
              </Wrapper.ReportsActivitytableData>
              <td></td>
            </tr>
          </Wrapper.ReportsActivitytableHead>
          <tbody>
            {' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '5% 100%'
              }}
            >
              <span style={{ alignSelf: 'center' }}>
                <IconWarningAlt />
              </span>

              <p
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                {Tablei18nText(t).noItems}
              </p>
            </div>
          </tbody>
        </Wrapper.ReportsActivitytable>
      )}
    </Wrapper.ReportsPDFContent>
  )
}
export default PolicyItemsAssessedHTML
