import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'

export const Footer = () => {
  const { t } = useI18n()
  return (
    <Wrapper.ReportsFooter
      data-testid="reports_Disclaimer_Footer_Info"
      id="reports_Disclaimer_Footer_Info"
    >
      {t(
        'ecp-global-reports.disclaimer',
        "This report is provided for general comparison only. The information contained is based on manufacturer's published and internal specifications, and proprietary data and algorithms. The information is not guaranteed accurate by HP Development Company. Users can customize the security policies used in the analysis, which will affect the results. Actual results may vary."
      )}
    </Wrapper.ReportsFooter>
  )
}
