import React, { createContext, useState, useEffect, useMemo } from 'react'
import {
  DELETE_SCOPES,
  READ_SCOPES,
  WRITE_SCOPES,
  COLLECTIONS_READ_SCOPES,
  JOBHISTORY_READ_SCOPES
} from './consts'

export const ScopeContext = createContext({
  READ_SCOPE: null,
  WRITE_SCOPE: null,
  DELETE_SCOPE: null,
  COLLECTIONS_READ_SCOPE: null,
  JOBHISTORY_READ_SCOPE: null,
  verifyScope: null
})

export const ScopeProvider = ({ children, accessControl }) => {
  const [READ_SCOPE, setReadScope] = useState(null)
  const [WRITE_SCOPE, setWriteScope] = useState(null)
  const [DELETE_SCOPE, setDeleteScope] = useState(null)
  const [COLLECTIONS_READ_SCOPE, setCollectionsReadScope] = useState(null)
  const [JOBHISTORY_READ_SCOPE, setJobHistoryReadScope] = useState(null)

  const verifyScope = (scopeValue: string) => {
    return accessControl && accessControl.checkScopes([{ scope: scopeValue }])
  }

  const contextValue = useMemo(
    () => ({
      READ_SCOPE,
      WRITE_SCOPE,
      DELETE_SCOPE,
      COLLECTIONS_READ_SCOPE,
      JOBHISTORY_READ_SCOPE,
      verifyScope
    }),
    [
      READ_SCOPE,
      WRITE_SCOPE,
      DELETE_SCOPE,
      COLLECTIONS_READ_SCOPE,
      JOBHISTORY_READ_SCOPE,
      verifyScope
    ]
  )

  useEffect(() => {
    if (accessControl) {
      // order of promise should match the respective set function
      Promise.all([
        accessControl.checkScopes(READ_SCOPES),
        accessControl.checkScopes(WRITE_SCOPES),
        accessControl.checkScopes(DELETE_SCOPES),
        accessControl.checkScopes(COLLECTIONS_READ_SCOPES),
        accessControl.checkScopes(JOBHISTORY_READ_SCOPES)
      ])
        .then((values) => {
          console.log(values)

          setReadScope(values[0])
          setWriteScope(values[1])
          setDeleteScope(values[2])
          setCollectionsReadScope(values[3])
          setJobHistoryReadScope(values[4])
        })
        .catch((err) => console.log(err))
    }
  }, [accessControl])

  return (
    <div>
      <ScopeContext.Provider value={contextValue}>
        {children}
      </ScopeContext.Provider>
    </div>
  )
}

export default ScopeProvider
