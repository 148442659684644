import { getDateAndTimePDF } from '../../../utils/commonMethods'

// Function to handle the third column data based on the type of the device
const thirdColumn = (type, device, t, locale) => {
  // Destructure the assessmentStatus from the device object
  const { assessmentStatus } = device

  // Destructure the properties from the assessmentStatus object
  const {
    lastAssessedTime,
    lastRemediatonTimeStamp,
    highRiskCount,
    mediumRiskCount,
    lowRiskCount,
    assessmentResultReason
  } = assessmentStatus || {}

  // Define handlers for each type of device
  const typeHandlers = {
    // For devicesAssessmentSummary, return the last assessed time or '--' if not available
    devicesAssessmentSummary: () =>
      lastAssessedTime ? getDateAndTimePDF(lastAssessedTime, locale) : '--',

    // For devicesRemediationSummary, return the last remediation timestamp or '--' if not available
    devicesRemediationSummary: () =>
      lastRemediatonTimeStamp
        ? getDateAndTimePDF(lastRemediatonTimeStamp, locale)
        : '--',

    // For devicesRecommendationSummary, return the sum of high, medium, and low risk counts
    devicesRecommendationSummary: () =>
      (highRiskCount || 0) + (mediumRiskCount || 0) + (lowRiskCount || 0),

    // For devicesNotAssessed, return the assessment result reason if available
    devicesNotAssessed: () =>
      assessmentResultReason &&
      t(
        `ecp-global-reports.deviceDetails.assessmentResultReason.${assessmentResultReason}`,
        ''
      )
  }

  // Return the handler function for the given type, or null if no handler is defined for this type
  return typeHandlers[type] ? typeHandlers[type]() : null
}

export default thirdColumn
