import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { TextArea, Checkbox } from '@veneer/core'
import { IconWarning } from '@veneer/core'
import * as Wrapper from './styles'

export const EmailOption = () => {
  const { t } = useI18n()
  return (
    <Wrapper.SectionContainer>
      <Wrapper.SectionTitle data-testid="repors_EmailOption_Header">
        {t('ecp-global-reports.generate.emailOption.header', 'Email option')}
      </Wrapper.SectionTitle>

      <Wrapper.SectionTitleDivider />
      <div style={{ marginTop: '18px' }}>
        <Checkbox
          data-testid="reports_EmailOption_Checkbox"
          disabled
          id="reports_EmailOption_Checkbox"
          label={t(
            'ecp-global-reports.generate.emailOption.sendEmail',
            'Send Email when generating a report'
          )}
        />
      </div>

      <Wrapper.EmailOptionContent data-testid="reports_EmailOption_ReportType_Format">
        {t(
          'ecp-global-reports.generate.emailOption.formatLabel',
          'Report file format'
        )}
        <br></br>
        <div style={{ marginRight: '67px', marginTop: '18px' }}>
          <Checkbox
            disabled
            id="reports_EmailOption_ReportType_PDFformat"
            label={t('ecp-global-reports.generate.emailOption.pdf', 'PDF')}
          />
        </div>

        <div style={{ marginTop: '18px' }}>
          <Checkbox
            disabled
            id="reports_EmailOption_ReportType_XLSVformat"
            label={t('ecp-global-reports.generate.emailOption.xlsv', 'XLSV')}
          />
        </div>
      </Wrapper.EmailOptionContent>

      <Wrapper.EmailOptionTextbox data-testid="reports_EmailOption_EmailAddress_Label">
        {t('ecp-global-reports.generate.emailOption.email', 'Email address')}
        <br></br>
        <TextArea
          data-testid="reports_EmailOption_EmailAddress"
          style={{
            marginTop: '2px',
            width: '424px',
            height: '88px'
          }}
          defaultValue={t(
            'ecp-global-reports.generate.emailOption.emailPlaceholder',
            'Enter email address'
          )}
          disabled
          placeholder="Placeholder"
        />
      </Wrapper.EmailOptionTextbox>

      <Wrapper.EmailOptionFooterContent>
        <Wrapper.EmailOptionFooterContentIcon>
          <IconWarning filled size={24} color="colorGray5" />
        </Wrapper.EmailOptionFooterContentIcon>
        <Wrapper.EmailOptionFooterContentText
          id="reports_EmailOption_EmailAddress_Info"
          data-testid="reports_EmailOption_EmailAddress_Info"
        >
          {t(
            'ecp-global-reports.generate.emailOption.info',
            'When entering multiple addresses, separate them with commas.'
          )}
        </Wrapper.EmailOptionFooterContentText>
      </Wrapper.EmailOptionFooterContent>
    </Wrapper.SectionContainer>
  )
}
