import styled from 'styled-components'

export const ReportAssessmentListHeader = styled.p`
  width: 328px;
  height: 32px;
  left: 0px; 
  top: 0px;

  font-size: 24px;
  line-height: 32px;
  color: ${(props) => (props.Rate < 0 ? '#BE1313' : '#1C7A17')}; ;
`
export const ReportAssessmentListSubHeader = styled.p`
  width: 328px;
  height: 20px;
  left: 0px;
  top: 32px;

  font-size: 14px;
  line-height: 20px;
`
