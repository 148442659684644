import styled from 'styled-components'

export const TextInfo = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`
export const TitleText = styled.div`
  font-size: 20px;
`
export const DateTime = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
`
export const Ldr = styled.span`
  width: 136px;
`
export const LDRContainer = styled.div`
  margin-top: 8px;
`
export const PolicyContainer = styled.div`
  padding: 24px;
  padding-bottom: 16px;
`
export const ReportDetailsTitleCard = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid #e9e9e9;
`

export const ReportDetailsCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 6px rgba(33, 33, 33, 0.1));
`
