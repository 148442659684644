import styled from 'styled-components'

export const ReportListSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export const ReportListSubHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  /* gap: 12px; */
`
