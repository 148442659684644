import { CharsNotAllowed } from '../../../utils/consts'

/**
 * Returns the helper text based on the provided report name and translation function.
 * @param reportName - The report name.
 * @param t - The translation function.
 * @returns The helper text.
 */
export function getHelperText(reportName, error, textError, t) {
  const trimmedReportName = reportName.trim()
  const nameLength = trimmedReportName.length
  if (error) {
    if (nameLength === 0) {
      return t('ecp-global-reports.generate.enterReport', 'Enter a report name')
    }
    if (nameLength < 3) {
      return t(
        'ecp-global-reports.toastNotification.reportNameMin3Char',
        'Report Name requires minimum 3 characters'
      )
    }
    if (CharsNotAllowed.some((char) => trimmedReportName.includes(char))) {
      return (
        t(
          'ecp-global-reports.toastNotification.reportNameNotValid',
          "Report Name can't contain "
        ) + CharsNotAllowed.join(', ')
      )
    }
  }
  return ''
}
/**
 * Handles the input event for the report name field.
 *
 * @param {Function} setReportName - The function to set the report name.
 * @param {Function} setTextError - The function to set the text error.
 * @param {string} value - The input value.
 * @returns {void}
 */
export const onInput = (setReportName, setTextError, value) => {
  setReportName(value)
  const reportName = value.replace(/\s+/g, ' ').trim()
  const isNameTooShort = reportName.length < 3
  const containsInvalidChars = CharsNotAllowed.some((char) =>
    reportName.includes(char)
  )

  const hasError = isNameTooShort || containsInvalidChars
  setTextError(hasError)
}
