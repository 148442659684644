import { Accordion } from '@veneer/core'
import styled from 'styled-components'

export const ReportRiskColorIndicator = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-left: 12px;
  background-color: ${(props) => props.backgroundColor};
`

export const ReportRiskColorIndicatorItems = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  background-color: ${(props) => props.backgroundColor};
`

export const ReportRiskColor = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
`

export const ReportRiskLabel = styled.div`
  display: flex;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #212121;
  justify-content: end;
  margin-bottom: 8px;
`
export const AccordianReport = styled(Accordion)`
  & > div > div {
    background-color: #fafafa;
  }
`
export const StatusContainer = styled.div`
  width: 100%;
`
export const ContentContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  display: flex;
  justify-content: space-between;
  & > div {
    width: 32%;
    margin-bottom: 12px;
  }
  &:nth-last-child(1) > div {
    margin-bottom: 0px;
  }
`

export const LeftContainer = styled.div`
  word-break: break-word;
  display: flex;
`

export const LeftTextArea = styled.span`
  line-height: 20px;
  padding: 2px 0;
`
export const LeftTextAreaSubItem = styled.div`
  margin-left: 20px;
`
export const MiddleTextArea = styled.div`
  padding-left: 20px;
`
