import styled from 'styled-components'
import { Button } from '@veneer/core'
// import tokens from "@veneer/tokens";
import { Modal } from '@veneer/core'

export const SectionTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
`
export const GroupModal = styled(Modal)`

  padding-top: 24px;
  padding-bottom: 24px;
  & > div {
    overflow: hidden;
  }
  & > div > div {
   @media only screen and (max-width: 550px) {
      width: 300px;
    }
    width: 612px;
    min-height: 560px;
    max-height: 100vh;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  & > div > div > #modal-id-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 0;
  }
`

export const DeviceGroupSubHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`
export const GroupDetails = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  word-break: break-word;
`
export const ReportSelectDeviceButton = styled(Button)`
  align-items: center;
  padding: 8px 20px;
  height: 36px;
  width: 168px;
  border: 1px solid #027aae;
  box-sizing: border-box;
  border-radius: 8px;
`

export const DeviceGrouptext = styled.p`
@media only screen and (max-width: 550px) {
  width: auto;
}
  height: 20px;
  width: 317px;
  font-size: 16px;
  line-height: 20px;
  margin-left: 12px;
  padding-top: 8px;
`

export const DeviceGroupsubContent = styled.div`
  width: fit-content;
  margin-top: 10px;
  display: flex;
`

export const SectionTitleDivider = styled.div`
  border-bottom: 1px solid #ebe7e7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
`

export const SectionContainer = styled.div`
  margin-top: 10px;
`
export const GenerateButton = styled(Button)`
  width: 84px;
  height: 36px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 8px, 20px, 8px, 20px;
`

// Range Report css

export const RangeInput = styled.div`
  // max-width: 44px;
  margin: 0 13px;
`
export const ReportRangeContent = styled.div`
  display: flex;
  align-items: center;
`
export const ReportRangeText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: ${(props) =>
    (props.error && props.reportRangeError) || props.reportRangeValueError
      ? 'relative'
      : 'static'};
  right: ${(props) =>
    props.trailing &&
    ((props.error && props.reportRangeError) || props.reportRangeValueError)
      ? '12.5em'
      : '0'};
  bottom: ${(props) =>
    (props.error && props.reportRangeError) || props.reportRangeValueError
      ? '0.75em'
      : '0'};
`
