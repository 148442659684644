import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'
// import { HighlightText } from '../HighlightText'

export const ReportListStatus = (props) => {
  const { t } = useI18n()

  const getReportStatus = (powerState) => {
    switch (powerState?.toLowerCase()) {
      case 'ready':
        return (
          <div>
            <Wrapper.ReportColourStatus1 data-testid="reports-status-ready" />
            {t('ecp-global-reports.statusList.ready', 'Ready')}
            {/* <HighlightText
              value={t('ecp-global-reports.statusList.ready', 'Ready')}
              searchItem={props.searchText}
            /> */}
          </div>
        )
      case 'inprogress':
        return (
          <div>
            <Wrapper.ReportColourStatus2 data-testid="reports-status-generating" />
            {t('ecp-global-reports.statusList.generating', 'Generating...')}
            {/* <HighlightText
              value={t('ecp-global-reports.statusList.generating', 'Generating...')}
              searchItem={props.searchText}
            /> */}
          </div>
        )
      case 'deleted':
        return (
          <div>
            <Wrapper.ReportColourStatus3 data-testid="reports-status-deleting" />
            {t('ecp-global-reports.statusList.deleting', 'Deleting...')}
            {/* <HighlightText
              value={t('ecp-global-reports.statusList.deleting', 'Deleting...')}
              searchItem={props.searchText}
            /> */}
          </div>
        )
      case 'cancelled':
        return (
          <div>
            <Wrapper.ReportColourStatus3 data-testid="reports-status-deleting" />
            {t('statusList.canceled', 'Canceled')}
            {/* <HighlightText
              value={t('statusList.deleting', 'Deleting...')}
              searchItem={props.searchText}
            /> */}
          </div>
        )
      case 'failed':
      case 'error':
      default:
        return (
          <div>
            <Wrapper.ReportColourStatus4 data-testid="reports-status-failed" />
            {t('ecp-global-reports.statusList.failed', 'Failed')}
            {/* <HighlightText
              value={t('ecp-global-reports.statusList.failed', 'Failed')}
              searchItem={props.searchText}
            /> */}
          </div>
        )
    }
  }

  return (
    <Wrapper.statusRenderer data-testid="reports-status" id="reports-status">
      {getReportStatus(props.status)}
    </Wrapper.statusRenderer>
  )
}
