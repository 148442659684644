import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import highchartsInterface, {
  Chart,
  ChartProvider
} from '@veneer/highcharts-interface'

export const GroupBarchart = ({ data }) => {
  const { t } = useI18n()

  return (
    <ChartProvider
      data-testid="reports_Group_Chart_Provider"
      chartInterface={highchartsInterface}
    >
      <Chart
        data-testid="reports_Group_Chart"
        options={{
          chart: {
            type: 'bar',
            height: 442
          },
          xAxis: {
            categories: [
              t(
                'ecp-global-reports.tableHeader.authentication',
                'Authentication'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheDevice',
                'Device Protection'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheDocument',
                'Document Protection'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheNetwork',
                'Network Protection'
              )
            ],
            title: {
              text: null
            }
          },
          yAxis: {
            min: 0,
            tickInterval: 2,
            title: {
              text: null,
              align: 'high'
            },
            labels: {
              overflow: 'justify'
            }
          },
          plotOptions: {
            series: {
              animation: false,
              enableMouseTracking: false
            },
            bar: {
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            x: -30,
            y: 0
          },
          series: data
        }}
      />
    </ChartProvider>
  )
}
