import styled from 'styled-components'

export const ReportListComponent = styled.div`
  padding: 24px 24px 0 24px;
`
export const RedirectLink = styled.div`
  cursor: pointer;
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #027aae;
  a {
    color: inherit;
  }
`
