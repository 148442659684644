import React from 'react'
import * as Wrapper from './styles'
import { useI18n } from '@jarvis/react-portal-addons'
import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'
import { ReportDetailsAccordionContent } from '../../Common/ReportDetailsAccordionContent'

export const SolutionsEntitledSummaryCard = ({ data }) => {
  const { t } = useI18n()
  const contentData = () => (
    <Wrapper.ReportsLicenseSummaryCard>
      <Wrapper.ReportsLicenseSummaryHeading
        data-testid="ExecutiveSummary_solutionEntitledSummary_smAdvanced"
        id="ExecutiveSummary_solutionEntitledSummary_smAdvanced"
      >
        {t(
          'ecp-global-reports.executiveSummary.smAdvanced',
          'Secure Fleet Manager Advanced (Devices)'
        )}
      </Wrapper.ReportsLicenseSummaryHeading>
      <Wrapper.ReportsLicenseSummaryContents>
        {data?.reportData?.advanceCount || '0'}
      </Wrapper.ReportsLicenseSummaryContents>

      <Wrapper.ReportsLicenseSummaryHeading
        data-testid="ExecutiveSummary_solutionEntitledSummary_smBasic"
        id="ExecutiveSummary_solutionEntitledSummary_smBasic"
      >
        {t(
          'ecp-global-reports.executiveSummary.smBasic',
          'Secure Fleet Manager (Devices)'
        )}
      </Wrapper.ReportsLicenseSummaryHeading>
      <Wrapper.ReportsLicenseSummaryContents>
        {data?.reportData?.standardCount || '0'}
      </Wrapper.ReportsLicenseSummaryContents>
    </Wrapper.ReportsLicenseSummaryCard>
  )
  const content = [
    {
      content: <ReportDetailsAccordionContent ReportContent={contentData()} />,
      expanded: true,
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={t(
              'ecp-global-reports.executiveSummary.last',
              'Solutions Entitled Summary'
            )}
            data-testid="ExecutiveSummary_solutionEntitledSummary_Label"
            id="ExecutiveSummary_solutionEntitledSummary_Label"
            hideButton={true}
          />
        )
      },
      id: 'SolutionsEntitledSummaryAccordion'
    }
  ]
  return <ReportDetailsAccordion content={content} />
}
