import styled from 'styled-components'

export const RDContainer = styled.div`
  margin-left: 24px;
  margin-right: 16px;
  margin-top: 24px;
`
export const RDSubContainer = styled.div`
  margin-bottom: 24px;
`
