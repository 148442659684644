import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  width: fit-content;
  box-sizing: border-box;
`;

export const Dot = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  padding: 1px;

  > div {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    color: black;
    text-align: center;
  }

  &.cyan > div {
    background-color: #03a4f0;
  }

  &.magenta > div {
    background-color: #f7448b;
  }

  &.yellow > div {
    background-color: #ffd906;
  }

  &.black > div {
    background-color: black;
    color: white;
  }
`;
