import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import { useToast } from '@veneer/core'

import GeneralContext from '../../contexts/GeneralContext'
import { useReports } from '../../hooks/useReports'
import * as Wrapper from './styles'

export const EditReportContextualFooter = (props) => {
  const { t } = useI18n()
  const { editReport } = useReports()
  const { reportId } = useParams()
  const { addToast } = useToast()
  const { reportName, TextBoxValue, nameChanging, setNameChanging, error } =
    props
  const { navigation } = useContext(GeneralContext)

  return (
    <Wrapper.ReportsGenerateContextualFooterMenu>
      <li>
        <Wrapper.EditReportContextualCancelButton
          id="reports_Edit_Cancel_Button"
          data-testid="reports_Edit_Cancel_Button"
          disabled={nameChanging}
          appearance="secondary"
          onClick={() => navigation.push('/reports')}
        >
          {t('ecp-global-reports.button.cancel', 'Cancel')}
        </Wrapper.EditReportContextualCancelButton>
        <Wrapper.EditReportContextualEditButton
          disabled={reportName === TextBoxValue || nameChanging}
          onClick={() => {
            if (!error) {
              setNameChanging(true)
              editReport({ fileName: TextBoxValue }, reportId)
                .then((e) => {
                  navigation.push('/reports')
                  addToast({
                    id: 'edit-success',
                    type: 'positive',
                    text: t(
                      'ecp-global-reports.toastNotification.renameSuccessText',
                      {
                        reportName: TextBoxValue,
                        defaultValue: `Editing ${TextBoxValue} successfully completed.`
                      }
                    ),
                    timeout: 3
                  })
                })
                .catch((e) => {
                  console.log(e)
                  addToast({
                    id: 'edit-error',
                    type: 'negative',
                    text: t(
                      'ecp-global-reports.toastNotification.renameFailureText',
                      `Failed to edit ${TextBoxValue}.`
                    ),
                    timeout: 3
                  })
                })
                .finally(() => {
                  setNameChanging(false)
                })
            }
          }}
          id="reports_Edit_Edit_Button"
          data-testid="reports_Edit_Edit_Button"
          appearance="primary"
        >
          {t('ecp-global-reports.button.save', 'Save')}
        </Wrapper.EditReportContextualEditButton>
      </li>
    </Wrapper.ReportsGenerateContextualFooterMenu>
  )
}
