import styled from 'styled-components'

export const DeviceDetailsProfileAccordion = styled.div`
  border-radius: 11px 12px 0px 0px;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
`

export const DeviceDetailsheading = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #212121;
  margin: 0px 4px;
`

export const ReportsLicenseSummaryHeading = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #737373;
`
export const DeviceProfileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 974px;
  left: calc(50% - 974px / 2);
  top: 0%;
  bottom: 0%;
  filter: drop-shadow(0px 4px 16px rgba(33, 33, 33, 0.1));
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`

export const ReportsLicenseSummaryCard = styled.div`
  display: grid;
  width: 980px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 4px 16px rgba(33, 33, 33, 0.1));
`

export const ReportsLicenseSummaryContents = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  padding: 0px 0px 24px 0px;
`

export const AccordionCard = styled.div`
  width: 974px;
  height: 172px;
  left: 0px;
  top: 56px;
  padding: 16px, 24px, 16px, 24px;
  background: #ffffff;
  border-radius: 0px;
  margin: 0px 0px;
`

export const RDAccordionTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`
