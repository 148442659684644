import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import highchartsInterface, {
  Chart,
  ChartProvider
} from '@veneer/highcharts-interface'
import { getFontFamily } from '../../../utils/commonMethods'

export const GroupBarChartPDF = ({ data, locale }) => {
  const { t } = useI18n()

  return (
    <ChartProvider
      data-testid="report_Group_Chart_PDF_Provider"
      chartInterface={highchartsInterface}
    >
      <Chart
        data-testid="report_Group_Chart_PDF"
        options={{
          chart: {
            type: 'bar',
            height: 240,
            width: 320
          },
          xAxis: {
            categories: [
              t(
                'ecp-global-reports.tableHeader.authentication',
                'Authentication'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheDevice',
                'Device Protection'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheDocument',
                'Document Protection'
              ),
              t(
                'ecp-global-reports.executiveSummary.protectTheNetwork',
                'Network Protection'
              )
            ],
            labels: {
              style: { fontSize: '6px' }
            }
          },
          yAxis: {
            min: 0,
            tickInterval: 2,
            labels: {
              style: { fontSize: '6px' }
            }
          },
          plotOptions: {
            series: {
              animation: false,
              enableMouseTracking: false
            },
            column: {
              grouping: true,
              groupPadding: 0
            },
            bar: {
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: {
            itemStyle: {
              fontSize: '8px',
              fontFamily: getFontFamily(locale)
            },
            itemDistance: 10,
            squareSymbol: true,
            symbolHeight: 6.4,
            symbolPadding: 1,
            symbolRadius: 2,
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
            x: -10,
            y: 0
          },
          series: data
        }}
      />
    </ChartProvider>
  )
}
