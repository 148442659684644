import React from 'react'
import * as Wrapper from './styles'

export const ReportDetailsAccordionContent = (props) => {
  const { ReportContent } = props
  return (
    <>
      <Wrapper.ReportDetailsContent
        data-testid="device_Details_Accordian_Content">
        {ReportContent}
      </Wrapper.ReportDetailsContent>
    </>
  )
}
