import styled from 'styled-components'

export const TextInfo = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`
export const TitleText = styled.div`
  font-size: 20px;
`
export const DateTime = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
`
export const Ldr = styled.span`
  width: 136px;
`
export const LDRContainer = styled.div`
  margin-top: 8px;
`
export const PolicyAccordianContainer = styled.div`
  margin: 8px 16px 0px 8px;
`
export const ReportDetailsTitleCard = styled.div`
  padding: 4px 8px;
`
