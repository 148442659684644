// import { ProgressIndicator } from '@veneer/core'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import { useReports } from '../../../hooks/useReports'
import { ReportDetailsCard } from '..//Common/ReportDetailsCard'
import { JobsSummary } from './JobsSummary'
import { JobList } from './JobList'
import * as Wrapper from './styles'
import { Footer } from '../Common/Footer'
import { getDateAndTime, setBreadcrumbs } from '../../../utils/commonMethods'
import GeneralContext from '../../../contexts/GeneralContext'
import { selectedGroupsFormat } from '../../../utils/genericMethods'
import LoadingIndicator from '../Common/LoadingIndicator'

export const JobHistoryExecutiveSummary = () => {
  const { t } = useI18n()
  const [jobSummaryLoading, setJobSummaryLoading] = useState(true)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const { stack, navigation, breadcrumbs } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId } = useParams()

  const [customerName, setcustomerName] = React.useState('--')
  const [collectionInfo, setCollectionInfo] = useState([])

  const [reportDetails, setReportDetails] = useState({})
  const [jobList, setJobList] = useState({
    reportData: {}
  })
  const [jobsStatus, setJobStatus] = useState({})

  const headers = [
    {
      key: 'jobName',
      label: t('ecp-global-reports.tableHeader.jobName', 'Job Name')
    },
    {
      key: 'deviceDetail',
      label: t('ecp-global-reports.tableHeader.deviceDetail', 'Device Detail')
    },
    {
      key: 'date',
      label: t('ecp-global-reports.tableHeader.date', 'Date')
    },
    {
      key: 'status',
      label: t('ecp-global-reports.status', 'Status')
    },
    {
      key: 'copies',
      label: t('ecp-global-reports.tableHeader.copies', 'Copies')
    }
  ]

  const isData = (obj) => {
    return obj?.jobs !== null && obj?.collectionInfo !== null
  }
  const reloadJobSummarydata = async () => {
    try {
      setJobSummaryLoading(true)

      const response = await downLoadingReports(reportId, stack)
      const res = JSON.parse(response[0])

      if (res?.collectionInfo) setCollectionInfo(res.collectionInfo)
      if (res?.customerName) setcustomerName(res.customerName)

      if (isData(res)) {
        setJobStatus(res.statusCount)
        setJobList({
          reportData: res.jobs
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setJobSummaryLoading(false)
    }
  }

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  const createExportTableData = (jobs) => {
    const statusMapping = {
      failed: t('ecp-global-reports.statusList.failed', 'Failed'),
      cancelled: t(
        'ecp-global-reports.jobHistoryExecutiveSummary.cancelled',
        'Cancelled'
      ),
      success: t('ecp-global-reports.statusList.completed', 'Completed')
    }

    return jobs?.reportData?.map((job) => ({
      jobName: job?.jobName,
      deviceDetail: job?.modelName,
      date: getDateAndTime(job?.jobCompletionTime || null),
      status: statusMapping[job?.status] || '',
      copies: job?.copiesCount
    }))
  }
  const reloadReportDetails = async () => {
    try {
      setReportDetailsLoading(true)
      const response = await fetchReportsByID(reportId)
      setReportDetails(response)
      setBreadcrumbs(breadcrumbs, navigation, t, {
        name: response.fileName || response.reportName
      })
    } catch (e) {
      console.log(e)
      setReportDetails({})
    } finally {
      setReportDetailsLoading(false)
    }
  }
  useEffect(() => {
    setJobSummaryLoading(true)
    reloadJobSummarydata()
    reloadReportDetails()
  }, [reportId])

  const ReportContent = ({
    reportDetails,
    deviceGroupData,
    customerName,
    headers,
    jobList
  }) => (
    <>
      <ReportDetailsCard
        data-testid="executive_Summary_Top_Card"
        reportDetails={reportDetails}
        deviceGroupData={deviceGroupData}
        customerName={customerName}
        headers={headers}
        exportData={createExportTableData(jobList)}
        imgDisplay={false}
      />
      <>
        {' '}
        <Wrapper.RDSubContainer>
          <JobsSummary jobsStatus={jobsStatus} />
        </Wrapper.RDSubContainer>
        <Wrapper.RDSubContainer>
          <JobList data={jobList}></JobList>
        </Wrapper.RDSubContainer>
        <Wrapper.RDSubContainer data-testid="reports_Footer">
          <Footer data-testid="reports_Footer_Info" />
        </Wrapper.RDSubContainer>
      </>
    </>
  )

  return (
    <Wrapper.RDContainer data-testid="executive_Summary">
      <Wrapper.RDSubContainer data-testid="executive_Summary_Top_Card_Component">
        {reportDetailsLoading || jobSummaryLoading ? (
          <LoadingIndicator />
        ) : (
          <ReportContent
            reportDetails={reportDetails}
            deviceGroupData={deviceGroupData}
            customerName={customerName}
            headers={headers}
            jobList={jobList}
          />
        )}
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
