import { Button } from '@veneer/core'
import styled from 'styled-components'
import { layout } from '@veneer/tokens/dist/tokens'

export const ReportsGenerateContextualFooterMenu = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: block;
  margin: 0;
  height: 60px;
  right: 0;
  z-index: 3;
  background: #ebf9ff;
  li {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 1px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: flex-end;
    padding: 12px ${layout.size6};
    padding-left: 284px;
    position: relative;
    width: 100%;
    background: #ebf9ff;
  }
`

export const GenerateContextualCancelButton = styled(Button)`
  height: 36px;
  width: 112px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 8px, 20px, 8px, 20px;
`

export const GenerateContextualGenerateButton = styled(Button)`
  height: 36px;
  width: 112px;
  left: 10px;
  top: 0px;
  border-radius: 8px;
  padding: 8px, 20px, 8px, 20px;
`
