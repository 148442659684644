import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Colors } from '../../../../utils/consts'

import Donut from '../../../Charts/PieChart/Donut'
import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'
import { ReportDetailsAccordionContent } from '../../Common/ReportDetailsAccordionContent'

import * as Wrapper from './styles'

export const DeviceAssessmentSummaryCard = (props: { data: any }) => {
  const { data } = props
  const { t } = useI18n()
  const isData = Object.keys(data.reportData).length > 0

  const generateSummaryData = (data, type) => {
    const parseValue = (value) => parseInt(value || 0)
    const total =
      type === 'assessed'
        ? parseValue(data?.compliant) +
          parseValue(data?.nonCompliant?.highRisk) +
          parseValue(data?.nonCompliant?.mediumRisk) +
          parseValue(data?.nonCompliant?.lowRisk)
        : parseValue(data?.notAssessed?.disconnected) +
          parseValue(data?.notAssessed?.unknown)

    return [
      {
        Legends: 'true',
        DonutDiplayText: total,
        DonutDiplaySubText: `${t(
          'ecp-global-reports.executiveSummary.of',
          'of'
        )} ${isData ? data?.total || 0 : '--'} ${t(
          'ecp-global-reports.common.devices',
          'devices'
        )}`,
        TotalCount: parseValue(data?.total),
        CategoryList: [
          {
            CategoryTitle: t(
              'ecp-global-reports.executiveSummary.categoryTitle',
              'Total Devices'
            ),
            CategoryCount: total,
            DisplayCount: true,
            data:
              type === 'assessed'
                ? [
                    {
                      Item: 'compliant',
                      ItemDisplayText: t(
                        'ecp-global-reports.risks.passed',
                        'Passed'
                      ),
                      SubItem: [],
                      Count: data?.compliant || 0,
                      Color: Colors.passed
                    },
                    {
                      Item: 'highRisk',
                      ItemDisplayText: t(
                        'ecp-global-reports.risks.highRiskFailure',
                        'High Risk'
                      ),
                      SubItem: [],
                      Count: data?.nonCompliant?.highRisk || 0,
                      Color: Colors.high
                    },
                    {
                      Item: 'mediumRisk',
                      ItemDisplayText: t(
                        'ecp-global-reports.risks.mediumRiskFailure',
                        'Medium Risk'
                      ),
                      SubItem: [],
                      Count: data?.nonCompliant?.mediumRisk || 0,
                      Color: Colors.medium
                    },
                    {
                      Item: 'lowRisk',
                      ItemDisplayText: t(
                        'ecp-global-reports.risks.lowRiskFailure',
                        'Low Risk'
                      ),
                      SubItem: [],
                      Count: data?.nonCompliant?.lowRisk || 0,
                      Color: Colors.low
                    }
                  ]
                : [
                    {
                      Item: 'Error1',
                      ItemDisplayText: t(
                        'ecp-global-reports.executiveSummary.error',
                        'Error'
                      ),
                      SubItem: [
                        {
                          SubItemName: 'disconnected',
                          ItemDisplayText: t(
                            'ecp-global-reports.executiveSummary.disconnected',
                            'Disconnected'
                          ),
                          SubItemCount: data?.notAssessed?.disconnected || 0
                        }
                      ],
                      Count: data?.notAssessed?.disconnected || 0,
                      Color: Colors.high
                    },
                    {
                      Item: 'Error2',
                      ItemDisplayText: t(
                        'ecp-global-reports.executiveSummary.unknown',
                        'Unknown'
                      ),
                      SubItem: [],
                      Count: data?.notAssessed?.unknown || 0,
                      Color: Colors.unknown
                    }
                  ]
          }
        ]
      }
    ]
  }
  const assessedDeviceSummary = (data) => {
    return generateSummaryData(data, 'assessed')
  }

  const notAssessedDeviceSummary = (data) => {
    return generateSummaryData(data, 'notAssessed')
  }

  const contentData = () => (
    <Wrapper.ReportDetailsCard>
      <Wrapper.ReportDetailsChartCard>
        <Wrapper.ReportDetailsChartDesc>
          <Wrapper.ReportDetailsChartDescTitle data-testid="ExecutiveSummary_AssessedDonut_title">
            {t(
              `ecp-global-reports.executiveSummary.assessedDonut.title`,
              'Assessed Status by Devices'
            )}
          </Wrapper.ReportDetailsChartDescTitle>
          <Wrapper.ReportDetailsChartDescContent data-testid="ExecutiveSummary_AssessedDonut_Subtitle">
            {t(
              `ecp-global-reports.executiveSummary.assessedDonut.subtitle`,
              'The chart below shows the status of all assessed devices in Command Center based on their most severe risk. For example, if one device has five low risk and one high risk, the device would be tagged as high risk.'
            )}
          </Wrapper.ReportDetailsChartDescContent>
        </Wrapper.ReportDetailsChartDesc>
        <Donut
          data={assessedDeviceSummary(data?.reportData)}
          type="executiveSummary"
        />
      </Wrapper.ReportDetailsChartCard>
      <Wrapper.ReportDetailsChartCardDivider></Wrapper.ReportDetailsChartCardDivider>
      <Wrapper.ReportDetailsChartCard>
        <Wrapper.ReportDetailsChartDesc>
          <Wrapper.ReportDetailsChartDescTitle data-testid="ExecutiveSummary_NotAssessedDonut_title">
            {t(
              `ecp-global-reports.executiveSummary.notAssessedDonut.title`,
              'Not Assessed Status by Devices'
            )}
          </Wrapper.ReportDetailsChartDescTitle>
          <Wrapper.ReportDetailsChartDescContent data-testid="ExecutiveSummary_NotAssessedDonut_subtitle">
            {t(
              `ecp-global-reports.executiveSummary.notAssessedDonut.subtitle`,
              'The chart below indicates why devices were not assessed due to various reasons like failed credentials or even never assessed. For example, the good devices are the ones which were never assessed no attempt was made to assess them.'
            )}
          </Wrapper.ReportDetailsChartDescContent>
        </Wrapper.ReportDetailsChartDesc>
        <Donut
          data={notAssessedDeviceSummary(data?.reportData)}
          type="executiveSummary"
        />
      </Wrapper.ReportDetailsChartCard>
    </Wrapper.ReportDetailsCard>
  )
  const content = [
    {
      content: <ReportDetailsAccordionContent ReportContent={contentData()} />,
      expanded: true,
      header: {
        centralArea: (
          <ReportDetailsHeader
            hideButton={true}
            title={t(
              'ecp-global-reports.executiveSummary.top',
              'Device Assessment Summary'
            )}
          />
        )
      },
      id: 'accordionItem2'
    }
  ]

  return <ReportDetailsAccordion content={content} />
}
