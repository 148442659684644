import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useScrollInfo from 'react-element-scroll-hook';
import Card from '@veneer/core/dist/scripts/card';
import {
  Container,
  Content,
  Header,
  PageArea,
} from './styles';
import UnsavedChangesModal from '../UnsavedChangesModal';
import Footer from './Footer';

const Setup = ({
  className,
  title,
  cards,
  onFinish,
  onCancel,
  onExit,
  buttons,
  i18n,
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [buttonsCallback, setButtonsCallback] = useState(() => () => {});
  const [scrollInfo, setRef] = useScrollInfo();
  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    setShadow(!!scrollInfo.y.total);
  }, [scrollInfo]);

  const cardsToShow = useMemo(() => cards.map(card => (
    <Card
      key={card.id}
      content={(
        <card.component
          setButtons={buttonsCallback}
          onFinish={onFinish}
        />
      )}
    />
  )), [buttonsCallback, cards, onFinish]);

  return (
    <Container className={className}>
      <Header>
        <p className="subtitle-large">{title}</p>
      </Header>

      <PageArea ref={setRef}>
        <Content>
          {cardsToShow}
        </Content>
      </PageArea>

      <Footer
        setButtonsCallback={callback => setButtonsCallback(() => callback)}
        i18n={i18n.navigation}
        setShowCancelModal={setShowCancelModal}
        buttons={buttons}
        shadow={shadow}
      />

      <UnsavedChangesModal
        onCancel={() => setShowCancelModal(false)}
        onLeave={() => {
          onCancel();
          onExit();
        }}
        show={showCancelModal}
        i18n={i18n.cancelModal}
      />
    </Container>
  );
};

Setup.defaultProps = {
  className: undefined,
  onCancel: () => {},
  onExit: () => {},
  onFinish: () => {},
  buttons: [],
  i18n: {
    navigation: {
      next: 'Next',
      back: 'Back',
      apply: 'Apply',
      cancel: 'Cancel',
    },
    cancelModal: {
      title: 'Unsaved Changes',
      body: `Are you sure you want to leave this page?
        Your changes have not been saved and will be discarded if you leave now.`,
      cancel: 'Cancel',
      leave: 'Leave',
    },
  },
};

Setup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    component: PropTypes.elementType,
  })).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    area: PropTypes.string,
    props: PropTypes.objectOf(PropTypes.any),
  })),
  onCancel: PropTypes.func,
  onExit: PropTypes.func,
  onFinish: PropTypes.func,
  i18n: PropTypes.shape({
    navigation: PropTypes.shape({
      next: PropTypes.string,
      back: PropTypes.string,
      apply: PropTypes.string,
      cancel: PropTypes.string,
    }),
    cancelModal: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      cancel: PropTypes.string,
      leave: PropTypes.string,
    }),
  }),
};

export default React.memo(Setup);
