import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProgressIndicator } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'
import { SolutionsEntitledSummaryCard } from './SolutionsEntitledSummaryCard'

import { RiskSummaryPolicyItemsCard } from './RiskSummaryByPolicyItemsCard'
import { ExecutiveSummaryMainCard } from './ExecutiveSummaryMainCard'
import { DeviceAssessmentSummaryCard } from './DeviceAssessmentSummaryCard'
import { PolicyItemsAssessedCard } from './PolicyItemsAssessedCard'
import { Footer } from '../Common/Footer'

import { useReports } from '../../../hooks/useReports'
import GeneralContext from '../../../contexts/GeneralContext'
import * as Wrapper from './styles'
// import { ReportDetailsBreadcrumData } from '../../../utils/consts'
import { selectedGroupsFormat } from '../../../utils/genericMethods'
import { setBreadcrumbs } from '../../../utils/commonMethods'

export const ExecutiveSummary = () => {
  const { t } = useI18n()
  const { stack, navigation, breadcrumbs } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId } = useParams()
  const [reportDetails, setReportDetails] = useState(null)
  const [assessmentloading, setAssessmentLoading] = useState(true)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [customerName, setcustomerName] = React.useState('--')
  const [collectionInfo, setCollectionInfo] = useState([])
  const [assessmentSummaryData, setAssessmentSummaryData] = useState({
    reportData: {}
  })

  const isData = (obj) => {
    return (
      obj?.fleetRiskLevel !== null &&
      obj?.total !== null &&
      obj?.compliant !== null &&
      obj?.overAllRisk !== null &&
      obj?.overAllScore !== null &&
      obj?.worseCaseScore !== null
    )
  }

  const reloadAssessmentDetails = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        const res = JSON.parse(response[0])
        res?.collectionInfo && setCollectionInfo(res?.collectionInfo)
        res?.customerName && setcustomerName(res?.customerName)
        isData(res) &&
          setAssessmentSummaryData({
            reportData: res
          })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setAssessmentLoading(false)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
        // setBreadcrumb(
        //   ReportDetailsBreadcrumData(response.fileName || response.reportName)
        // )
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }
  useEffect(() => {
    setAssessmentLoading(true)
    reloadAssessmentDetails()
    reloadReportDetails()
  }, [reportId])

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  return (
    <Wrapper.RDContainer data-testid="executive_Summary">
      <>
        <Wrapper.RDSubContainer data-testid="executive_Summary_Top_Card_Component">
          {reportDetailsLoading || assessmentloading ? (
            <ProgressIndicator
              data-testid="reports_Progress_Indicator"
              style={{ padding: '3% 50%' }}
              appearance={'circular'}
            />
          ) : (
            <ExecutiveSummaryMainCard
              data-testid="executive_Summary_Top_Card"
              reportDetails={reportDetails}
              deviceGroupData={deviceGroupData}
              customerName={customerName}
              imgDisplay={false}
            />
          )}
        </Wrapper.RDSubContainer>

        {assessmentloading ? (
          <ProgressIndicator
            data-testid="reports_Progress_Indicator"
            style={{ padding: '3% 50%' }}
            appearance={'circular'}
          />
        ) : (
          <>
            <Wrapper.RDSubContainer data-testid="Executive_Summary_Device_Assessment_SummaryCard_Component">
              <DeviceAssessmentSummaryCard
                data-testid="Executive_Summary_Device_Assessment_SummaryCard"
                data={assessmentSummaryData}
              />
            </Wrapper.RDSubContainer>

            <Wrapper.RDSubContainer data-testid="Executive_Summary_Policy_Items_AssessedCard_Component">
              <PolicyItemsAssessedCard
                data-testid="Executive_Summary_Policy_Items_AssessedCard"
                data={assessmentSummaryData}
              />
            </Wrapper.RDSubContainer>

            <Wrapper.RDSubContainer data-testid="Executive_Summary_Risk_SummaryCard_Component">
              <RiskSummaryPolicyItemsCard
                data-testid="Executive_Summary_Risk_SummaryCard"
                data={assessmentSummaryData}
              />
            </Wrapper.RDSubContainer>

            <Wrapper.RDSubContainer data-testid="Executive_Summary_License_SummaryCard_Component">
              <SolutionsEntitledSummaryCard
                data-testid="Executive_Summary_License_SummaryCard"
                data={assessmentSummaryData}
              />
            </Wrapper.RDSubContainer>

            <Wrapper.RDSubContainer data-testid="reports_Footer">
              <Footer data-testid="reports_Footer_Info" />
            </Wrapper.RDSubContainer>
          </>
        )}
      </>
    </Wrapper.RDContainer>
  )
}
