import { Accordion } from '@veneer/core'
import React, { useState } from 'react'
import * as Wrapper from './styles'
import { SampleAccordianContent } from '../SampleAccordianContent'
import { ReportDetailsHeader } from '../ReportDetailsHeader'

export const PolicyItemAssesedAccordian = (props) => {
  const { textInfo, title, data } = props
  const [expanded, setExpanded] = useState(true)
  const content = (
    <Wrapper.PolicyAccordianContainer data-testid="reports_Accordian_Content">
      <Wrapper.TextInfo data-testid="reports_Accordian_ContentText_Info">
        <>{textInfo}</>
      </Wrapper.TextInfo>
      <SampleAccordianContent
        data-testid="reports_Accordian_Content_SubAccordian"
        data={data}
      />
    </Wrapper.PolicyAccordianContainer>
  )

  return (
    <Accordion
      data-testid="reports_Accordian_Component"
      appearance="dropShadow"
      onExpand={() => setExpanded(!expanded)}
      onCollapse={() => setExpanded(!expanded)}
      items={[
        {
          content: content,
          expanded: expanded,
          header: {
            centralArea: (
              <Wrapper.ReportDetailsTitleCard data-testid="reports_Accordian_Header_Component">
                <ReportDetailsHeader
                  data-testid="reports_Accordian_Header"
                  title={title}
                  hideButton={true}
                />
              </Wrapper.ReportDetailsTitleCard>
            )
          },
          id: 'accordionItem1'
        }
      ]}
      id="accordion-one-item"
    />
  )
}
