import React from 'react'
import * as Wrapper from './styles'
import { HighlightText } from '../../../HighlightText'

export const ReportListTableStatus = (props) => {
  const { statusName, statusColor, searchItem } = props
  return (
    <Wrapper.ReportListTableStatus data-testid="reports_List_Table_Status">
      <Wrapper.ReportListTableStatusDot
        data-testid="reports_List_Table_Status_Dot"
        Color={statusColor}
      ></Wrapper.ReportListTableStatusDot>
      <Wrapper.ReportListTableStatusText
        data-testid="reports_List_Table_Status_Text"
        id="reports_List_Table_Status_Text"
      >
        <HighlightText value={statusName} searchItem={searchItem} />
      </Wrapper.ReportListTableStatusText>
    </Wrapper.ReportListTableStatus>
  )
}
