// Summary Top Card Group Format
export const formatGroupDetails = (groupDetails, t) => {
  let devicesCount = 0
  let groupCount = 0
  let groupInfo = ''
  let deviceGroupInfo = ''
  groupDetails &&
    groupDetails.length > 0 &&
    groupDetails.forEach((el, i) => {
      if (el.collectionName !== 'All') {
        deviceGroupInfo = i === 0 ? el.collectionName : deviceGroupInfo
        devicesCount = devicesCount + el.devicesCount
        groupCount = groupCount + 1
        groupInfo =
          groupInfo +
          (i > 0
            ? ', ' + el.collectionName
            : i === groupDetails?.length - 1
            ? i > 0
              ? ` ${t('ecp-global-reports.common.and')} ` + el.collectionName
              : el.collectionName
            : el.collectionName)
      }
    })
  return {
    deviceGroupInfo:
      groupCount > 1
        ? `${deviceGroupInfo} & ${groupCount - 1} ${t(
            'ecp-global-reports.generate.deviceGroupSelect.more',
            'more'
          )}`
        : deviceGroupInfo,
    reportGroupInfo: `${groupInfo} (${groupCount} ${
      groupCount > 1
        ? t('ecp-global-reports.common.groups')
        : t('ecp-global-reports.common.group')
    }, ${devicesCount} ${
      devicesCount > 1
        ? t('ecp-global-reports.common.devices')
        : t('ecp-global-reports.common.device')
    })`
  }
}

export const selectedGroupsFormat = (collectionInfo, t) => {
  const filteredList = collectionInfo?.filter((collections) => {
    return collections?.collectionName !== 'All'
  })
  const deviceCount = filteredList.reduce((r, e) => {
    return r + e.devicesCount
  }, 0)
  const groupData = filteredList.reduce(
    (res, elm, i) =>
      i < filteredList.length - 1
        ? res + (i === 0 ? elm.collectionName : ', ' + elm.collectionName)
        : res +
          (i === 0
            ? elm.collectionName
            : ` ${t('ecp-global-reports.common.and', 'and')} ` +
              elm.collectionName),
    ''
  )
  return `${groupData} (${filteredList.length} ${
    filteredList.length === 1
      ? t('ecp-global-reports.common.group', 'group')
      : t('ecp-global-reports.common.groups', 'groups')
  }, ${deviceCount} ${
    deviceCount === 1
      ? t('ecp-global-reports.common.device', 'device')
      : t('ecp-global-reports.common.devices', 'devices')
  })`
}
