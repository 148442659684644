import { DevicesClient } from '../client/DevicesClient'
import { getEnvConfig } from '../utils/consts'

/**
 * Custom hook to fetch device by ID from the Devices API.
 * @param {string} stack - The stack name.
 * @returns {Object} An object containing the `fetchDevicesByID` function.
 */

// This is a custom hook that takes in a stack name as a parameter
export const useDevices = (stack, authProvider) => {
  // Get the environment configuration based on the stack name
  const config = getEnvConfig(stack)
  // Create a new instance of the DevicesClient with the device cache API and device ID
  const devicesClient = new DevicesClient(config.deviceCacheAPI, authProvider)
  const fetchLFDeviceList = async () => {
    try {
      // Call the getDevices method on the DevicesClient instance to fetch the device list
      const deviceList = await devicesClient.getDevices({
        serviceId: 'ws-hp.com/partnerlinksvc'
      })
      // Return the device list if it exists, otherwise return an empty array
      return deviceList || []
    } catch (e) {
      // Log any errors and return an empty array
      console.log(e)
      return []
    }
  }
  // This is a function that fetche device List
  const fetchDevicesByID = async (deviceID) => {
    try {
      // Call the getDevicesByID method on the DevicesClient instance to fetch the device details
      const device = await devicesClient.getDevicesByID(deviceID)
      // Return the device object if it exists, otherwise return an empty object
      return device || {}
    } catch (e) {
      // Log any errors and return an empty object
      console.log(e)
      return {}
    }
  }

  // Return an object containing the fetchDevicesByID and fetchDeviceList function
  return {
    fetchDevicesByID,
    fetchLFDeviceList
  }
}
