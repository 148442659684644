import { Button } from '@veneer/core'
import { IconWarningAlt } from '@veneer/core'
import styled from 'styled-components'

export const ReportsModalHeaderDelete = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 20px !important;
`

export const ReportsGeneratepara = styled.p`
  width: 532px;
  height: 24px;
  left: 0px;
  top: 0px;

  font-size: 16px;
  line-height: 24px;
  color: #212121;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
`

export const DeleteButton = styled(Button)`
  height: 36px;
  width: 76px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 8px, 20px, 8px, 20px;
`
export const DeleteWarningIcon = styled(IconWarningAlt)`
  color: #d06702;
`
