import styled from 'styled-components'
import { typography, layout } from '@veneer/tokens/dist/tokens'
import { Button, Select } from '@veneer/core'

export const ReportsContextualFooterMenu = styled.div`
  position: fixed;
  bottom: 0;
  display: block;
  margin: 0;
  height: 60px;
  right: 0;
  z-index: 3;
  /* left: 0px; */
  left: 260px;

  @media (max-width: 1199px) {
    left: 0px;
  }

  li {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 1px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px ${layout.size6};
    position: relative;
    width: 100%;
    background: #ebf9ff;
  }
`

export const ReportsContextualLeftCancelButtonBox = styled.div`
  display: inline-flex;
`
export const ReportsContextualLeftCancelButton = styled(Button)`
  border: 1px solid #027aae;
  box-sizing: border-box;
  margin-right: ${layout.size6} !important;
  height: 36px;
  width: 112px;
  border-radius: 8px;
`

export const ReportsContextualItemsSelectedBox = styled.div`
  display: inline-flex;
  flex: 1 1 100%;
  order: 1;
  font-family: ${typography.family0};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight4};
  vertical-align: middle;
`

export const ReportsContextualRightActionBox = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  order: 2;
`

export const ReportsContextualRightActionFlexBox = styled.div`
  display: flex;
  div {
    div > div {
      padding-right: 16px;
      div + input + span {
        padding-right: 0px;
        div.vn-select__open-button {
          padding-right: 0px;
        }
      }
    }
  }
`

export const ReportsContextualRightActionSelect = styled(Select)`
  width: 224px;
  background-color: #ffffff;
`

export const ReportsContextualRightActionSelectBox = styled.div`
  height: 36px;
  width: 224px;
`

export const ReportsContextualRightContinueButton = styled(Button)`
  height: 36px;
  width: 112px;
  padding: 12px 8px 12px 0px;
`

export const ReportsContextualRightContinueButtonBox = styled.div`
  display: inline-flex;
  margin-left: 10px;
`
