import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'
import { ProgressIndicator } from '@veneer/core'
import { CharsNotAllowed } from '../../utils/consts'
import { ScopeContext } from '../../scopes/ScopeContext'

export const GenerateReportInput = (props) => {
  const {
    inputValue,
    setInputValue,
    label,
    nameChanging,
    error,
    textError,
    reportDetailsLoading
  } = props

  const { t } = useI18n()
  const { WRITE_SCOPE } = useContext(ScopeContext)
  return (
    <Wrapper.GenerateReportInputContainer>
      <Wrapper.GenerateReportInputLabel
        id="reports_Generate_ReportName"
        data-testid="reports_Generate_ReportName"
      >
        {label}
      </Wrapper.GenerateReportInputLabel>
      {reportDetailsLoading ? (
        <ProgressIndicator appearance="circular" />
      ) : (
        <Wrapper.ReportsNameTextBox
          disabled={nameChanging || !WRITE_SCOPE}
          id="report_name_input"
          data-testid="report_name_input"
          placeholder={t(
            'ecp-global-reports.generate.enterReport',
            'Enter a report name'
          )}
          value={inputValue}
          error={error && textError}
          {...(inputValue.trim().length === 0
            ? {
                helperText: t(
                  'ecp-global-reports.generate.enterReport',
                  'Enter a report name'
                )
              }
            : inputValue.trim().length < 3
            ? {
                helperText: t(
                  'ecp-global-reports.toastNotification.reportNameMin3Char',
                  'Report Name requires minimum 3 characters'
                )
              }
            : CharsNotAllowed.some((_char) =>
                inputValue.includes(_char) ? true : false
              )
            ? {
                helperText:
                  t(
                    'ecp-global-reports.toastNotification.reportNameNotValid',
                    "Report Name can't contain "
                  ) + CharsNotAllowed.join(' , ')
              }
            : {})}
          onChange={(Enteredvalue) => setInputValue(Enteredvalue)}
          required
        />
      )}
    </Wrapper.GenerateReportInputContainer>
  )
}
