import React from 'react'
import { ProgressIndicator } from '@veneer/core'

const LoadingIndicator = () => (
  <ProgressIndicator
    data-testid="reports_Progress_Indicator"
    style={{ padding: '3% 50%' }}
    appearance={'circular'}
  />
)
export default LoadingIndicator
