import styled from 'styled-components'

export const ReportListTable = styled.div<{ withPadding: boolean, isMobileView: boolean }>`
  & > div > div {
    padding-bottom: ${(props) => (props.withPadding && props.isMobileView ? '50px' : '12px')};
  }
`

export const RetryCardContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 7px 0px 0px;
  // border-top: 1px solid rgba(33, 33, 33, 0.1);
`
export const BorderTopCss = styled.div`
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  position: relative;
  width: 100%;
  top: -22px;
`

export const RetryCardIcon = styled.div`
  height: 36px;
  width: 36px;
  background-color: rgba(33, 33, 33, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 8px;
`

export const RetryCardContent = styled.h6`
  color: #404040;
  font-family: 'HP Simplified', Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`
export const RetryCardButton = styled.a`
  color: #027aae;
  font-family: 'HP Simplified', Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

