import { Colors } from '../../../../utils/consts'
import { checkIfZero } from '../../Common/ReportUtils'

export const CategoryList = (data,totalassessed, t) => {
  return [
    {
      CategoryTitle: t(
        `ecp-global-reports.executiveSummary.policyTitle`,
        'Total Policy Items'
      ),
      CategoryCount: totalassessed,
      DisplayCount: true,
      data: [
        {
          Item: 'passed',
          ItemDisplayText: t(`ecp-global-reports.risks.passed`, 'Passed'),
          SubItem: [],
          Count: data?.passed,
          Color: Colors.passed
        },
        {
          Item: 'highRisk',
          ItemDisplayText: t(
            `ecp-global-reports.risks.highRiskFailure`,
            'High Risk'
          ),
          SubItem: [],
          Count: data?.high,
          Color: Colors.high
        },
        {
          Item: 'mediumRisk',
          ItemDisplayText: t(
            `ecp-global-reports.risks.mediumRiskFailure`,
            'Medium Risk'
          ),
          SubItem: [],
          Count: data?.medium,
          Color: Colors.medium
        },
        {
          Item: 'lowRisk',
          ItemDisplayText: t(
            `ecp-global-reports.risks.lowRiskFailure`,
            'Low Risk'
          ),
          SubItem: [],
          Count: data?.low,
          Color: Colors.low
        }
      ]
    }
  ]
}

export const assessedFeatureSummary = (data,t) => {
  const riskLevels = [
    {
      level: 'passed',
      name: 'Passed',
      tKey: 'ecp-global-reports.risks.passed',
      color: Colors.passed
    },
    {
      level: 'high',
      name: 'High Risk',
      tKey: 'ecp-global-reports.risks.highRiskFailure',
      color: Colors.high
    },
    {
      level: 'medium',
      name: 'Medium Risk',
      tKey: 'ecp-global-reports.risks.mediumRiskFailure',
      color: Colors.medium
    },
    {
      level: 'low',
      name: 'Low Risk',
      tKey: 'ecp-global-reports.risks.lowRiskFailure',
      color: Colors.low
    }
  ]

  const categories = [
    'authentication',
    'protectTheDevice',
    'protectTheDocument',
    'protectTheNetwork'
  ]

  return riskLevels.map((riskLevel) => ({
    name: t(riskLevel.tKey, riskLevel.name),
    data: categories.map((category) => ({
      y: checkIfZero(parseInt(data?.[category]?.[riskLevel.level])),
      color: riskLevel.color
    })),
    color: riskLevel.color
  }))
}

