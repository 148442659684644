import styled from 'styled-components'

export const RDContainer = styled.div`
  padding: 24px;
  background-color: #f8f8f8;
`
export const RDSubContainer = styled.div`
  margin-bottom: 24px;
`

export const PolicyText = styled.span`
  font-weight: 100;
`
export const DateText = styled.span``
