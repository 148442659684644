import React, { useCallback } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { MicroFrontend } from '@jarvis/react-mfe-component'
/**
 * Renders a global header component with an optional title text and reload button.
 * @param {object} props - The component titletext and reload(boolean).
 * @returns {JSX.Element} - The rendered GlobalHeader component.
 */
export const GlobalHeader = ({ titleText, disableReload = false }) => {
  const { t } = useI18n()
  const SubMfe = useCallback(({ component, title, type, disableReload }) => {
    return (
      <MicroFrontend
        disableContainerPadding
        component={component}
        title={title}
        type={type}
        disableReload={disableReload}
      />
    )
  }, [])

  if (titleText) {
    const [key, title] = titleText
    return (
      <SubMfe
        type="headerTitle"
        title={t(key, title)}
        component="@jarvis/react-ecp-header"
        disableReload={disableReload}
      />
    )
  }
  return (
    <SubMfe
      type="headerTitle"
      component="@jarvis/react-ecp-header"
      disableReload={disableReload}
    />
  )
}
