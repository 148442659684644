import React, { useContext } from 'react'
import { ScopeContext } from '../../scopes/ScopeContext'
import * as Wrapper from './styles'

export const ReportsOutlineBtn = (props) => {
  const { text, width, onClick, disabled } = props
  const { WRITE_SCOPE } = useContext(ScopeContext)
  let id = 'reports_Button_' + text?.split(' ')?.join('')
  return (
    <Wrapper.ReportsOutlineButton
      id={id}
      data-testid={id}
      disabled={disabled || !WRITE_SCOPE}
      appearance="secondary"
      width={width}
      onClick={onClick}
    >
      {text}
    </Wrapper.ReportsOutlineButton>
  )
}
