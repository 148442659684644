import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'

import { ExecutiveSummary } from './ExecutiveSummary'
import { JobHistoryExecutiveSummary } from './JobHistoryExecutiveSummary'
import { ExecutiveFleetAssessmentSummary } from './ExecutiveFleetAssessmentSummary'
import { DevicesAssessed } from './DevicesAssessed/DeviceList'
import { PolicyItemsAssessed } from './PolicyItemsAssessed'
import { DevicesNotAssessed } from './DevicesNotAssessed'
import { Recommendations } from './Recommendations/DeviceList'
import { Remediation } from './Remediation/DeviceList'
import { ScopeContext } from '../../scopes/ScopeContext'

export const ReportDetails = () => {
  const { READ_SCOPE } = useContext(ScopeContext)

  const { reportType, reportCategory } = useParams()

  const { t } = useI18n()
  if (READ_SCOPE) {
    if (reportCategory === 'security')
      switch (reportType) {
        case 'executiveSummary':
          return <ExecutiveSummary />
        case 'executiveAssessmentSummary':
          return <ExecutiveFleetAssessmentSummary />
        case 'devicesRemediationSummary':
          return <Remediation />
        case 'devicesAssessmentSummary':
          return <DevicesAssessed />
        case 'policyItemsAssessed':
          return <PolicyItemsAssessed />
        case 'devicesRecommendationSummary':
          return <Recommendations />
        case 'devicesNotAssessed':
          return <DevicesNotAssessed />
        default:
          return (
            <div>
              {t(
                'ecp-global-reports.toastNotification.notValidReport',
                'Report Type is not valid'
              )}
            </div>
          )
      }
    if (reportCategory === 'jobs')
      switch (reportType) {
        case 'executiveSummary':
        case 'jobSummary':
          return <JobHistoryExecutiveSummary />
        default:
          return (
            <div>
              {t(
                'ecp-global-reports.toastNotification.notValidReport',
                'Report Type is not valid'
              )}
            </div>
          )
      }
  } else {
    return null
  }
}
