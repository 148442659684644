import React, { useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'
import { Card, ProgressIndicator, ThemeProvider } from '@veneer/core'

import { EditReportContextualFooter } from './EditReportButtons'
import { GenerateReportInput } from '../GenerateReportInput'
import { EditReportDeviceGroup } from './DeviceGroup'

import * as Wrapper from './styles'
import { useReports } from '../../hooks/useReports'
import { CharsNotAllowed } from '../../utils/consts'

export const ReportEditor = () => {
  const { reportId } = useParams()
  const { fetchReportsByID } = useReports()
  const { t } = useI18n()

  const [TextBoxValue, SetTextBoxValue] = useState('')
  const [reportName, setReportName] = useState('')
  const [nameChanging, setNameChanging] = useState(false)
  const [error, setError] = useState(false)
  const [reportDetails, setReportDetails] = useState({
    fileName: '',
    reportName: '',
    category: ''
  })
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)

  const onEditInput = (TextBoxValue) => {
    SetTextBoxValue(TextBoxValue)
    const reportName = TextBoxValue.replace(/\s+/g, ' ').trim()
    if (reportName.length < 3) {
      setError(true)
    } else if (
      CharsNotAllowed.some((_char) =>
        reportName.includes(_char) ? true : false
      )
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        SetTextBoxValue(response.fileName)
        setReportName(response.fileName)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }
  useEffect(() => {
    reloadReportDetails()
  }, [])

  const content = () => {
    return (
      <Wrapper.Container>
        <Wrapper.EditReportDetailsCard>
          <Wrapper.EditReportCategoryCard>
            <Wrapper.ReportContentsHeader
              id="reports_Edit_ReportCategory_Label"
              data-testid="reports_Edit_ReportCategory_Label"
            >
              {t('ecp-global-reports.reportCategory', 'Report Category')}
            </Wrapper.ReportContentsHeader>
            {reportDetailsLoading ? (
              <ProgressIndicator
                appearance="circular"
                data-testid="reports_Edit_ReportName_Progress"
              />
            ) : (
              <Wrapper.ReportContents
                id="reports_Edit_ReportCategory"
                data-testid="reports_Edit_ReportCategory"
              >
                {t(
                  `ecp-global-reports.generate.reportCategoryOptions.${reportDetails.category}`,
                  ''
                )}
              </Wrapper.ReportContents>
            )}
          </Wrapper.EditReportCategoryCard>

          <Wrapper.EditReportTypeCard>
            <Wrapper.ReportContentsHeader
              id="reports_Edit_ReportType_Label"
              data-testid="reports_Edit_ReportType_Label"
            >
              {t('ecp-global-reports.reportDetails.report', 'Report Type')}
            </Wrapper.ReportContentsHeader>
            {reportDetailsLoading ? (
              <ProgressIndicator
                appearance="circular"
                data-testid="reports_Edit_ReportName_Progress"
              />
            ) : (
              <Wrapper.ReportContents
                id="reports_Edit_ReportType"
                data-testid="reports_Edit_ReportType"
              >
                {t(
                  `ecp-global-reports.reportsType.${reportDetails.reportName}`,
                  ''
                )}
              </Wrapper.ReportContents>
            )}
          </Wrapper.EditReportTypeCard>
          <ThemeProvider density="high">
            <GenerateReportInput
              data-testid="reports_Edit_ReportName"
              nameChanging={nameChanging}
              label={t('ecp-global-reports.reportName', 'Report Name') + ' *'}
              inputValue={TextBoxValue}
              error={error}
              reportDetailsLoading={reportDetailsLoading}
              setInputValue={(TextBoxValue) => onEditInput(TextBoxValue)}
              textError={error}
            />
          </ThemeProvider>
        </Wrapper.EditReportDetailsCard>

        <EditReportDeviceGroup reportDetailsLoading={reportDetailsLoading} />
      </Wrapper.Container>
    )
  }
  return (
    <div>
      <Card
        // css={{ width: '972px', minHeight: '336px' }}
        content={content() as any}
        border="dropShadow"
        data-testid="reports_Edit_Form"
      />
      <EditReportContextualFooter
        nameChanging={nameChanging}
        error={error}
        setNameChanging={setNameChanging}
        TextBoxValue={TextBoxValue.replace(/\s+/g, ' ').trim()}
        reportName={reportName}
      />
    </div>
  )
}
