import styled from 'styled-components'

export const ReportDetailsTitleCard = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid #e9e9e9;
`

export const ReportDetailsDescContent = styled.div`
  padding: 8px 8px 0px 8px;
`
