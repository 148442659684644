import styled from 'styled-components'
import { Button } from '@veneer/core'

export const InputContainer = styled.div`
  margin-bottom: ${(props) => (props.error ? '30px' : '16px')};
  height: 58px;
  width: 456px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
`
export const SampleButton = styled(Button)`
  height: 36px;
  width: auto;
  border: 1px solid #027aae;
  border-radius: 8px !important;
  padding: 8px 20px !important;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
`
