import mockupController, { isMockUpMode } from './mockupController'
import { useContext, useEffect, useRef, useState } from 'react'
import { CollectionClient } from '@jarvis/web-stratus-client'
import GeneralContext from '../contexts/GeneralContext'
import { getAPIURL } from '../utils/commonMethods'

// TBD - api : dependency collection svc
const useGetCollectionsOfCollectionClient = () => {
  const { stack, authProvider } = useContext(GeneralContext)
  //const clientDeviceCacheApi = useRef<DeviceCacheApiClient>(null);
  const collectionApi = useRef<CollectionClient>(null)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [isFetching, setIsFetching] = useState(true)

  const fetchDeviceGroups = async () => {
    if (isMockUpMode()) {
      return
    }
    try {
      setIsFetching(true)
      setError(false)
      //const response = await clientDeviceCacheApi.current.list();
      const response = await collectionApi.current.getCollections(
        0,
        169,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )

      const ok =
        response.status > 199 &&
        response.status < 400 &&
        response.status !== 404

      if (response.status === 404) {
        setTotalCount(0)
        setData([])
      }

      if (!ok) {
        throw response.statusText
      }

      setTotalCount(response.data.totalSize)
      setData(response.data.contents)

      return response.data
    } catch (err) {
      setError(err)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (isMockUpMode()) {
      mockupController.getDto('device_groups').then((data) => {
        setData(data.contents)
        setTotalCount(data.totalSize)
        setIsFetching(false)
      })
      return
    }
    //clientDeviceCacheApi.current = new DeviceCacheApiClient(stack, authProvider);
    const collectionApiEndpoint = getAPIURL(
      stack,
      '',
      '/collections-gateway/v1'
    )

    collectionApi.current = new CollectionClient(
      collectionApiEndpoint,
      authProvider
    )

    return () => {
      // destroyed
      collectionApi.current = null
      //clientDeviceCacheApi.current = null;
    }
  }, []) // mounted

  return {
    data,
    error,
    isFetching,
    totalCount,
    fetchDeviceGroups
  }
}

export default useGetCollectionsOfCollectionClient
