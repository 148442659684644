import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { IconFunnel } from '@veneer/core'
import * as Wrapper from './styles'

export const ReportsFilterButton = (props) => {
  const { errorDisabled, isMobileView } = props
  const { t } = useI18n()

  const filterButton = (isMobileView?: boolean) => {
    return (
      <Wrapper.ReportsFilterButton
        id="reports-filter-button"
        data-testid="reports-filter-button"
        leadingIcon={<IconFunnel color="hpBlue7" size={18} />}
        appearance="tertiary"
        disabled={props.isLoading || errorDisabled}
      >
         {isMobileView ? '' : t('ecp-global-reports.button.filter', 'Filter')}
      </Wrapper.ReportsFilterButton>
    )
  }
  return (
    <>
      {isMobileView && filterButton(isMobileView)}
      {!isMobileView && filterButton()}
    </>
  )
}
