import React, { useEffect, useRef, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'

import {
  Accordion,
  Button,
  Card,
  ProgressIndicator,
  Scrollbar,
  ThemeProvider
} from '@veneer/core'
import { ExcelDownload } from '..'
import * as Wrapper from './style'

export const ExcelBox = () => {
  const { t } = useI18n()
  const noOfReport = window?.opener?.data?.length
  const [noOfDownloadedReports, setNoOfDownloadedReports] = useState(0)
  const [html, setHtml] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [content, setContent] = useState(null)
  const [reportData, setReportData] = useState([])
  const htmlRef = useRef(null)

  function downloadReports(list: any[]) {
    list.forEach((row, i) => {
      setTimeout(() => {
        if (!list[i].downloaded) {
          list[i].downloaded = 'InProgress'
        }
        setReportData(list)

        setTimeout(() => {
          let _addExport = (
            <ExcelDownload
              key={i}
              type={row.type}
              reportId={row.reportId}
              fileName={row?.fileName}
            />
          )
          setHtml(<>{_addExport}</>)
          setReportData([
            ...list,
            (list[i].downloaded = t(
              'ecp-global-reports.statusList.completed',
              'Completed'
            ))
          ])
          if (i < list.length - 1) {
            setReportData([
              ...list,
              getNextReportStatus(list[i + 1].downloaded)
            ])
          }
          setNoOfDownloadedReports(i + 1)
        }, 2000)
        if (i === list.length - 1)
          setTimeout(() => {
            window.close()
          }, 8000) //close window 8 secs after the last report downloaded
      }, i * 5000)
      //time interval between the reports to be downloaded
    })
  }

  function getNextReportStatus(downloaded: string) {
    if (
      downloaded ===
        t('ecp-global-reports.statusList.completed', 'Completed') ||
      downloaded === t('ecp-global-reports.statusList.failed', 'Failed')
    ) {
      return downloaded
    } else {
      return 'InProgress'
    }
  }

  function useEffectCallback() {
    document.title = t(
      'ecp-global-reports.toastNotification.downloadingInprogressText',
      'Report download in process ...'
    )
    const list = window.opener.data
    if (list) {
      downloadReports(list)
    } else {
      window.close()
    }
  }

  useEffect(useEffectCallback, [])

  useEffect(() => {
    const reportDownloadData =
      reportData &&
      reportData.map(
        (report) =>
          report.name && (
            <Wrapper.ReportDetails>
              <Wrapper.ReportText>{report.name}</Wrapper.ReportText>
              <Wrapper.ReportStatus>
                {report.downloaded !== 'InProgress' ? (
                  report.downloaded
                ) : (
                  <ProgressIndicator />
                )}
              </Wrapper.ReportStatus>
            </Wrapper.ReportDetails>
          )
      )
    setContent(
      <Scrollbar
        style={{
          height: '70vh',
          overflow: 'auto'
        }}
      >
        <Wrapper.PDFDownloadContainer>
          {reportDownloadData}
        </Wrapper.PDFDownloadContainer>
      </Scrollbar>
    )
  }, [reportData])

  return (
    <>
      <div style={{ position: 'absolute', left: '-2000px', top: 0 }}>
        <div ref={htmlRef}>{html}</div>
      </div>
      <Card
        style={{
          // padding: '5%',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 10000,
          padding: '32px 40px',
          border: 'none'
        }}
        content={
          (
            <div>
              <Wrapper.ReportDownloadTitle data-testid="reports_ExcelBox_Title">
                {t(
                  'ecp-global-reports.toastNotification.downloadingInprogressText',
                  'Report Excel download in process ...'
                )}
              </Wrapper.ReportDownloadTitle>
              <div style={{ paddingBottom: '6px' }}>
                <Wrapper.ProgressContainer>
                  <ProgressIndicator
                    data-testid="reports_ExcelBox_Progress"
                    appearance="linear"
                    behavior="determinate"
                    color="hpBlue7"
                    value={(noOfDownloadedReports / noOfReport) * 100}
                  />
                </Wrapper.ProgressContainer>
                <ThemeProvider density="high">
                  <Accordion
                    data-testid="reports_ExcelBox_Accordion"
                    onExpand={() => {
                      setExpanded(!expanded)
                      window.resizeTo(625, 500)
                    }}
                    onCollapse={() => {
                      setExpanded(!expanded)
                      window.resizeTo(625, 273)
                    }}
                    items={[
                      {
                        content: content,
                        expanded: expanded,
                        header: {
                          centralArea: `${noOfDownloadedReports} / ${noOfReport} ${t(
                            'ecp-global-reports.toastNotification.reportsComplete',
                            'Reports complete'
                          )}`,
                          endArea: (
                            <Button
                              data-testid="reports_ExcelBox_Cancel_Button"
                              appearance="tertiary"
                              onClick={() => window.close()}
                            >
                              {t('ecp-global-reports.button.cancel', 'Cancel')}
                            </Button>
                          )
                        },
                        id: 'reports_ExcelBox_Accordion'
                      }
                    ]}
                    id="accordion-one-item"
                  />
                </ThemeProvider>
              </div>
            </div>
          ) as any
        }
      />
    </>
  )
}
