import styled from 'styled-components'
import { Button } from '@veneer/core'

export const ReportsOutlineButton = styled(Button)`
  /* width: 123px; */
  height: 36px;
  border: 1px solid #027aae;
  box-sizing: border-box;
  border-radius: 8px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 20px !important;
  /* margin-bottom: 16px !important; */
  width: ${(props) => props.width};
  width: auto;
`
