import styled from 'styled-components'
import { Colors } from '../../../utils/consts'
export const RDContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  & > div {
    margin-bottom: 16px;
  }
`
export const PolicyText = styled.span`
  font-weight: 100;
`
export const DateText = styled.span``
export const RDSubContainer = styled.div`
  margin-top: 24px;
`
export const ReportsRiskDot = styled.span`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${(props) => props.Color};
`
function getRiskColor(risk) {
  switch (risk) {
    case 'passed':
      return Colors.passed
    case 'notAssessed':
      return Colors.notAssessed
    case 'lowRisk':
    case 'low':
      return Colors.low
    case 'mediumRisk':
    case 'medium':
      return Colors.medium
    case 'highRisk':
    case 'high':
      return Colors.high
    case 'unknown':
      return Colors.unknown
    default:
      return Colors.white
  }
}

export const ReportRiskIndicator = styled(ReportsRiskDot)`
  background-color: ${(props) => getRiskColor(props.risk)};
`
