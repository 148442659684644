import { Accordion } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'
import React, { useState } from 'react'

/**
 * Renders a summary of completed jobs in an accordion component.
 * @param {Object} jobsStatus - An object containing the status of completed jobs.
 * @param {number} jobsStatus.success - The number of successfully completed jobs.
 * @returns {JSX.Element} - A React component that displays the summary of completed jobs in an accordion.
 */
export const JobsSummary = ({ jobsStatus }) => {
  const { t } = useI18n()

  // State to manage the data for the accordion component
  const [data, setData] = useState([
    {
      header: {
        centralArea: t(
          'ecp-global-reports.jobHistoryExecutiveSummary.jobsSummary',
          'Jobs Summary'
        )
      },
      content: (
        <>
          {t(
            'ecp-global-reports.jobHistoryExecutiveSummary.jobsSummaryInfo',
            'Total completed jobs'
          )}
          <p
            data-testid="Jobs_Completed"
            style={{ fontSize: '24px', color: '#212121' }}
          >
            {jobsStatus?.success || 0}
          </p>
        </>
      ),
      expanded: true,
      id: ''
    }
  ])

  // Event handler for expanding an accordion item
  const handleExpand = (event, index, item) => {
    const updatedData = [...data]
    updatedData[index].expanded = true
    setData(updatedData)
  }

  // Event handler for collapsing an accordion item
  const handleCollapse = (event, index, item) => {
    const updatedData = [...data]
    updatedData[index].expanded = false
    setData(updatedData)
  }

  // Render the JobsSummary component
  return (
    <Accordion
      style={{ marginRight: '3%', marginTop: '24px', width: '100%' }}
      data-testid="jobHistory_jobSummary"
      appearance="dropShadow"
      key="jobSummary"
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      items={data}
    />
  )
}
