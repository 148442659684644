import styled from 'styled-components'

export const RDContainer = styled.div`
  padding: 24px;
  background-color: #f8f8f8;
`
export const RDSubContainer = styled.div`
  margin-bottom: 24px;
`

export const DeviceStatus = styled.div`
  display: flex;
  align-items: center;
`

export const PolicyText = styled.span`
  font-weight: 100;
`
export const DateText = styled.span``

export const DeviceStatusDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: ${(prop) => prop.color};
`

export const DeviceStatusText = styled.div`
  margin-right: 10px;
`
