import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import GeneralContext from '../../contexts/GeneralContext'
import * as Wrapper from './styles'

export const GenerateContextualFooter = (props) => {
  const { createReport, generating } = props
  const { t } = useI18n()
  const { navigation } = useContext(GeneralContext)

  return (
    <Wrapper.ReportsGenerateContextualFooterMenu>
      <li>
        <Wrapper.GenerateContextualCancelButton
          id="reports_Generate_Contextual_Cancel_Button"
          data-testid="reports_Generate_Contextual_Cancel_Button"
          appearance="secondary"
          onClick={() => navigation.push('/reports')}
        >
          {t('ecp-global-reports.button.cancel', 'Cancel')}
        </Wrapper.GenerateContextualCancelButton>
        <Wrapper.GenerateContextualGenerateButton
          disabled={generating}
          id="reports_Generate_Contextual_Generate_Button"
          data-testid="reports_Generate_Contextual_Generate_Button"
          appearance="primary"
          onClick={() => {
            createReport()
          }}
        >
          {t('ecp-global-reports.button.generate', 'Generate')}
        </Wrapper.GenerateContextualGenerateButton>
      </li>
    </Wrapper.ReportsGenerateContextualFooterMenu>
  )
}
