import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import Donut from '../../../Charts/PieChart/Donut'
import { GroupBarchart } from '../../../Charts/GroupBarChart'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'
import { ReportDetailsAccordionContent } from '../../Common/ReportDetailsAccordionContent'
import * as Wrapper from './styles'
import { CategoryList, assessedFeatureSummary } from './Helper'

export const PolicyItemsAssessedCard = ({ data }) => {
  const isData = Object.keys(data.reportData).length > 0
  isData &&
    Object.keys(data.reportData.policyItemDetails).forEach((keyL1) => {
      let keys = ['high', 'low', 'medium', 'passed']
      let featureKeys = [
        'authentication',
        'protectTheDevice',
        'protectTheNetwork',
        'protectTheDocument'
      ]
      if (
        data.reportData.policyItemDetails[keyL1] != null ||
        Object.keys(data.reportData.policyItemDetails[keyL1]).length > 0
      ) {
        if (keyL1 == 'assessmentStatusToCount') {
          Object.keys(data.reportData.policyItemDetails[keyL1]).forEach(
            (keyL2) => {
              keys.map((item) => {
                if (item == keyL2) keys = keys.filter((i) => i != item)
              })
            }
          )
          keys.map(
            (item) => (data.reportData.policyItemDetails[keyL1][item] = 0)
          )
        }
        if (keyL1 == 'featureCategoryToCount') {
          Object.keys(data.reportData.policyItemDetails[keyL1]).forEach(
            (keyL2) => {
              if (
                data.reportData.policyItemDetails[keyL1][keyL2] != null ||
                Object.keys(data.reportData.policyItemDetails[keyL1][keyL2])
                  .length > 0
              ) {
                featureKeys = featureKeys.filter((i) => i != keyL2)
                Object.keys(
                  data.reportData.policyItemDetails[keyL1][keyL2]
                ).forEach((keyL3) => {
                  keys.map((item) => {
                    if (item == keyL3) keys = keys.filter((i) => i != item)
                  })
                })
                keys.map(
                  (item) =>
                    (data.reportData.policyItemDetails[keyL1][keyL2][item] = 0)
                )
              }
            }
          )
          featureKeys.map(
            (item) =>
              (data.reportData.policyItemDetails[keyL1][item] = {
                high: 0,
                medium: 0,
                low: 0,
                passed: 0
              })
          )
        }
      }
    })

  const { t } = useI18n()
  const assessedPolicySummary = (data) => {
    const totalassessed = isData
      ? parseInt(data?.passed) +
        parseInt(data?.high) +
        parseInt(data?.medium) +
        parseInt(data?.low)
      : '--'

    return [
      {
        Legends: 'true',
        DonutDiplayText: isData ? data?.passed : '--',
        DonutDiplaySubText: `${t(
          `ecp-global-reports.executiveSummary.of`,
          'of'
        )} ${totalassessed} ${t(
          `ecp-global-reports.executiveSummary.policies`,
          'Policy Items'
        )}`,
        TotalCount: totalassessed,
        CategoryList: CategoryList(data, totalassessed, t)
      }
    ]
  }

  const contentData = () => (
    <Wrapper.ReportDetailsCard>
      <Wrapper.ReportDetailsChartCard>
        <Wrapper.ReportDetailsChartDesc>
          <Wrapper.ReportDetailsChartDescTitle data-testid="ExecutiveSummary_policyItemsStatus">
            {t(
              'ecp-global-reports.executiveSummary.policyItemsStatus',
              'Assessed Status by Policy Items'
            )}
          </Wrapper.ReportDetailsChartDescTitle>
          <Wrapper.ReportDetailsChartDescContent data-testid="ExecutiveSummary_policyItemsStatusDesc">
            {t(
              'ecp-global-reports.executiveSummary.policyItemsStatusDesc',
              'This chart evaluates the security settings with all policy items assessed across devices in Security Manager.'
            )}
          </Wrapper.ReportDetailsChartDescContent>
        </Wrapper.ReportDetailsChartDesc>
        <Donut
          data={assessedPolicySummary(
            data?.reportData?.policyItemDetails?.assessmentStatusToCount
          )}
          type="executiveSummary"
        />
      </Wrapper.ReportDetailsChartCard>
      <Wrapper.ReportDetailsChartCardDivider></Wrapper.ReportDetailsChartCardDivider>
      <Wrapper.ReportDetailsChartCard>
        <Wrapper.ReportDetailsChartDesc>
          <Wrapper.ReportDetailsChartDescTitle data-testid="ExecutiveSummary_featureItemsStatus">
            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatus',
              'Assessed Status by Feature Category'
            )}
          </Wrapper.ReportDetailsChartDescTitle>
          <Wrapper.ReportDetailsChartDescContent data-testid="ExecutiveSummary_featureItemsStatusDesc">
            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatusDescH',
              'The chart breaks down the policy item’s assessed results into four security feature categories.:'
            )}
            <br></br>

            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatusDesc1',
              '1.Authentication (Certificate, Passwords, SNMP, PINs, LDAP, etc.)'
            )}
            <br></br>

            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatusDesc2',
              '2.Device Protection (Firmware upgrade, Control panel lock, USB control, File assess, etc.)'
            )}
            <br></br>

            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatusDesc3',
              '3.Document Protection  (Email encryption, Send to folder/Fax, Job held timeout/Erase mode, etc.)'
            )}
            <br></br>
            {t(
              'ecp-global-reports.executiveSummary.featureItemsStatusDesc4',
              '4.Network Protection (IPsec/Firewall, FIPS 140, Web encryption, Print/Discovery protocols, etc.)'
            )}
            <br></br>
          </Wrapper.ReportDetailsChartDescContent>
        </Wrapper.ReportDetailsChartDesc>
        <GroupBarchart
          data={assessedFeatureSummary(
            data?.reportData?.policyItemDetails?.featureCategoryToCount,
            t
          )}
        />
      </Wrapper.ReportDetailsChartCard>
    </Wrapper.ReportDetailsCard>
  )
  const content = [
    {
      content: <ReportDetailsAccordionContent ReportContent={contentData()} />,
      expanded: true,
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={t(
              'ecp-global-reports.executiveSummary.middle',
              'Device Assessment Summary'
            )}
            hideButton={true}
          />
        )
      },
      id: 'accordionItem2'
    }
  ]

  return <ReportDetailsAccordion content={content} />
}
