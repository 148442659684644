import React, { useContext } from 'react'
import { GenerateReports } from '../GenerateReports'
import { setBreadcrumbs } from '../../utils/commonMethods'
import GeneralContext from '../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { useI18n } from '@jarvis/react-portal-addons'

export const GenerateDetails = () => {
  const { t } = useI18n()
  const { breadcrumbs, navigation } = useContext(GeneralContext)
  setBreadcrumbs(breadcrumbs, navigation, t)
  return (
    <Wrapper.GenerateDetailsComponent>
      <GenerateReports />
    </Wrapper.GenerateDetailsComponent>
  )
}
