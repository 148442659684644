import { JarvisWebHttpClient } from '@jarvis/web-http'
import { sprintf } from 'sprintf-js'
import { getProps } from '../utils/commonMethods'

export class ReportsClient {
  baseUrl
  days
  apiversion = '1'
  iDs
  jarvisWebClient
  constructor(baseUrl, reportID?, authProvider?) {
    const props = getProps()
    this.baseUrl = baseUrl
    this.iDs = reportID
    this.jarvisWebClient = new JarvisWebHttpClient({
      defaultBaseURL: this.baseUrl,
      defaultAuthProvider: authProvider ?? props.authProvider
    })
  }
  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }

  async getReports() {
    const response = await this.jarvisWebClient.get({
      url: this.getReportsEndPoint()
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  async getReportsByID() {
    const response = await this.jarvisWebClient.get({
      url: this.getReportsByIDEndPoint()
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  async delReports() {
    const response = await this.jarvisWebClient.delete({
      url: this.delReportsEndPoint()
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  async downloadReports() {
    const response = await this.jarvisWebClient.get({
      url: this.downloadReportsEndPoint()
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }
  async editReportName(data) {
    const response = await this.jarvisWebClient.patch({
      url: this.downloadReportsEndPoint(),
      data
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }
  async postReports(data) {
    const response = await this.jarvisWebClient.post({
      url: this.postReportsEndPoint(),
      data
    })
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data
        }
      }
      return []
    }
    throw response.statusText
  }

  getReportsEndPoint() {
    return sprintf('%s/v%s/reports', this.baseUrl, this.apiversion)
  }

  getReportsByIDEndPoint() {
    return sprintf(
      '%s/v%s/reports/%s/details',
      this.baseUrl,
      this.apiversion,
      this.iDs
    )
  }

  delReportsEndPoint() {
    return sprintf('%s/v%s/reports/%s', this.baseUrl, this.apiversion, this.iDs)
  }

  downloadReportsEndPoint() {
    return sprintf('%s/v%s/reports/%s', this.baseUrl, this.apiversion, this.iDs)
  }

  postReportsEndPoint() {
    return sprintf('%s/v%s/reports', this.baseUrl, this.apiversion)
  }
}
