import React from 'react'
import { ButtonGroup } from '@veneer/core'
import * as Wrapper from './styles'

const fetchDeviceCount = (groupNames, groups) => {
  let count = 0
  groups.forEach((grp) => {
    if (groupNames.includes(grp.name)) {
      count += grp.devices
    }
  })
  return count
}
/**
 * Fetches selected groups and returns a formatted string.
 *
 * @param {string[]} groupNames - The array of group names.
 * @param {Function} t - The translation function.
 * @returns {string} - The formatted string of selected groups.
 */
export const fetchSelectedGroups = (groupNames, t) => {
  let temp = groupNames.filter((e) => e !== 'All')
  if (groupNames?.length == 0) return ' '
  else
    return temp.reduce((prev, curr, i) =>
      i < temp.length - 1
        ? prev + ', ' + curr
        : prev + ` ${t('ecp-global-reports.common.and', 'and')} ` + curr
    )
}
/**
 * Calculates the total groups information based on the group names and groups array.
 * @param {string[]} groupNames - The array of group names.
 * @param {any[]} groups - The array of groups.
 * @param {Function} t - The translation function.
 * @returns {string} The total groups information.
 */
export const totalGroupsInfo = (groupNames, groups, t) => {
  let count = groups.length
  if (groupNames.length === count)
    return `${count - 1} ${t('ecp-global-reports.common.groups', 'groups')}`
  else
    return `${groupNames.length} ${
      groupNames.length === 1
        ? t('ecp-global-reports.common.group', 'group')
        : t('ecp-global-reports.common.groups', 'groups')
    } `
}
/**
 * Calculates the total number of devices in the given groups and returns a formatted string.
 * @param groupNames - An array of group names.
 * @param groups - An array of group objects.
 * @param t - The translation function.
 * @returns A formatted string representing the total number of groups and devices.
 */
export const totalGroupsDevicesInfo = (groupNames, groups, t) => {
  let count = groups.length
  if (groupNames.length === count)
    return `(${count - 1} ${t('ecp-global-reports.common.groups', 'groups')}, ${
      groups.find((grp) => grp.name === 'All')?.devices ?? ''
    } ${t('ecp-global-reports.common.devices', 'devices')})`
  else
    return `(${groupNames.length} ${
      groupNames.length === 1
        ? t('ecp-global-reports.common.group', 'group')
        : t('ecp-global-reports.common.groups', 'groups')
    }, ${fetchDeviceCount(groupNames, groups)} ${t(
      'ecp-global-reports.common.devices',
      'devices'
    )})`
}
export const FooterContent = (props) => {
  const {
    setSelectedButton,
    setOpenModal,
    selectedNodes,
    setSelectedNodes,
    groupIds,
    setGroupIds,
    selectedGroupName,
    setGroupNames,
    t
  } = props
  return (
    <div style={{ marginLeft: 'auto', display: 'block' }}>
      <ButtonGroup>
        <Wrapper.GenerateButton
          data-testid="reports_Generate_Cancel_Button"
          id="reports_Generate_Cancel_Button"
          appearance="secondary"
          onClick={() => {
            setSelectedButton(false)
            setOpenModal(false)
            groupIds.length != 0 && setSelectedButton(true)
            setSelectedNodes(groupIds)
          }}
        >
          {t('ecp-global-reports.button.cancel', 'Cancel')}
        </Wrapper.GenerateButton>
        <Wrapper.GenerateButton
          data-testid="reports_Generate_Select_Button"
          id="reports_Generate_Select_Button"
          disabled={selectedNodes?.length === 0}
          onClick={() => {
            setSelectedButton(false)
            setOpenModal(false)
            selectedNodes.length != 0 && setSelectedButton(true)
            setGroupIds(selectedNodes)
            setGroupNames(selectedGroupName)
          }}
        >
          {t('ecp-global-reports.button.select', 'Select')}
        </Wrapper.GenerateButton>
      </ButtonGroup>
    </div>
  )
}
