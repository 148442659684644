import styled from 'styled-components'
import { Button, TextBox } from '@veneer/core'
import { Select } from '@veneer/core'

export const GenerateReportsCardContent = styled.div`
  padding: 24px;
  height: 100%;
`

export const GenerateReportInputContainer = styled.div`
  margin-bottom: 16px;
  height: 58px;
  width: 456px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
`

export const GenerateReportInputLabel = styled.p`
  width: 456px;
  height: 20px;
  margin-bottom: 2px;

  font-size: 16px;
  line-height: 20px;
  color: #404040;
  display: flex;
`

export const GenerateReportSelectOption = styled(Select)`
  > div {
    background-color: #ffffff;
    border: 1px solid #adadad;
    box-sizing: border-box;
    border-radius: 8px;
  }
  width: initial;
  flex-grow: 0;
  height: 36px;
  width: 456px;
  border-radius: 0px;
  & * {
    box-sizing: border-box;
    min-height: none;
    max-height: 100%;
  }
`

export const ReportsViewSampleButton = styled(Button)`
  width: 123px;
  height: 36px;
  border: 1px solid #027aae;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 20px;
  margin-bottom: 16px !important;
`

export const ReportsNameTextBox = styled(TextBox)`
  position: static;
  width: 456px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 2px 0px;
  & * {
    min-height: none;
    max-height: 100%;
  }
`

export const GenerateDeviceGroup = styled.div`
  margin-top: 40px;
`

export const GenerateScheduleOption = styled.div`
  margin-top: 40px;
`

export const GenerateEmailOption = styled.div`
  margin-top: 40px;
`
