import { useContext, useState } from 'react'
import { ReportsClient } from '../client/ReportsClient'
import { getEnvConfig } from '../utils/consts'
import GeneralContext from '../contexts/GeneralContext'

export const useReports = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const [errorFetching, setErrorFetching] = useState(null)
  const [errorDeleting, setErrorDeleting] = useState(null)
  const [errorDownloading, setErrorDownloading] = useState(null)

  const { stack, authProvider } = useContext(GeneralContext)

  const fetchReports = async () => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, '', authProvider)
    try {
      setIsFetching(true)
      return await reportsClient.getReports()
    } catch (e) {
      setErrorFetching(e)
      console.log(e)
      return null // Return null to indicate that the fetch failed
    } finally {
      setIsFetching(false)
    }
  }

  const fetchReportsByID = async (reportId) => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, reportId)
    try {
      setIsFetching(true)
      return await reportsClient.getReportsByID()
    } catch (e) {
      setErrorFetching(e)
      console.log(e)
      return null // Return null to indicate that the fetch failed
    } finally {
      setIsFetching(false)
    }
  }

  const deleteReports = async (reportId, stack) => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, reportId)
    try {
      setIsDeleting(true)
      return await reportsClient.delReports()
    } catch (e) {
      setErrorDeleting(e)
      console.log(e)
      return null // Return null to indicate that the fetch failed
    } finally {
      setIsDeleting(false)
    }
  }

  const downLoadingReports = async (id, stack) => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, id)
    try {
      setIsDownloading(true)
      return await reportsClient.downloadReports()
    } catch (e) {
      setIsDownloading(false)
      setErrorDownloading(e)
      console.log(e)
    } finally {
      setIsDownloading(false)
    }
  }
  const editReport = async (data, id) => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, id)
    return await reportsClient.editReportName(data)
  }
  const postReports = async (data) => {
    const config = getEnvConfig(stack)
    const reportsClient = new ReportsClient(config.SSApiUrl, '')
    return await reportsClient.postReports(data)
  }

  return {
    fetchReports,
    deleteReports,
    downLoadingReports,
    postReports,
    isFetching,
    isDeleting,
    isDownloading,
    editReport,
    errorFetching,
    errorDeleting,
    errorDownloading,
    fetchReportsByID
  }
}
