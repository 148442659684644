import styled from 'styled-components'

export const ReportListTableStatus = styled.div`
  display: flex;
  align-items: center;
`

export const ReportListTableStatusDot = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background-color: ${(props) => props.Color};
  margin-right: 8px;
`

export const ReportListTableStatusText = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
`
