import { Tooltip } from '@veneer/core'
import React from 'react'

export const HighlightText = ({ value, searchItem }) => {
  const getTextWithHighlights = (text, searchText) => {
    const regex = new RegExp(searchText, 'gi')
    let newText =
      text &&
      text.toString().replace(regex, `<span class="highlight">$&</span>`)
    return <span dangerouslySetInnerHTML={{ __html: newText }} />
  }
  if (!value?.length) {
    return <span>{value}</span>
  }
  if (value && value?.trim() && value?.trim()?.length < 35)
    return <>{getTextWithHighlights(value, searchItem)}</>
  else
    return (
      <Tooltip arrow content={value.toString()} portal={true}>
        {getTextWithHighlights(value.substring(0, 35) + '...', searchItem)}
      </Tooltip>
    )
}
