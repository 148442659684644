import styled from 'styled-components'

export const ReportDetailsMainCard = styled.div``

export const ReportDetailsList = styled.ul`
  padding: 2px 8px 14px;
`
export const ReportDetailsDescContent = styled.div`
  padding: 8px 8px 0px 8px;
`
