import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Colors } from '../../../../utils/consts'

import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'
import { ReportDetailsAccordionContent } from '../../Common/ReportDetailsAccordionContent'

import * as Wrapper from './styles'

export const RiskSummaryPolicyItemsCard = ({ data }) => {
  const { t } = useI18n()

  const isData = Object.keys(data.reportData).length > 0
  const riskHighlight = (risk) => {
    let color, text
    switch (risk) {
      case 'high': {
        color = Colors.high
        text = t(`ecp-global-reports.risks.highRisk`, 'High Risk')
        break
      }
      case 'medium': {
        color = Colors.medium
        text = t(`ecp-global-reports.risks.mediumRisk`, 'Medium Risk')
        break
      }
      case 'low': {
        color = Colors.low
        text = t(`ecp-global-reports.risks.lowRisk`, 'Low Risk')
        break
      }
      default:
        return ''
    }
    return (
      <Wrapper.RiskTableLabel data-label="Number of Records" Color={color}>
        {text}
      </Wrapper.RiskTableLabel>
    )
  }

  const contentData = (data) => (
    <>
      <p data-testid="ExecutiveSummary_riskSummary_line1">
        {t(
          'ecp-global-reports.executiveSummary.riskSummary.line1',
          'The calculations below represent a weighted average risk score based on compliance of individual policy items for all current recommendations on all devices in a selected group.'
        )}
      </p>
      <p
        style={{ paddingBottom: '24px' }}
        data-testid="ExecutiveSummary_riskSummary_line2"
      >
        {t(
          'ecp-global-reports.executiveSummary.riskSummary.line2',
          'Overall Risk: A low number is preferred.'
        )}
      </p>
      <Wrapper.RiskTableList>
        <Wrapper.RiskTableHeader>
          <Wrapper.RiskTableHeaderCol1>
            {/* Job Id */}
          </Wrapper.RiskTableHeaderCol1>
          <Wrapper.RiskTableHeaderCol2 data-testid="ExecutiveSummary_riskSummary_noOfReco">
            {t(
              `ecp-global-reports.executiveSummary.noOfReco`,
              'NUMBER OF RECORDS'
            )}
          </Wrapper.RiskTableHeaderCol2>
          <Wrapper.RiskTableHeaderCol3 data-testid="ExecutiveSummary_riskSummary_riskfactor">
            {t(`ecp-global-reports.executiveSummary.riskfactor`, 'RISK FACTOR')}
          </Wrapper.RiskTableHeaderCol3>
          <Wrapper.RiskTableHeaderCol4 data-testid="ExecutiveSummary_riskSummary_totalRisk">
            {t(`ecp-global-reports.executiveSummary.totalRisk`, 'TOTAL RISK')}
          </Wrapper.RiskTableHeaderCol4>
          <Wrapper.RiskTableHeaderCol4 data-testid="ExecutiveSummary_riskSummary_worstRisk">
            {t(`ecp-global-reports.executiveSummary.worstRisk`, 'WORST CASE')}
          </Wrapper.RiskTableHeaderCol4>
        </Wrapper.RiskTableHeader>

        <Wrapper.RiskTableBody>
          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_highRiskFailure"
              id="ExecutiveSummary_riskSummary_highRiskFailure"
            >
              <Wrapper.ReportsRiskDot Color={Colors.high} />{' '}
              {t(`ecp-global-reports.risks.highRiskFailure`, 'High Risk')}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableCol2
              data-label="Number of Records"
              data-testid="ExecutiveSummary_riskSummary_highRiskFailure_risk"
              id="ExecutiveSummary_riskSummary_highRiskFailure_risk"
            >
              {isData ? data.reportData.risk.high : '--'}
            </Wrapper.RiskTableCol2>
            <Wrapper.RiskTableCol3
              data-label="Risk factor"
              data-testid="ExecutiveSummary_riskSummary_highRiskFailure_riskFactor"
            >
              {isData ? 'X ' + data.reportData.riskFactor.high : '--'}
            </Wrapper.RiskTableCol3>
            <Wrapper.RiskTableCol4
              data-label="total Risk"
              data-testid="ExecutiveSummary_riskSummary_highRiskFailure_totalRisk"
            >
              {isData ? '= ' + data.reportData.totalRisk.high : '--'}
            </Wrapper.RiskTableCol4>
            <Wrapper.RiskTableCol4
              data-label="Worst case"
              data-testid="ExecutiveSummary_riskSummary_highRiskFailure_worstCase"
            >
              {isData ? data.reportData.worstCase.high : '--'}
            </Wrapper.RiskTableCol4>
          </Wrapper.RiskTableRow>

          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_mediumRiskFailure"
              id="ExecutiveSummary_riskSummary_mediumRiskFailure"
            >
              <Wrapper.ReportsRiskDot Color={Colors.medium} />
              {t(`ecp-global-reports.risks.mediumRiskFailure`, 'Medium Risk')}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableCol2
              data-label="Number of Records"
              data-testid="ExecutiveSummary_riskSummary_mediumRiskFailure_risk"
            >
              {isData ? data.reportData.risk.medium : '--'}
            </Wrapper.RiskTableCol2>
            <Wrapper.RiskTableCol3
              data-label="Risk factor"
              data-testid="ExecutiveSummary_riskSummary_mediumRiskFailure_riskFactor"
            >
              {isData ? 'X ' + data.reportData.riskFactor.medium : '--'}
            </Wrapper.RiskTableCol3>
            <Wrapper.RiskTableCol4
              data-label="total Risk"
              data-testid="ExecutiveSummary_riskSummary_mediumRiskFailure_totalRisk"
            >
              {isData ? '= ' + data.reportData.totalRisk.medium : '--'}
            </Wrapper.RiskTableCol4>
            <Wrapper.RiskTableCol4
              data-label="Worst case"
              data-testid="ExecutiveSummary_riskSummary_mediumRiskFailure_worstCase"
            >
              {isData ? data.reportData.worstCase.medium : '--'}
            </Wrapper.RiskTableCol4>
          </Wrapper.RiskTableRow>

          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_lowRiskFailure"
              id="ExecutiveSummary_riskSummary_lowRiskFailure"
            >
              <Wrapper.ReportsRiskDot Color={Colors.low} />
              {t(`ecp-global-reports.risks.lowRiskFailure`, 'Low Risk')}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableCol2
              data-label="Number of Records"
              data-testid="ExecutiveSummary_riskSummary_lowRiskFailure_risk"
            >
              {isData ? data.reportData.risk.low : '--'}
            </Wrapper.RiskTableCol2>
            <Wrapper.RiskTableCol3
              data-label="Risk factor"
              data-testid="ExecutiveSummary_riskSummary_lowRiskFailure_riskFactor"
            >
              {isData ? 'X ' + data.reportData.riskFactor.low : '--'}
            </Wrapper.RiskTableCol3>
            <Wrapper.RiskTableCol4
              data-label="total Risk"
              data-testid="ExecutiveSummary_riskSummary_lowRiskFailure_totalRisk"
            >
              {isData ? '= ' + data.reportData.totalRisk.low : '--'}
            </Wrapper.RiskTableCol4>
            <Wrapper.RiskTableCol4
              data-label="Worst case"
              data-testid="ExecutiveSummary_riskSummary_lowRiskFailure_worstCase"
            >
              {isData ? data.reportData.worstCase.low : '--'}
            </Wrapper.RiskTableCol4>
          </Wrapper.RiskTableRow>

          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_passed"
              id="ExecutiveSummary_riskSummary_passed"
            >
              <Wrapper.ReportsRiskDot Color={Colors.passed} />
              {t(`ecp-global-reports.risks.passed`, 'Passed')}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableCol2
              data-label="Number of Records"
              data-testid="ExecutiveSummary_riskSummary_passed_risk"
            >
              {isData ? data.reportData.risk.passed : '--'}
            </Wrapper.RiskTableCol2>
            <Wrapper.RiskTableCol3
              data-label="Risk factor"
              data-testid="ExecutiveSummary_riskSummary_passed_riskFactor"
            >
              {isData ? 'X ' + data.reportData.riskFactor.passed : '--'}
            </Wrapper.RiskTableCol3>
            <Wrapper.RiskTableCol4
              data-label="total Risk"
              data-testid="ExecutiveSummary_riskSummary_passed_totalRisk"
            >
              {isData ? '= ' + data.reportData.totalRisk.passed : '--'}
            </Wrapper.RiskTableCol4>
            <Wrapper.RiskTableCol4
              data-label="Worst case"
              data-testid="ExecutiveSummary_riskSummary_passed_worstCase"
            >
              {isData ? data.reportData.worstCase.passed : '--'}
            </Wrapper.RiskTableCol4>
          </Wrapper.RiskTableRow>
        </Wrapper.RiskTableBody>

        <Wrapper.RiskTableFooter>
          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_overallScore"
              id="ExecutiveSummary_riskSummary_overallScore"
            >
              {t(
                `ecp-global-reports.executiveSummary.overallScore`,
                'Overall Score'
              )}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableCol2
              data-label="Number of Records"
              data-testid="ExecutiveSummary_riskSummary_worseCaseScore"
            >
              {isData
                ? `${data.reportData.overAllScore} ${t(
                    `ecp-global-reports.executiveSummary.outOf`,
                    'out of'
                  )} ${data.reportData.worseCaseScore}`
                : '--'}
            </Wrapper.RiskTableCol2>
          </Wrapper.RiskTableRow>
          <Wrapper.RiskTableRow>
            <Wrapper.RiskTableCol1
              data-testid="ExecutiveSummary_riskSummary_overallRisk"
              id="ExecutiveSummary_riskSummary_overallRisk"
            >
              {t(
                `ecp-global-reports.executiveSummary.overallRisk`,
                'Overall Risk'
              )}
            </Wrapper.RiskTableCol1>
            <Wrapper.RiskTableFlexCol data-label="Number of Records">
              <Wrapper.RiskTableCol
                data-label="Number of Records"
                data-testid="ExecutiveSummary_riskSummary_noOfrecords"
              >
                {isData
                  ? `${parseInt(data.reportData.overAllRisk).toFixed(2)} % `
                  : '--'}
              </Wrapper.RiskTableCol>
              {isData && riskHighlight(data.reportData.fleetRiskLevel)}
            </Wrapper.RiskTableFlexCol>
          </Wrapper.RiskTableRow>
        </Wrapper.RiskTableFooter>
      </Wrapper.RiskTableList>
    </>
  )
  const content = [
    {
      content: (
        <ReportDetailsAccordionContent ReportContent={contentData(data)} />
      ),
      expanded: true,
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={t(
              'ecp-global-reports.executiveSummary.bottom',
              'Risk Summary by Policy Items'
            )}
            hideButton={true}
          />
        )
      },
      id: 'accordionItem1'
    }
  ]
  return <ReportDetailsAccordion content={content} />
}
