import styled from 'styled-components'
import { TextBox } from '@veneer/core'

export const InputContainer = styled.div`
@media only screen and (max-width: 550px) {
  width: 70%
}
  margin-bottom: ${(props) => (props.error ? '30px' : '25px')};
  height: 58px;
  width: 456px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
`
export const ReportNameTextBox = styled(TextBox)`

  position: static;
  width: 456px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 2px 0px;
  & * {
    min-height: none;
    max-height: 100%;
  }
`
