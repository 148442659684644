import React, { useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'
import { RadioButtons, Checkbox, DatePicker, RadioButton } from '@veneer/core'

export const ScheduleOption = () => {
  const { t } = useI18n()
  const [date, setDate] = useState()
  const handleOnDateChange = (newDate) => {
    setDate(newDate)
  }
  return (
    <Wrapper.SectionContainer>
      <Wrapper.SectionTitle
        id="reports_ScheduleOption_Title"
        data-testid="reports_ScheduleOption_Title"
      >
        {t(
          'ecp-global-reports.generate.scheduleoption.header',
          'Schedule option'
        )}
      </Wrapper.SectionTitle>

      <Wrapper.SectionTitleDivider />

      <Wrapper.ScheduleOptionRadioButtons
        data-testid="reports_ScheduleOption_RadioButtons"
        name="radiobutton_default"
        defaultValue="0"
      >
        <RadioButton
          data-testid="reports_ScheduleOption_RadioButton_GenerateOnce"
          value="0"
          label={t(
            'ecp-global-reports.generate.scheduleoption.generateText',
            'Generate now and once'
          )}
          disabled
        />
        <RadioButton
          data-testid="reports_ScheduleOption_RadioButton_Generate"
          value="1"
          label={t(
            'ecp-global-reports.generate.scheduleoption.scheduleText',
            'Schedule to generate'
          )}
          disabled
        />
      </Wrapper.ScheduleOptionRadioButtons>

      <Wrapper.scheduleTimeContainer>
        <DatePicker
          data-testid="reports_ScheduleOption_DatePicker"
          disabled
          label={t(
            'ecp-global-reports.generate.scheduleoption.startDate',
            'Start Date'
          )}
          separateLabel
          placeholder="mm/dd/yyyy"
          onChange={handleOnDateChange}
          value={date}
        />
        <div style={{ marginTop: '18px' }}>
          <Checkbox
            id="reports_ScheduleOption_Recurrence"
            label={t(
              'ecp-global-reports.generate.scheduleoption.recurrence',
              'Recurrence'
            )}
            disabled
          />
        </div>
        <Wrapper.DeviceGroupTimeRadioButton>
          <RadioButtons
            name="radiobutton_default"
            data-testid="reports_ScheduleOption_RadioButtons"
            defaultValue="0"
          >
            <RadioButton
              value="0"
              data-testid="reports_ScheduleOption_RadioButton_Daily"
              label={t(
                'ecp-global-reports.generate.scheduleoption.daily',
                'Daily'
              )}
              disabled
            />
            <RadioButton
              value="1"
              data-testid="reports_ScheduleOption_RadioButton_Weekly"
              label={t(
                'ecp-global-reports.generate.scheduleoption.weekly',
                'Weekly'
              )}
              disabled
            />
            <RadioButton
              value="2"
              data-testid="reports_ScheduleOption_RadioButton_Monthly"
              label={t(
                'ecp-global-reports.generate.scheduleoption.monthly',
                'Monthly'
              )}
              disabled
            />
          </RadioButtons>
        </Wrapper.DeviceGroupTimeRadioButton>
      </Wrapper.scheduleTimeContainer>
    </Wrapper.SectionContainer>
  )
}
