import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import { ReportDetailsDesc } from '../../Common/ReportDetailsDesc'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'

import { getDateAndTime, getDateFormat } from '../../../../utils/commonMethods'
import * as Wrapper from './styles'
import GeneralContext from '../../../../contexts/GeneralContext'

export const FleetAssessmentMainCard = (props) => {
  const { t } = useI18n()
  const { locale } = useContext(GeneralContext)
  const { reportId, reportName, fileName, generatedTime, category } =
    props.reportDetails
  const downloadData = [
    {
      id: reportId,
      type: reportName,
      category: category,
      generatedTime: generatedTime,
      name: fileName || reportName,
      customerName: props.customerName
    }
  ]
  const reportDatas = [
    {
      title: t('ecp-global-reports.reportDetails.report', 'Report Type'),
      desc: t(`ecp-global-reports.reportsType.${reportName}`, '')
    },
    {
      title: t('ecp-global-reports.reportDetails.generated', 'Generated'),
      desc: getDateAndTime(generatedTime || null, locale)
    },
    {
      title: t('ecp-global-reports.reportDetails.deviceGroup', 'Device Group'),
      desc: props.deviceGroupData
    },
    {
      title: t('ecp-global-reports.reportDetails.dateRange', 'Date Range'),
      desc: props.data
        ? `${getDateFormat(props.data[1].date, locale)} to ${getDateFormat(
            props.data[0].date,
            locale
          )}`
        : '--'
    },
    {
      title: t('ecp-global-reports.reportDetails.customer', 'Customer'),
      desc: props.customerName
    }
  ]

  const content = [
    {
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={fileName || reportName}
            downloadData={downloadData}
            hideButton={false}
            showXlsx={false}
          />
        )
      },
      content: (
        <Wrapper.ReportDetailsDescContent>
          <ReportDetailsDesc reportDatas={reportDatas} imgDisplay={props.imgDisplay}/>
        </Wrapper.ReportDetailsDescContent>
      ),
      expanded: true,
      id: fileName
    }
  ]
  return <ReportDetailsAccordion content={content} />
}
