import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'

import { SummaryTopCard } from '../../Common/SummaryTopCard'
import { PolicyItemAssesedCard } from '../../Common/PolicyItemAssesedCard'

import { Footer } from '../../Common/Footer'

import { getDateAndTime, getValue } from '../../../../utils/commonMethods'
import { showSubSetting } from '../../../../utils/consts'
import { formatGroupDetails } from '../../../../utils/genericMethods'

import { useReports } from '../../../../hooks/useReports'
import GeneralContext from '../../../../contexts/GeneralContext'

import * as Wrapper from './styles'
import { useGetPolicyDetails } from '../../../../hooks/useGetPolicyDetails'

export const RemediationSummary = (props) => {
  const { deviceDetails, reportDetails } = props
  const { stack, locale } = useContext(GeneralContext)
  const { t } = useI18n()

  const { deviceId, reportId, deviceReportId } = useParams()
  const { downLoadingReports } = useReports()
  const { policyDetails, policyLoading } = useGetPolicyDetails(
    reportId,
    deviceId
  )

  const [assessmentloading, setAssessmentLoading] = useState(true)
  const [assessmentReport, setAssessmentReport] = useState(null)
  const [groupDetails, setGroupDetails] = useState(null)
  const [customerName, setCustomerName] = useState('--')

  const reloadAssessmentDetails = () => {
    downLoadingReports(deviceReportId, stack)
      .then((response) => {
        const assessData = JSON.parse(response).detailedAssessment
        setCustomerName(JSON.parse(response)?.customerName)
        setGroupDetails(
          formatGroupDetails(JSON.parse(response)?.collectionInfo, t)
        )
        assessData.length &&
          setAssessmentReport(CreateAccordionData(assessData))
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setAssessmentLoading(false)
      })
  }

  const CreateAccordionData = (report) => {
    let accordianData = []
    report.forEach((assessmentOBJ: any, index) => {
      const assessment: any = Object.values(assessmentOBJ)[0]
      const category = assessment[0]?.category.split('-')

      const innerData = assessment.map((assessmentByPolicy: any) => {
        if (showSubSetting[assessmentByPolicy?.settingId])
          return {
            col1: t(
              `ecp-global-reports.settings.${assessmentByPolicy?.settingId}.name`,
              ''
            ),
            col2: '',
            col3: '',
            risk: assessmentByPolicy?.complianceResult,
            subItems: assessmentByPolicy?.policyItemsAssessment.map(
              (setting) => {
                const commonSettingName = t(
                  `ecp-global-reports.settings.common.${
                    setting.policyItemId.split('.')[1]
                  }`,
                  ''
                )

                return {
                  col1:
                    commonSettingName === ''
                      ? t(
                          `ecp-global-reports.settings.${setting.policyItemId}`,
                          ''
                        )
                      : commonSettingName,
                  col2:
                    setting.assessmentObject.assessmentResult ===
                    'remediateSucceeded'
                      ? `${t(
                          'ecp-global-reports.deviceDetails.newValue',
                          'New Value: '
                        )}${
                          getValue(
                            setting?.assessmentObject?.policyValue,
                            t,
                            setting.policyItemId
                          ) + ', '
                        }${t(
                          'ecp-global-reports.deviceDetails.oldValue',
                          'Old Value: '
                        )}${getValue(
                          setting?.assessmentObject?.deviceValue,
                          t,
                          setting.policyItemId
                        )}`
                      : t(
                          `ecp-global-reports.deviceDetails.assessmentResultReason.deviceRejectedValue`,
                          'Remediation Failed'
                        ),
                  col3: ''
                }
              }
            )
          }
        else {
          const object =
            assessmentByPolicy.policyItemsAssessment[0].assessmentObject
          return {
            col1: t(
              `ecp-global-reports.settings.${assessmentByPolicy.settingId}.name`
            ),
            col2:
              object.assessmentResult === 'remediateSucceeded'
                ? `${t(
                    'ecp-global-reports.deviceDetails.newValue',
                    'New Value: '
                  )}${
                    getValue(object?.policyValue, t, object.policyItemId) + ', '
                  }${t(
                    'ecp-global-reports.deviceDetails.oldValue',
                    'Old Value: '
                  )}${getValue(object?.deviceValue, t, object.policyItemId)}`
                : t(
                    `ecp-global-reports.deviceDetails.assessmentResultReason.deviceRejectedValue`,
                    'Remediation Failed'
                  ),
            col3: '',
            risk: assessmentByPolicy.complianceResult
          }
        }
      })
      accordianData.push({
        centralArea: (
          <span
            data-testid={'device-report-policyname-' + index}
            id={'device-report-policyname-' + index}
          >
            {t(
              `ecp-global-reports.deviceDetails.accordionHeader.left.${category[0]}`,
              ''
            )}
            {category.length === 2 &&
              t(
                `ecp-global-reports.deviceDetails.accordionHeader.left.${category[1]}`,
                ''
              )}
          </span>
        ),
        endArea: (
          <>
            <span>
              {t(`ecp-global-reports.deviceDetails.accordionHeader.right`)}
              &nbsp;
            </span>
            <Wrapper.DateText
              data-testid={'remediation_Summary_assessedTime' + index}
              id={'remediation_Summary_assessedTime' + index}
            >
              {getDateAndTime(assessment[0].assessedTime || null, locale)}
            </Wrapper.DateText>
          </>
        ),
        expanded: true,
        innerItems: innerData
      })
    })

    return accordianData
  }
  useEffect(() => {
    setAssessmentLoading(true)
    reloadAssessmentDetails()
  }, [deviceId, reportId])

  return (
    <Wrapper.RDContainer data-testid="remediation_Summary">
      <Wrapper.RDSubContainer data-testid="remediation_Summary_TopCard">
        {deviceDetails && reportDetails && (
          <SummaryTopCard
            data-testid="remediation_Summary_TopCard_Info"
            id="remediation_Summary_TopCard_Info"
            reportDetails={reportDetails}
            deviceDetails={deviceDetails}
            groupDetails={groupDetails}
            policyDetails={policyDetails}
            isLoading={assessmentloading || policyLoading}
            customerName={customerName}
          />
        )}
      </Wrapper.RDSubContainer>

      <Wrapper.RDSubContainer data-testid="remediation_Summary_PolicyItemsAssessed">
        <PolicyItemAssesedCard
          data-testid="remediation_Summary_PolicyItemsAssessed_Info"
          id="remediation_Summary_PolicyItemsAssessed_Info"
          textInfo={t(
            `ecp-global-reports.deviceDetails.${reportDetails.reportName}`,
            ''
          )}
          data={assessmentReport}
          title={t(
            `ecp-global-reports.reportsType.${reportDetails.reportName}`,
            ''
          )}
          isLoading={assessmentloading}
        />
      </Wrapper.RDSubContainer>

      <Wrapper.RDSubContainer data-testid="reports_Footer" id="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
