import React from 'react'
import { Colors } from '../../utils/consts'
import { GroupBarChartPDF } from '../Charts/GroupBarChartPDF'

import {
  assessedFeatureSummary,
  CategoryList
} from '../ReportDetails/ExecutiveSummary/PolicyItemsAssessedCard/Helper'
import Donut from './Donut'
import * as Wrapper from './style'
import HpLogo from './HpLogo'

const ExecutiveSummaryHTML = (data, t, locale) => {
  const obj = data.reportData
  const isData =
    obj?.fleetRiskLevel !== null &&
    obj?.total !== null &&
    obj?.compliant !== null &&
    obj?.overAllRisk !== null &&
    obj?.overAllScore !== null &&
    obj?.worseCaseScore !== null

  if (!isData) data.reportData = {}
  obj.policyItemDetails &&
    Object.keys(obj.policyItemDetails).forEach((keyL1) => {
      let keys = ['high', 'low', 'medium', 'passed']
      let featureKeys = [
        'authentication',
        'protectTheDevice',
        'protectTheNetwork',
        'protectTheDocument'
      ]
      if (
        obj.policyItemDetails[keyL1] != null ||
        Object.keys(obj.policyItemDetails[keyL1]).length > 0
      ) {
        if (keyL1 === 'assessmentStatusToCount') {
          Object.keys(obj.policyItemDetails[keyL1]).forEach((keyL2) => {
            if (keys.includes(keyL2)) {
              keys = keys.filter((item) => item !== keyL2)
            }
          })
          keys.forEach((item) => {
            obj.policyItemDetails[keyL1][item] = 0
          })
        }

        if (keyL1 == 'featureCategoryToCount') {
          Object.keys(obj.policyItemDetails[keyL1]).forEach((keyL2) => {
            if (
              obj.policyItemDetails[keyL1][keyL2] != null ||
              Object.keys(obj.policyItemDetails[keyL1][keyL2]).length > 0
            ) {
              featureKeys = featureKeys.filter((i) => i != keyL2)
              Object.keys(obj.policyItemDetails[keyL1][keyL2]).forEach(
                (keyL3) => {
                  keys.map((item) => {
                    if (item == keyL3) keys = keys.filter((i) => i != item)
                  })
                }
              )
              keys.map(
                (item) => (obj.policyItemDetails[keyL1][keyL2][item] = 0)
              )
            }
          })
          featureKeys.map(
            (item) =>
              (obj.policyItemDetails[keyL1][item] = {
                high: 0,
                medium: 0,
                low: 0,
                passed: 0
              })
          )
        }
      }
    })
  const assessedDeviceSummary = (data) => {
    const totalassessed = isData
      ? parseInt(data?.compliant) +
        parseInt(data?.nonCompliant?.highRisk) +
        parseInt(data?.nonCompliant?.mediumRisk) +
        parseInt(data?.nonCompliant?.lowRisk)
      : '--'
    return [
      {
        Title: t(
          `ecp-global-reports.executiveSummary.assessedDonut.title`,
          'Assessed Status by Devices'
        ), // optional if empty string then it will not be displayed
        Subtitle: t(
          `ecp-global-reports.executiveSummary.assessedDonut.subtitle`,
          'The chart below shows the status of all assessed devices in Command Center based on their most severe risk. For example, if one device has five low risk and one high risk, the device would be tagged as high risk.'
        ),
        DonutDiplayText: totalassessed,
        DonutDiplaySubText: `${t(
          `ecp-global-reports.executiveSummary.of`,
          'of'
        )} ${isData ? data?.total : '--'} ${t(
          `ecp-global-reports.common.devices`,
          'devices'
        )}`,
        TotalCount: parseInt(data?.total),
        CategoryList: [
          {
            CategoryTitle: t(
              `ecp-global-reports.executiveSummary.categoryTitle`,
              'Total Devices'
            ),
            CategoryCount: totalassessed,
            DisplayCount: true,
            data: [
              {
                Item: 'compliant',
                ItemDisplayText: t(`ecp-global-reports.risks.passed`, 'Passed'),
                SubItem: [],
                Count: data?.compliant,
                Color: Colors.passed
              },
              {
                Item: 'highRisk',
                ItemDisplayText: t(
                  `ecp-global-reports.risks.highRiskFailure`,
                  'High Risk'
                ),
                SubItem: [],
                Count: data?.nonCompliant?.highRisk,
                Color: Colors.high
              },
              {
                Item: 'mediumRisk',
                ItemDisplayText: t(
                  `ecp-global-reports.risks.mediumRiskFailure`,
                  'Medium Risk'
                ),
                SubItem: [],
                Count: data?.nonCompliant?.mediumRisk,
                Color: Colors.medium
              },
              {
                Item: 'lowRisk',
                ItemDisplayText: t(
                  `ecp-global-reports.risks.lowRiskFailure`,
                  'Low Risk'
                ),
                SubItem: [],
                Count: data?.nonCompliant?.lowRisk,
                Color: Colors.low
              }
            ]
          }
        ]
      }
    ]
  }
  const notAssessedDeviceSummary = (data) => {
    const totalNotAssessed = isData
      ? parseInt(data?.notAssessed?.disconnected) +
        parseInt(data?.notAssessed?.unknown)
      : '--'
    return [
      {
        Title: t(
          `ecp-global-reports.executiveSummary.notAssessedDonut.title`,
          'Not Assessed Status by Devices'
        ), // optional if empty string then it will not be displayed
        Subtitle: t(
          `ecp-global-reports.executiveSummary.notAssessedDonut.subtitle`,
          'The chart below indicates why devices were not assessed due to various reasons like failed credentials or even never assessed. For example, the good devices are the ones which were never assessed no attempt was made to assess them.'
        ),
        DonutDiplayText: totalNotAssessed,
        DonutDiplaySubText: `${t(
          `ecp-global-reports.executiveSummary.of`,
          'of'
        )} ${isData ? data?.total : '--'} ${t(
          `ecp-global-reports.common.devices`,
          'devices'
        )}`,
        TotalCount: parseInt(data.total),
        CategoryList: [
          {
            CategoryTitle: t(
              `ecp-global-reports.executiveSummary.categoryTitle`,
              'Total Devices'
            ),
            CategoryCount: totalNotAssessed,
            DisplayCount: true,
            data: [
              {
                Item: 'Error1',
                ItemDisplayText: t(
                  `ecp-global-reports.executiveSummary.error`,
                  'Error'
                ),
                SubItem: [
                  {
                    SubItemName: 'disconnected',
                    ItemDisplayText: t(
                      `ecp-global-reports.executiveSummary.disconnected`,
                      'Disconnected'
                    ),
                    SubItemCount: data?.notAssessed?.disconnected
                  }
                ],
                Count: data?.notAssessed?.disconnected,
                Color: Colors.high
              },
              {
                Item: 'Error2',
                ItemDisplayText: t(
                  `ecp-global-reports.executiveSummary.unknown`,
                  'Unknown'
                ),
                SubItem: [],
                Count: data?.notAssessed?.unknown,
                Color: Colors.unknown
              }
            ]
          }
        ]
      }
    ]
  }
  const assessedPolicySummary = (data) => {
    const totalassessed = isData
      ? parseInt(data?.passed) +
        parseInt(data?.high) +
        parseInt(data?.medium) +
        parseInt(data?.low)
      : '--'
    return [
      {
        Title: t(
          'ecp-global-reports.executiveSummary.policyItemsStatus',
          'Assessed Status by Policy Items'
        ),
        Subtitle: t(
          'ecp-global-reports.executiveSummary.policyItemsStatusDesc',
          'This chart evaluates the security settings with all policy items assessed across devices in Security Manager.'
        ),
        DonutDiplayText: data?.passed ?? '--',
        DonutDiplaySubText: `${t(
          `ecp-global-reports.executiveSummary.of`,
          'of'
        )} ${totalassessed} ${t(
          `ecp-global-reports.executiveSummary.policies`,
          'Policy Items'
        )}`,
        TotalCount: totalassessed,
        CategoryList: CategoryList(data, totalassessed, t)
      }
    ]
  }

  const riskHighlight = (risk) => {
    let color, text
    switch (risk) {
      case 'high': {
        color = Colors.high
        text = t(`ecp-global-reports.risks.highRisk`, 'High Risk')
        break
      }
      case 'medium': {
        color = Colors.medium
        text = t(`ecp-global-reports.risks.mediumRisk`, 'Medium Risk')
        break
      }
      case 'low': {
        color = Colors.low
        text = t(`ecp-global-reports.risks.lowRisk`, 'Low Risk')
        break
      }
      default:
        return ''
    }
    return (
      <Wrapper.ReportsRiskHighlightText Color={color}>
        {text}
      </Wrapper.ReportsRiskHighlightText>
    )
  }

  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />
        <Wrapper.ReportsBannerTitle>
          <Wrapper.BannerTextContainer>
            <span>
              {t(
                'ecp-global-reports.pdfHeader',
                'Command Center Security Report:'
              )}
            </span>

            <div style={{ flex: '1' }}>
              <Wrapper.ReportsBannerTitle2>
                {t(
                  'ecp-global-reports.reportsType.executiveSummary',
                  'Executive Summary'
                )}
              </Wrapper.ReportsBannerTitle2>
            </div>
          </Wrapper.BannerTextContainer>
          <Wrapper.ReportsBannerTitle3>
            <Wrapper.ReportsPDFGenerationTime>
              {data.generatedTime}
            </Wrapper.ReportsPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 41
                  ? '20px'
                  : '0'
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: ${data.deviceGroup}`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p style={{ wordBreak: 'break-word' }}>
            {`${t('ecp-global-reports.reportDetails.customer', 'Customer')}: ${
              data.customerName
            }`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>

      <Wrapper.ReportsActivitytable
        data-testid="reports_activityTable"
        className="keep-together-section"
      >
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData>
              {t(
                'ecp-global-reports.executiveSummary.top',
                'Device Assessment Summary'
              )}
            </Wrapper.ReportsActivitytableData>
            <Wrapper.ReportsActivitytableData />
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <tr>
            <td
              style={{ borderRight: `2px solid ${Colors.gray1}` }}
              data-testid="reports_donutChart1"
            >
              <Donut data={assessedDeviceSummary(data?.reportData)} t={t} />
            </td>
            <td data-testid="reports_donutChart2">
              <Donut data={notAssessedDeviceSummary(data?.reportData)} t={t} />
            </td>
          </tr>
        </tbody>
      </Wrapper.ReportsActivitytable>

      <Wrapper.ReportsActivitytable
        data-testid="reports_activityTable"
        className="keep-together-section"
      >
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData>
              {t(
                'ecp-global-reports.executiveSummary.middle',
                'Policy Items Assessed Summary'
              )}
            </Wrapper.ReportsActivitytableData>
            <Wrapper.ReportsActivitytableData />
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <tr>
            <td
              style={{ borderRight: `2px solid ${Colors.gray1}` }}
              data-testid="reports_donutChart1"
            >
              <Donut
                data={assessedPolicySummary(
                  data?.reportData?.policyItemDetails?.assessmentStatusToCount
                )}
                t={t}
              />
            </td>
            <td>
              <div>
                <Wrapper.ReportDetailsChartDescTitle locale={locale}>
                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatus',
                    'Assessed Status by Feature Category'
                  )}
                </Wrapper.ReportDetailsChartDescTitle>
                <Wrapper.ReportDetailsChartDescContent>
                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatusDescH',
                    'The chart breaks down the policy item’s assessed results into four security feature categories.:'
                  )}
                  <br></br>

                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatusDesc1',
                    '1.Authentication (Certificate, Passwords, SNMP, PINs, LDAP, etc.)'
                  )}
                  <br></br>

                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatusDesc2',
                    '2.Device Protection (Firmware upgrade, Control panel lock, USB control, File assess, etc.)'
                  )}
                  <br></br>

                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatusDesc3',
                    '3.Document Protection (Email encryption, Send to folder/Fax, Job held timeout/Erase mode, etc.)'
                  )}
                  <br></br>
                  {t(
                    'ecp-global-reports.executiveSummary.featureItemsStatusDesc4',
                    '4.Network Protection (IPsec/Firewall, FIPS 140, Web encryption, Print/Discovery protocols, etc.)'
                  )}
                  <br></br>
                </Wrapper.ReportDetailsChartDescContent>
              </div>
              <GroupBarChartPDF
                locale={locale}
                data={assessedFeatureSummary(
                  data?.reportData?.policyItemDetails?.featureCategoryToCount,
                  t
                )}
              />
            </td>
          </tr>
        </tbody>
      </Wrapper.ReportsActivitytable>

      <Wrapper.ReportsActivitytable
        data-testid="reports_activityTable"
        className="keep-together-section"
      >
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData>
              {t(
                'ecp-global-reports.executiveSummary.bottom',
                'Risk Summary by Policy Items'
              )}
            </Wrapper.ReportsActivitytableData>
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <div style={{ padding: '10px' }}>
            <Wrapper.ReportsSummaryRiskBody>
              <p>
                {t(
                  'ecp-global-reports.executiveSummary.riskSummary.line1',
                  'The calculations below represent a weighted average risk score based on compliance of individual policy items for all current recommendations on all devices in a selected group.'
                )}
              </p>
              <p>
                {t(
                  'ecp-global-reports.executiveSummary.riskSummary.line2',
                  'Overall Risk: A low number is preferred.'
                )}
              </p>
            </Wrapper.ReportsSummaryRiskBody>
            <Wrapper.RiskSummaryTable data-testid="reports_riskTable">
              <Wrapper.RiskSummaryTableHead>
                <tr>
                  <Wrapper.RiskSummaryTableData />
                  <Wrapper.RiskSummaryTableData>
                    {t(
                      `ecp-global-reports.executiveSummary.noOfReco`,
                      'NUMBER OF RECORDS'
                    )}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {t(
                      `ecp-global-reports.executiveSummary.riskfactor`,
                      'RISK FACTOR'
                    )}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {t(
                      `ecp-global-reports.executiveSummary.totalRisk`,
                      'TOTAL RISK'
                    )}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {t(
                      `ecp-global-reports.executiveSummary.worstRisk`,
                      'WORST CASE'
                    )}
                  </Wrapper.RiskSummaryTableData>
                </tr>
              </Wrapper.RiskSummaryTableHead>
              <tbody>
                <tr>
                  <Wrapper.RiskSummaryTableData>
                    <Wrapper.ReportsRiskDot Color={Colors.high} />
                    {t(`ecp-global-reports.risks.highRiskFailure`, 'High Risk')}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.risk.high : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? 'X ' + obj.riskFactor.high : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? '= ' + obj.totalRisk.high : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.worstCase.high : '--'}
                  </Wrapper.RiskSummaryTableData>
                </tr>
                <tr>
                  <Wrapper.RiskSummaryTableData>
                    <Wrapper.ReportsRiskDot Color={Colors.medium} />
                    {t(
                      `ecp-global-reports.risks.mediumRiskFailure`,
                      'Medium Risk'
                    )}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.risk.medium : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? 'X ' + obj.riskFactor.medium : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? '= ' + obj.totalRisk.medium : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.worstCase.medium : '--'}
                  </Wrapper.RiskSummaryTableData>
                </tr>
                <tr>
                  <Wrapper.RiskSummaryTableData>
                    <Wrapper.ReportsRiskDot Color={Colors.low} />
                    {t(`ecp-global-reports.risks.lowRiskFailure`, 'Low Risk')}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.risk.low : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? 'X ' + obj.riskFactor.low : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? '= ' + obj.totalRisk.low : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.worstCase.low : '--'}
                  </Wrapper.RiskSummaryTableData>
                </tr>
                <tr>
                  <Wrapper.RiskSummaryTableData>
                    <Wrapper.ReportsRiskDot Color={Colors.passed} />
                    {t(`ecp-global-reports.risks.passed`, 'Passed')}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.risk.passed : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? 'X ' + obj.riskFactor.passed : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? '= ' + obj.totalRisk.passed : '--'}
                  </Wrapper.RiskSummaryTableData>
                  <Wrapper.RiskSummaryTableData>
                    {isData ? obj.worstCase.passed : '--'}
                  </Wrapper.RiskSummaryTableData>
                </tr>
                <tr style={{ borderTop: `0.7px solid ${Colors.gray1}` }}>
                  <Wrapper.OverallLabel>
                    {t(
                      `ecp-global-reports.executiveSummary.overallScore`,
                      'Overall Score'
                    )}
                  </Wrapper.OverallLabel>
                  <Wrapper.OverallData>
                    {isData
                      ? `${obj.overAllScore} ${t(
                          `ecp-global-reports.executiveSummary.outOf`,
                          'out of'
                        )} ${obj.worseCaseScore}`
                      : '--'}
                  </Wrapper.OverallData>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <Wrapper.OverallLabel>
                    {t(
                      `ecp-global-reports.executiveSummary.overallRisk`,
                      'Overall Risk'
                    )}
                  </Wrapper.OverallLabel>
                  <Wrapper.OverallData>
                    {isData
                      ? `${parseInt(obj.overAllRisk).toFixed(2)} % `
                      : '--'}
                    {isData && riskHighlight(obj.fleetRiskLevel)}
                  </Wrapper.OverallData>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Wrapper.RiskSummaryTable>
          </div>
        </tbody>
      </Wrapper.ReportsActivitytable>

      <Wrapper.ReportsActivitytable
        data-testid="reports_activityTable"
        className="keep-together-section"
      >
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData>
              {t(
                'ecp-global-reports.executiveSummary.last',
                'Solutions Entitled Summary'
              )}
            </Wrapper.ReportsActivitytableData>
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <div style={{ padding: '10px' }}>
            <Wrapper.ReportsLicenseSummaryHeading>
              {t(
                'ecp-global-reports.executiveSummary.smAdvanced',
                'Secure Fleet Manager Advanced (Devices)'
              )}
            </Wrapper.ReportsLicenseSummaryHeading>
            <Wrapper.ReportsLicenseSummaryContents>
              {data?.reportData?.advanceCount || '0'}
            </Wrapper.ReportsLicenseSummaryContents>

            <Wrapper.ReportsLicenseSummaryHeading>
              {t(
                'ecp-global-reports.executiveSummary.smBasic',
                'Secure Fleet Manager (Devices)'
              )}
            </Wrapper.ReportsLicenseSummaryHeading>
            <Wrapper.ReportsLicenseSummaryContents>
              {data?.reportData?.standardCount || '0'}
            </Wrapper.ReportsLicenseSummaryContents>
          </div>
        </tbody>
      </Wrapper.ReportsActivitytable>
    </Wrapper.ReportsPDFContent>
  )
}

export default ExecutiveSummaryHTML
