import styled from 'styled-components'
// import tokens from "@veneer/tokens";

export const SectionTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

export const SectionTitleDivider = styled.div`
  border-bottom: 1px solid #ebe7e7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
`

export const EmailOptionSubContent = styled.div`
  margin-top: 18px;
`

export const EmailOptionContent = styled.div`
  margin-top: 18px;
  margin-left: 32px;
`

export const EmailOptionTextbox = styled.div`
  margin-top: 18px;
  margin-left: 32px;
`
export const EmailOptionFooterContent = styled.div`
 @media only screen and (max-width: 550px) {
  display: -webkit-box;
  margin-left: 0px;
  margin-top: auto;
  }
  margin-left: 32px;
  display: flex;
  margin-top: 30px;
`

export const EmailOptionFooterContentText = styled.p`
  @media only screen and (max-width: 550px) {
    white-space: break-spaces;
    width: 100%;
  }
  width: 413px;
  height: 24px;
  left: 0px;
  margin-top: 5px;
  white-space: nowrap;

  font-size: 16px;
  line-height: 24px;
`

export const EmailOptionFooterContentIcon = styled.div`
  margin-top: 3px;
`
export const SectionContainer = styled.div`
  margin-top: 40px;
`
