import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'

import { SummaryTopCard } from '../../Common/SummaryTopCard'
import { PolicyItemAssesedCard } from '../../Common/PolicyItemAssesedCard'

import { Footer } from '../../Common/Footer'

import { getDateAndTime, getValue } from '../../../../utils/commonMethods'
import { showSubSetting } from '../../../../utils/consts'
import { formatGroupDetails } from '../../../../utils/genericMethods'

import { useReports } from '../../../../hooks/useReports'
import GeneralContext from '../../../../contexts/GeneralContext'

import * as Wrapper from './styles'
import { useGetPolicyDetails } from '../../../../hooks/useGetPolicyDetails'

export const RecommendationsSummary = (props) => {
  const { deviceDetails, reportDetails } = props
  const { stack, locale } = useContext(GeneralContext)
  const { t } = useI18n()

  const { deviceId, reportId, deviceReportId } = useParams()
  const { downLoadingReports } = useReports()
  const { policyDetails, policyLoading } = useGetPolicyDetails(
    reportId,
    deviceId
  )

  const [recommendationLoading, setRecommendationLoading] = useState(true)
  const [recommendationReport, setRecommendationReport] = useState(null)
  const [groupDetails, setGroupDetails] = useState(null)
  const [customerName, setCustomerName] = useState('--')

  const reloadRecommendationDetails = () => {
    downLoadingReports(deviceReportId, stack)
      .then((response) => {
        const assessData = JSON.parse(response).detailedAssessment
        setCustomerName(JSON.parse(response)?.customerName)
        setGroupDetails(
          formatGroupDetails(JSON.parse(response)?.collectionInfo, t)
        )
        assessData.length
          ? setRecommendationReport(CreateAccordionData(assessData))
          : setRecommendationReport([])
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setRecommendationLoading(false)
      })
  }

  const CreateAccordionData = (report) => {
    let accordianData = []
    report.forEach((recommendationOBJ: any, index) => {
      const recommendation: any = Object.values(recommendationOBJ)[0]
      const category = recommendation[0]?.category.split('-')

      const innerData = recommendation.map((recommendationByPolicy: any) => {
        if (showSubSetting[recommendationByPolicy?.settingId])
          return {
            col1: t(
              `ecp-global-reports.settings.${recommendationByPolicy?.settingId}.name`,
              ''
            ),
            col2: '',
            col3: '',
            risk: recommendationByPolicy?.complianceResult,
            subItems: recommendationByPolicy?.policyItemsAssessment
            .filter(setting => setting.policyItemId.split('.').length <= 2)
            .map(
              (setting) => {
                const commonSettingName = t(
                  `ecp-global-reports.settings.common.${
                    setting.policyItemId.split('.')[1]
                  }`,
                  ''
                )
                return {
                  col1:
                    commonSettingName === ''
                      ? t(
                          `ecp-global-reports.settings.${setting.policyItemId}`,
                          ''
                        )
                      : commonSettingName,
                  col2:
                    setting.assessmentObject.assessmentResult ===
                      'assessSucceeded' ||
                    setting.assessmentObject.assessmentResult ===
                      'remediateSucceeded'
                      ? t(`ecp-global-reports.risks.passed`, 'Passed')
                      : t(
                          `ecp-global-reports.deviceDetails.assessmentResultReason.${setting.assessmentObject.assessmentResultReason}`,
                          ''
                        ),
                  col3:
                    setting.assessmentObject.assessmentResultReason ===
                    'valueMismatch'
                      ? `${t(
                          'ecp-global-reports.tableHeader.policy',
                          'Policy'
                        )}: ${
                          getValue(
                            setting?.assessmentObject?.policyValue,
                            t,
                            setting.policyItemId
                          ) + ', '
                        }${t(
                          'ecp-global-reports.deviceDetails.deviceValue',
                          '"Device: "'
                        )}${getValue(
                          setting?.assessmentObject?.deviceValue,
                          t,
                          setting.policyItemId
                        )}`
                      : setting.assessmentObject.assessmentResult !==
                          'assessSucceeded' &&
                        setting.assessmentObject.assessmentResult !==
                          'remediateSucceeded' &&
                        t(
                          'ecp-global-reports.deviceDetails.assessmentResult.remediateFailed',
                          'Cannot Remediate'
                        )
                }
              }
            )
          }
        else {
          const object =
            recommendationByPolicy.policyItemsAssessment[0].assessmentObject
          return {
            col1: t(
              `ecp-global-reports.settings.${recommendationByPolicy.settingId}.name`,
              ''
            ),

            col2:
              object.assessmentResult === 'assessSucceeded' ||
              object.assessmentResult === 'remediateSucceeded'
                ? t(`ecp-global-reports.risks.passed`, 'Passed')
                : t(
                    `ecp-global-reports.deviceDetails.assessmentResultReason.${object.assessmentResultReason}`,
                    ''
                  ),
            col3:
              object.assessmentResultReason === 'valueMismatch'
                ? `${t(
                    'ecp-global-reports.deviceDetails.deviceValue',
                    '"Device: "'
                  )}${
                    getValue(object?.deviceValue, t, object.policyItemId) + ', '
                  }${t(
                    'ecp-global-reports.tableHeader.policy',
                    'Policy'
                  )}: ${getValue(object?.policyValue, t, object.policyItemId)}`
                : object.assessmentResult !== 'assessSucceeded' &&
                  object.assessmentResult !== 'remediateSucceeded' &&
                  t(
                    'ecp-global-reports.deviceDetails.assessmentResult.remediateFailed',
                    'Cannot Remediate'
                  ),
            risk: recommendationByPolicy.complianceResult
          }
        }
      })
      accordianData.push({
        centralArea: (
          <span
            data-testid={'device-report-policyname-' + index}
            id={'device-report-policyname-' + index}
          >
            {t(
              `ecp-global-reports.deviceDetails.accordionHeader.left.${category[0]}`,
              ''
            )}
            {category.length === 2 &&
              t(
                `ecp-global-reports.deviceDetails.accordionHeader.left.${category[1]}`,
                ''
              )}
          </span>
        ),
        endArea: (
          <>
            <span>
              {t(
                `ecp-global-reports.deviceDetails.accordionHeader.right`,
                'Date Run: '
              )}
              &nbsp;
            </span>
            <Wrapper.DateText
              data-testid={'recommendation_Summary_assessedTime' + index}
              id={'recommendation_Summary_assessedTime' + index}
            >
              {getDateAndTime(recommendation[0].assessedTime || null, locale)}
            </Wrapper.DateText>
          </>
        ),
        expanded: true,
        innerItems: innerData
      })
    })

    return accordianData
  }
  useEffect(() => {
    setRecommendationLoading(true)
    reloadRecommendationDetails()
  }, [deviceId, reportId])

  return (
    <Wrapper.RDContainer
      data-testid="recommendation_Summary"
      id="recommendation_Summary"
    >
      <Wrapper.RDSubContainer
        data-testid="recommendation_Summary_TopCard"
        id="recommendation_Summary_TopCard"
      >
        {deviceDetails && reportDetails && (
          <SummaryTopCard
            data-testid="recommendation_Summary_TopCard_Info"
            id="recommendation_Summary_TopCard_Info"
            reportDetails={reportDetails}
            deviceDetails={deviceDetails}
            groupDetails={groupDetails}
            isLoading={recommendationLoading || policyLoading}
            policyDetails={policyDetails}
            customerName={customerName}
          />
        )}
      </Wrapper.RDSubContainer>
      <Wrapper.RDSubContainer
        data-testid="recommendation_Summary_PolicyItemsAssessed"
        id="recommendation_Summary_PolicyItemsAssessed"
      >
        <PolicyItemAssesedCard
          data-testid="recommendation_Summary_PolicyItemsAssessed_Info"
          id="recommendation_Summary_PolicyItemsAssessed_Info"
          textInfo={t(
            `ecp-global-reports.deviceDetails.${reportDetails.reportName}`,
            ''
          )}
          data={recommendationReport}
          title={t(
            `ecp-global-reports.reportsType.${reportDetails.reportName}`,
            ''
          )}
          isLoading={recommendationLoading}
        />
      </Wrapper.RDSubContainer>
      <Wrapper.RDSubContainer data-testid="reports_Footer" id="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
