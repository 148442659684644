import React, { useEffect, useState } from 'react'
import { Scrollbar, ProgressIndicator } from '@veneer/core'
import {
  Wrapper,
  TreeViewWrapper,
  DeviceGroupsLoading,
  TreeViewBorder,
  TreeStyled,
  ErrorMessage
} from './styles'

export const DeviceGroupsTreeView = (props: {
  groups: []
  totalCount: number
  error: boolean
  groupIds: []
  isFetching: boolean
  setSelectedGroupName: (param: any) => void
  selectedNodes: any[]
  setSelectedNodes: (param: any) => void
  parentGroupId: any[]
}) => {
  const [groupData, setGroupData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [totalDeviceCount, setTotalDeviceCount] = useState(null)
  useEffect(() => {
    if (props.groups) {
      setTotalDeviceCount(props.totalCount ?? 0)
      setGroupData(props.groups)
    }
  }, [props.groups])

  useEffect(() => {
    if (props.error) {
      renderError()
    }
  }, [props.error])

  const renderError = () => {
    setErrorMessage('Error')
  }

  const createTreeViewNodes = (groupData) => {
    let parentNodes = []

    if (!totalDeviceCount) {
      parentNodes = [
        {
          id: props.parentGroupId,
          label: 'All',
          totalChildren: totalDeviceCount?.toString()
        }
      ]
    } else {
      const childNodes = groupData
        .filter((group) => group.id !== props.parentGroupId[0])
        .map((group) => {
          return {
            id: group.id,
            label: group.name,
            totalChildren:
              group.devices === -1 ? '0' : group?.devices?.toString()
          }
        })

      parentNodes = groupData
        .filter((group) => group.id === props.parentGroupId[0])
        .map((group) => {
          return {
            id: group.id,
            label: group.name,
            totalChildren: group?.devices?.toString(),
            nodes: childNodes
          }
        })
    }
    return parentNodes
  }

  const handleChangeEvent = (event, id) => {
    const selectedDeviceGroup = id
    if (selectedDeviceGroup.length === 0) {
      props.setSelectedNodes([])
      props.setSelectedGroupName([])
    } else {
      props.setSelectedNodes(selectedDeviceGroup)
      let i, j
      const item = []
      for (i = 0; i < groupData.length; i++) {
        for (j = 0; j < selectedDeviceGroup.length; j++) {
          if (groupData[i].id == selectedDeviceGroup[j])
            item.push(groupData[i].name)
        }
      }
      props.setSelectedGroupName(item)
    }
    event.preventDefault()
  }

  const renderTreeView = () => {
    const nodes = createTreeViewNodes(groupData)
    return (
      <TreeViewBorder>
        <Scrollbar
          customStyle={{
            height: '100%',
            overflow: 'auto'
          }}
        >
          <TreeStyled
            data-testid="report_Device_Group_TreeView"
            defaultExpandedNodes={props.parentGroupId}
            nodes={nodes}
            onChange={handleChangeEvent}
            selectedNodes={props.selectedNodes}
            nodeSelector="multiSelection"
          />
        </Scrollbar>
      </TreeViewBorder>
    )
  }

  return (
    <Wrapper>
      {props.isFetching ? (
        <DeviceGroupsLoading>
          <ProgressIndicator data-testid="progress-indicator" />
        </DeviceGroupsLoading>
      ) : (
        <TreeViewWrapper>
          {errorMessage ? (
            <ErrorMessage data-testid="group-errmsg">
              {errorMessage}
            </ErrorMessage>
          ) : (
            renderTreeView()
          )}
        </TreeViewWrapper>
      )}
    </Wrapper>
  )
}
