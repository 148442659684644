import styled from 'styled-components'
import { TextBox } from '@veneer/core'

export const GenerateReportInputContainer = styled.div`
  margin-bottom: 40px;
  height: 58px;
  width: 456px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
`

export const GenerateReportInputLabel = styled.p`
  width: 456px;
  height: 20px;
  margin-bottom: 2px;

  font-size: 16px;
  line-height: 20px;
  color: #404040;
  display: flex;
`

export const ReportsNameTextBox = styled(TextBox)`
  position: static;
  width: 456px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 2px 0px;
  & * {
    min-height: none;
    max-height: 100%;
  }
`
