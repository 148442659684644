//Shareable component for all the preview Main card reports
import React, { useContext, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'
import { ExcelDownload } from '../../../ExcelGenerator'
import { ReportsOutlineBtn } from '../../../ReportsOutlineBtn'
import { ScopeContext } from '../../../../scopes/ScopeContext'
import { SingleDownloadPDf } from '../../../PDFgenerator/SingleDownloadPDF'
import { useToast } from '@veneer/core'

export const ReportDetailsHeader = (props) => {
  const { WRITE_SCOPE } = useContext(ScopeContext)
  const { t } = useI18n()
  const [enableDownload, setEnableDDownload] = useState(false)
  const [singlePDFDownload, setSinglePDFDownload] = useState(false)
  const toast = useToast()
  const { addToast } = toast

  const {
    title,
    downloadData,
    hideButton,
    showXlsx,
    fileName,
    exportData,
    headers
  } = props

  let i = 0
  return (
    <Wrapper.ReportDetailsHeader
      data-testid={'device_Details_Header_' + title}
      id={'device_Details_Header_' + title}
    >
      <Wrapper.ReportDetailsTitle
        data-testid={'device_Details_title_' + title}
        id={'device_Details_title_' + title}
      >
        {title}
      </Wrapper.ReportDetailsTitle>
      <Wrapper.ButtonContainer data-testid="device_Details_Button_Container">
        {WRITE_SCOPE && (
          <>
            {!hideButton && (
              <>
                <ReportsOutlineBtn
                  data-testid={'reports_Button_SaveAsPDF' + { title }}
                  id={'reports_Button_SaveAsPDF' + { title }}
                  text={t('ecp-global-reports.button.saveasPDF', 'Save as PDF')}
                  hideButton
                  onClick={() => {
                    addToast({
                      id: 'download-single-pdf' + ++i,
                      type: 'informative',
                      text: t(
                        'ecp-global-reports.toastNotification.downloadingInprogressText',
                        'Report download in process ...'
                      ),
                      timeout: 3
                    })
                    setSinglePDFDownload(true)
                  }}
                  width="120px"
                />
                {singlePDFDownload && (
                  <SingleDownloadPDf
                    setSinglePDFDownload={setSinglePDFDownload}
                    downloadData={downloadData}
                  />
                )}
                {showXlsx && (
                  <ReportsOutlineBtn
                    data-testid={'reports_Button_SaveAsXlsx' + { title }}
                    id={'reports_Button_SaveAsXlsx' + { title }}
                    text={t(
                      'ecp-global-reports.button.saveasXLSX',
                      'Save as XLSX'
                    )}
                    width="127px"
                    onClick={() => {
                      addToast({
                        id: 'download-excel' + ++i,
                        type: 'informative',
                        text: t(
                          'ecp-global-reports.toastNotification.downloadingInprogressText',
                          'Report download in process ...'
                        ),
                        timeout: 3
                      })
                      setEnableDDownload(true)
                    }}
                  />
                )}
              </>
            )}
            {enableDownload && (
              <ExcelDownload
                setEnableDDownload={setEnableDDownload}
                data={exportData}
                fileName={fileName}
                headers={headers}
              />
            )}
          </>
        )}
      </Wrapper.ButtonContainer>
    </Wrapper.ReportDetailsHeader>
  )
}
