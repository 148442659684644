import styled from 'styled-components'

export const ReportDetailsMainCard = styled.div`
  /* display: flex; */
`

export const ReportDetailsList = styled.ul`
  /* padding: 0px 8px 12px; */
`

export const ReportDetailsListItem = styled.li`
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 136px 1fr;
  align-items: flex-start;
  gap: 12px;
`

export const ReportDetailsListItemLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  color: #737373;
`

export const ReportDetailsListItemDesc = styled.span`
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`

export const DeviceImageWrapper = styled.div`
  width: 144px;
  height: 144px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

export const ReportDetailsTop = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const DeviceDetailsBottom = styled.div`
  margin-top: 20px;
`

export const DeviceDetailsList = styled.ul`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

export const DeviceDetailsListItem = styled.li`
  display: flex;
  flex-flow: column;
  padding-right: 16px;
  border-right: 2px solid #ebebeb;
  &:last-child {
    border-right: none;
  }
`
export const DeviceDetailsListItemTitle = styled.label`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #737373;
`

export const DeviceDetailsListItemDesc = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: #212121;

  display: flex;
  align-items: center;
`

export const DeviceStatusDot = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: ${(props) => props.Color};
  margin-right: 4px;
`
