//Shareable component for all the preview Main content Accordian
import React, { useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Colors } from '../../../../utils/consts'
import * as Wrapper from './styles'
import { IconWarningAlt } from '@veneer/core'
import { Tablei18nText } from '../../../../utils/consts'

export const SampleAccordianContent = (props) => {
  const { data } = props
  const [dataItems, setDataItems] = useState(data)
  const { t } = useI18n()

  useEffect(() => {
    setDataItems(data)
  }, [data])

  const getContent = (item) => {
    return item?.map((items) => (
      <>
        <Wrapper.ContentContainer>
          <Wrapper.LeftContainer>
            <span>
              <Wrapper.ReportRiskColorIndicatorItems
                backgroundColor={Colors[items.risk]}
              ></Wrapper.ReportRiskColorIndicatorItems>
            </span>
            <Wrapper.LeftTextArea>{items.col1}</Wrapper.LeftTextArea>
          </Wrapper.LeftContainer>
          <Wrapper.MiddleTextArea>{items.col2}</Wrapper.MiddleTextArea>
          <div>{items.col3}</div>
        </Wrapper.ContentContainer>
        {items.subItems
          ? items.subItems.map((subItem) => (
              <Wrapper.ContentContainer>
                <div>
                  <Wrapper.LeftTextAreaSubItem>
                    {subItem.col1}
                  </Wrapper.LeftTextAreaSubItem>
                </div>
                <Wrapper.MiddleTextArea>{subItem.col2}</Wrapper.MiddleTextArea>
                <div>{subItem.col3}</div>
              </Wrapper.ContentContainer>
            ))
          : ''}
      </>
    ))
  }

  const handleExpand = (event, index, item) => {
    const updatedData = [...dataItems]
    updatedData[index].expanded = true
    setDataItems(updatedData)
  }

  const handleCollapse = (event, index, item) => {
    const updatedData = [...dataItems]
    updatedData[index].expanded = false
    setDataItems(updatedData)
  }

  return (
    <>
      {' '}
      <Wrapper.StatusContainer>
        <Wrapper.ReportRiskLabel>
          <div data-testid="device_Report_Passed">
            <Wrapper.ReportRiskColorIndicator
              backgroundColor={Colors.passed}
            ></Wrapper.ReportRiskColorIndicator>
            {t(`ecp-global-reports.risks.passed`, 'Passed')}
          </div>
          <div data-testid="device_Report_Lowrisk">
            <Wrapper.ReportRiskColorIndicator
              backgroundColor={Colors.low}
            ></Wrapper.ReportRiskColorIndicator>
            {t('ecp-global-reports.risks.lowRisk', 'Low Risk')}
          </div>
          <div data-testid="device_Report_Medrisk">
            <Wrapper.ReportRiskColorIndicator
              backgroundColor={Colors.medium}
            ></Wrapper.ReportRiskColorIndicator>
            {t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')}
          </div>
          <div data-testid="device_Report_Highrisk">
            <Wrapper.ReportRiskColorIndicator
              backgroundColor={Colors.high}
            ></Wrapper.ReportRiskColorIndicator>
            {t('ecp-global-reports.risks.highRisk', 'High Risk')}
          </div>
        </Wrapper.ReportRiskLabel>
      </Wrapper.StatusContainer>
      <>
        <Wrapper.AccordianReport
          data-testid="device_Accordian_Report"
          appearance="outlined"
          onCollapse={handleCollapse}
          onExpand={handleExpand}
          gutter={8}
          items={
            dataItems.length
              ? dataItems?.map((items) => ({
                  content: getContent(items?.innerItems),
                  header: {
                    centralArea: items?.centralArea,
                    endArea: items?.endArea
                  },
                  expanded: items?.expanded
                }))
              : [
                  {
                    header: {
                      centralArea: '--',
                      endArea: ''
                    },
                    expanded: true,
                    content: (
                      <div
                        data-testid="device_Accordian_Report_Content"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column'
                          // margin: '5% 150%'
                        }}
                      >
                        <span
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            backgroundColor: '#DBDBDB',
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%'
                          }}
                        >
                          <IconWarningAlt />
                        </span>

                        <p
                          style={{
                            paddingTop: '8px',
                            alignSelf: 'center',
                            whiteSpace: 'nowrap',
                            color: '#404040'
                          }}
                        >
                          {Tablei18nText(t).noItems}
                        </p>
                      </div>
                    )
                  }
                ]
          }
          id="accordion-one-item"
        ></Wrapper.AccordianReport>
      </>
    </>
  )
}
