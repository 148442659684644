//Shareable component for all the preview Main card reports
import React, { useContext, useState } from 'react'
import * as Wrapper from './styles'
import { ReportsOutlineBtn } from '../../../ReportsOutlineBtn'
import { ScopeContext } from '../../../../scopes/ScopeContext'
import { SingleDownloadPDf } from '../../../PDFgenerator/SingleDownloadPDF'
import { useToast } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'

export const ReportDetailsTopCardHeader = (props) => {
  const { WRITE_SCOPE } = useContext(ScopeContext)
  const { title, downloadData, hideButton } = props
  const [singlePDFDownload, setSinglePDFDownload] = useState(false)
  const toast = useToast()
  const { addToast } = toast
  const { t } = useI18n()

  return (
    <Wrapper.ReportDetailsHeader data-testid="reports_Details_TopCard_Header">
      <Wrapper.ReportDetailsTitle data-testid="reports_Details_TopCard_Title">
        {title}
      </Wrapper.ReportDetailsTitle>
      {WRITE_SCOPE && (
        <>
          {!hideButton && (
            <Wrapper.ButtonContainer data-testid="reports_Details_TopCard_Button_Container">
              <ReportsOutlineBtn
                data-testid="reports_Details_TopCard_Button_SaveasPDF"
                text={t('ecp-global-reports.button.saveasPDF', 'Save as PDF')}
                onClick={() => {
                  setSinglePDFDownload(true)
                  addToast({
                    id: 'download-single-pdf',
                    type: 'informative',
                    text: t(
                      'ecp-global-reports.toastNotification.downloadingInprogressText',
                      'Report download in process ...'
                    ),
                    timeout: 3
                  })
                }}
                width="120px"
              />
              {singlePDFDownload ? (
                <SingleDownloadPDf
                  setSinglePDFDownload={setSinglePDFDownload}
                  downloadData={downloadData}
                />
              ) : (
                ''
              )}
              {/* <ReportsOutlineBtn text="Save as XLSX" disable width="127px" /> */}
            </Wrapper.ButtonContainer>
          )}
        </>
      )}
    </Wrapper.ReportDetailsHeader>
  )
}
