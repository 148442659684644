import { Colors } from '../../../../utils/consts'

const CategoryList = (data, t) => {
  return data
    ? [
        {
          CategoryTitle: '',
          CategoryCount: data.total,
          DisplayCount: false,
          data: [
            {
              Item: 'compliant',
              ItemDisplayText: t(`ecp-global-reports.risks.passed`, 'Passed'),
              SubItem: [],
              Count: data.compliant.toString(),
              Color: Colors.passed
            },
            {
              Item: 'highRisk',
              ItemDisplayText: t(
                `ecp-global-reports.risks.highRiskFailure`,
                'High Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant.highRisk.toString(),
              Color: Colors.high
            },
            {
              Item: 'mediumRisk',
              ItemDisplayText: t(
                `ecp-global-reports.risks.mediumRiskFailure`,
                'Medium Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant.mediumRisk.toString(),
              Color: Colors.medium
            },
            {
              Item: 'lowRisk',
              ItemDisplayText: t(
                `ecp-global-reports.risks.lowRiskFailure`,
                'Low Risk'
              ),
              SubItem: [],
              Count: data.nonCompliant.lowRisk.toString(),
              Color: Colors.low
            },
            {
              Item: 'notAssessed',
              ItemDisplayText: t(
                `ecp-global-reports.risks.notAssessed`,
                'Not Assessed'
              ),
              SubItem: [],
              Count: (
                data.notAssessed.disconnected + data.notAssessed.unknown
              ).toString(),
              Color: Colors.notAssessed
            }
          ]
        }
      ]
    : [
        {
          CategoryTitle: 'No Data Available',
          CategoryCount: '--',
          DisplayCount: false,
          data: []
        }
      ]
}
export default CategoryList
