import React, { useContext } from 'react'
import * as Wrapper from './styles'
import { ScopeContext } from '../../scopes/ScopeContext'

export const GenerateReportSelect = (props) => {
  const { WRITE_SCOPE } = useContext(ScopeContext)

  const {
    disabled,
    setOptionValue,
    optionValue,
    optionsList,
    label,
    placeholder,
    error,
    setloadingSampleImg
  } = props
  let labelid =
    `reports_Generate_` + label?.replace('*', '')?.split(' ')?.join('')

  return (
    <Wrapper.GenerateReportInputContainer error={error}>
      <Wrapper.GenerateReportInputLabel
        data-testid={`${labelid}_Label`}
        id={`${labelid}_Label`}
        style={{ color: `${disabled ? '#ADADAD' : '#404040'}` }}
      >
        {label}
      </Wrapper.GenerateReportInputLabel>
      <Wrapper.GenerateReportSelectOption
        id={`${labelid}_Select`}
        data-testid={`${labelid}_Select`}
        options={optionsList}
        required
        {...(optionValue.length === 0 ? { helperText: placeholder } : {})}
        placeholder={placeholder}
        clearIcon={false}
        onChange={({ value: v }) => {
          setOptionValue([v])
          if (setloadingSampleImg) {
            setloadingSampleImg(true)
            setTimeout(() => {
              setloadingSampleImg(false)
            }, 13000)
          }
        }}
        error={error}
        disabled={!WRITE_SCOPE || disabled}
      />
    </Wrapper.GenerateReportInputContainer>
  )
}
