//Shareable component for all the preview Main card reports
import React, { useEffect, useState } from 'react'
import * as Wrapper from './styles'
import { NamingConvention } from '../../../../utils/commonMethods'
import { Colors } from '../../../../utils/consts'
import { IconPrinterCheckmark, Tooltip } from '@veneer/core'

export const ReportDetailsDesc = (props) => {
  const {
    reportDatas,
    reportDataImg,
    reportDeviceDatas,
    policyDetails,
    imgDisplay
  } = props
  const [checkImgDisplay, setCheckImgDisplay] = useState(imgDisplay)
 
  useEffect(() => {
    if (checkImgDisplay === false) return
    else setCheckImgDisplay(true)
  }, [imgDisplay])
  return (
    <Wrapper.ReportDetailsMainCard data-testid="device_Details_Main_Card">
      <Wrapper.ReportDetailsTop data-testid="device_Details_Main_TopCard">
        {checkImgDisplay && (
          <div>
            {reportDataImg ? (
              <Wrapper.DeviceImageWrapper data-testid="device_Details_Main_Top_DeviceImage">
                <img src={reportDataImg} data-testid="device-inset-img" />
              </Wrapper.DeviceImageWrapper>
            ) : (
              <Wrapper.DeviceImageWrapper data-testid="device_Details_Main_Top_DeviceImage">
                <IconPrinterCheckmark data-testid="device-img" size={120} />
              </Wrapper.DeviceImageWrapper>
            )}
          </div>
        )}
        <Wrapper.ReportDetailsList data-testid="device_Details_List">
          {reportDatas &&
            reportDatas.map((reportData, i) => {
              return (
                <Wrapper.ReportDetailsListItem
                  data-testid="device_Details_Top_List_Item"
                  id="device_Details_Top_List_Item"
                  key={i}
                >
                  <Wrapper.ReportDetailsListItemLabel
                    data-testid={
                      'device_Details_Top_List_Item_' + reportData.title
                    }
                    id={'device_Details_Top_List_Item_' + reportData.title}
                  >
                    {reportData.title}
                  </Wrapper.ReportDetailsListItemLabel>

                  <Wrapper.ReportDetailsListItemDesc
                    data-testid={
                      'device_Details_Top_List_Item_' +
                      reportData.title +
                      '_Value'
                    }
                    id={
                      'device_Details_Top_List_Item_' +
                      reportData.title +
                      '_Value'
                    }
                  >
                    {reportData.desc}
                  </Wrapper.ReportDetailsListItemDesc>
                </Wrapper.ReportDetailsListItem>
              )
            })}
        </Wrapper.ReportDetailsList>
      </Wrapper.ReportDetailsTop>
      {reportDeviceDatas && (
        <Wrapper.DeviceDetailsBottom data-testid="device_Details_Sub_List_Item">
          <Wrapper.DeviceDetailsList>
            {reportDeviceDatas.map((reportDeviceData) => {
              let component
              switch (reportDeviceData.id) {
                case 'passed':
                  component = (
                    <Wrapper.DeviceStatusDot
                      data-testid="device_Details_Status_Dot_Passed"
                      Color={Colors.green6}
                    ></Wrapper.DeviceStatusDot>
                  )
                  break
                case 'lowRisk':
                  component = (
                    <Wrapper.DeviceStatusDot
                      data-testid="device_Details_Status_Dot_LowRisk"
                      Color={Colors.low}
                    ></Wrapper.DeviceStatusDot>
                  )
                  break
                case 'mediumRisk':
                  component = (
                    <Wrapper.DeviceStatusDot
                      data-testid="device_Details_Status_Dot_MediumRisk"
                      Color={Colors.medium}
                    ></Wrapper.DeviceStatusDot>
                  )
                  break
                case 'highRisk':
                  component = (
                    <Wrapper.DeviceStatusDot
                      data-testid="device_Details_Status_Dot_HighRisk"
                      Color={Colors.high}
                    ></Wrapper.DeviceStatusDot>
                  )
                  break
                default:
                  component = <div></div>
              }

              return (
                <Wrapper.DeviceDetailsListItem
                  data-testid="device_Details_Bottom_List_Item"
                  key={reportDeviceData.title}
                >
                  <Wrapper.DeviceDetailsListItemTitle data-testid="device_Details_Bottom_List_Item_Title">
                    {reportDeviceData.title}
                  </Wrapper.DeviceDetailsListItemTitle>
                  <Wrapper.DeviceDetailsListItemDesc data-testid="device_Details_Bottom_List_Item_Desc">
                    {reportDeviceData.key === 'assessmentstatus'
                      ? component
                      : null}
                    {reportDeviceData.key === 'assessmentstatus' ? (
                      NamingConvention(reportDeviceData?.desc || '')
                    ) : reportDeviceData?.key === 'policy' ? (
                      <Tooltip
                        arrow
                        content={<>{policyDetails?.policyTooltip || '--'}</>}
                        id="policy-tooltip"
                        placement="bottom"
                      >
                        <span>{policyDetails?.policyString || '--'}</span>
                      </Tooltip>
                    ) : (
                      reportDeviceData?.desc
                    )}
                  </Wrapper.DeviceDetailsListItemDesc>
                </Wrapper.DeviceDetailsListItem>
              )
            })}
          </Wrapper.DeviceDetailsList>
        </Wrapper.DeviceDetailsBottom>
      )}
    </Wrapper.ReportDetailsMainCard>
  )
}
