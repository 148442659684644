import React from 'react'
import { ListItem, List } from '@veneer/core'
import highchartsInterface, {
  Chart,
  ChartProvider
} from '@veneer/highcharts-interface'
import { Colors } from '../../utils/consts'
import * as Wrapper from './style'

function countTotalOfCategories(categories) {
  let total = 0
  for (let i = 0; i < categories.length; i++) {
    total += categories[i].CategoryCount
  }
  return total
}
const Donut = (props) => {
  const { data, type, t } = props
  const header = data[0].Title
  const subHeader = data[0].Subtitle
  const donutText = data[0].DonutDiplayText
  const donuSubText = data[0].DonutDiplaySubText
  const categories = data[0].CategoryList
  const dataForDonut = []
  const totalCountOfAllCategories = countTotalOfCategories(categories)

  const donutColors = []
  const Square = (color) => {
    return (
      <Wrapper.ReportsDonutPieSymbol
        data-testid="reports-pie-legends-Symbol"
        style={{
          backgroundColor: color
        }}
      />
    )
  }
  const modified = []
  for (let i = 0; i < categories.length; i++) {
    let item
    const categoryItem = (
      <Wrapper.ReportsDonutDisplayCount>
        {categories[i].DisplayCount
          ? categories[i].CategoryTitle + ': ' + categories[i].CategoryCount
          : categories[i].CategoryTitle}
      </Wrapper.ReportsDonutDisplayCount>
    )
    modified.push(categoryItem)
    for (let j = 0; j < categories[i].data.length; j++) {
      const dataItem = categories[i].data[j]
      const itemTotal =
        dataItem.Count == undefined ? undefined : parseInt(dataItem.Count, 10)
      dataForDonut.push([dataItem.ItemDisplayText, itemTotal])
      donutColors.push(dataItem.Color)
      const percent =
        totalCountOfAllCategories > 0
          ? (itemTotal * 100) / totalCountOfAllCategories
          : 0
      item = (
        <div>
          <ListItem
            key={dataItem.ItemDisplayText}
            className="list-item"
            style={{ borderBottom: 0, padding: 0 }}
            leadingArea={Square(dataItem.Color)}
            centerArea={
              <Wrapper.ReportsListItem1>
                {`
                ${dataItem.ItemDisplayText}: ${
                  itemTotal === undefined
                    ? '--'
                    : `${percent.toFixed(2)}% (${itemTotal})`
                }
                `}
              </Wrapper.ReportsListItem1>
            }
            trailingArea={''}
          />
        </div>
      )
      modified.push(item)
      for (let k = 0; k < dataItem.SubItem.length; k++) {
        const subItem = dataItem.SubItem[k]
        const subItemCount =
          subItem.SubItemCount == undefined
            ? undefined
            : parseInt(subItem.SubItemCount, 10)
        const subItemModified = (
          <div>
            <ListItem
              key={subItem.ItemDisplayText}
              className="list-item"
              style={{ borderBottom: 0, padding: 0 }}
              leadingArea={Square('transparent')}
              centerArea={
                <Wrapper.ReportsListItem2>
                  {`${subItem.ItemDisplayText} :${
                    subItemCount === undefined ? '--' : `(${subItemCount})`
                  }`}
                </Wrapper.ReportsListItem2>
              }
              trailingArea={''}
            />
          </div>
        )
        modified.push(subItemModified)
      }
    }
    const space = <Wrapper.ReportsSpacing />
    modified.push(space)
  }
  return (
    <Wrapper.ReportsDonutComponent>
      <Wrapper.ReportsPDFDonut>
        {header !== '' ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Wrapper.ReportsDonutPieChartHeader data-testid="reports-pie-header">
              {header}
            </Wrapper.ReportsDonutPieChartHeader>

            <Wrapper.ReportsDonutPieChartSubHeader data-testid="reports-pie-subheader">
              {subHeader}
            </Wrapper.ReportsDonutPieChartSubHeader>
          </div>
        ) : (
          false
        )}

        <Wrapper.ReportsChart type={type}>
          <Wrapper.ReportsDonutPieChartComponent>
            <Wrapper.ReportsDonutPieChart data-testid="reports-pie-chart">
              <ChartProvider chartInterface={highchartsInterface}>
                {totalCountOfAllCategories > 0 ? (
                  <Chart
                    options={{
                      chart: {
                        type: 'donut',
                        height: '100%'
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      plotOptions: {
                        series: {
                          animation: false
                        },
                        pie: {
                          innerSize: '85%',
                          size: '100%',
                          colors: [...donutColors],
                          states: {
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: donutText,
                        style: { fontSize: '24px' },
                        y: 12
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          fontSize: '6px',
                          color: Colors.gray5,
                          lineHeight: '6px',
                          height: '30px'
                        },
                        y: 15
                      },
                      series: [
                        {
                          name: data[0].Title,
                          data: dataForDonut
                        }
                      ]
                    }}
                  />
                ) : (
                  <Chart
                    data-testid="reports-pie-chart-withNoDevices"
                    options={{
                      chart: {
                        type: 'donut',
                        height: '100%'
                      },
                      legend: {
                        layout: 'vertical'
                      },
                      plotOptions: {
                        series: {
                          animation: false
                        },
                        pie: {
                          innerSize: '85%',
                          size: '100%',
                          colors: [Colors.gray1],
                          states: {
                            inactive: {
                              opacity: 0.2
                            }
                          }
                        }
                      },
                      title: {
                        text: donutText,
                        style: { fontSize: '24px' },
                        y: 12
                      },
                      subtitle: {
                        text: donuSubText,
                        style: {
                          fontSize: '6px',
                          color: Colors.gray5,
                          lineHeight: '6px',
                          height: '30px'
                        },
                        y: 15
                      },
                      series: [
                        {
                          name: t(
                            'ecp-global-reports.common.noDevice',
                            'No Device'
                          ),
                          data: [['notassessed', 1]]
                        }
                      ]
                    }}
                  />
                )}
              </ChartProvider>
            </Wrapper.ReportsDonutPieChart>
          </Wrapper.ReportsDonutPieChartComponent>

          <Wrapper.ReportsDonutLegendsList>
            <List>
              <Wrapper.ReportsDonutLegends data-testid="reports-pie-chart-legends">
                {modified}
              </Wrapper.ReportsDonutLegends>
            </List>
          </Wrapper.ReportsDonutLegendsList>
        </Wrapper.ReportsChart>
      </Wrapper.ReportsPDFDonut>
    </Wrapper.ReportsDonutComponent>
  )
}
export default Donut
