import styled from 'styled-components'

export const ReportsRiskDot = styled.span`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: inline-flex;
  margin-right: 12px;
  background-color: ${(props) => props.Color};
`

export const List = styled.li`
  width: 200px;
  border: 1px solid rgb(0, 0, 0);
  box-sizing: border-box;
`

export const ListItemTitle = styled.span`
  display: block;
  padding: 6px 9px;
  border: 1px solid rgb(0, 0, 0);
`

export const ListItem = styled.span`
  display: block;
  padding: 6px 9px;
  border: 1px solid rgb(0, 0, 0);
`

export const RiskTableList = styled.ul`
  border: 1px solid #c4c4c4;
  border-radius: 12px;
`

export const RiskTableListItem = styled.li`
  border-radius: 12px 12px 0px 0px;
  padding: 10px 20px;
  display: flex;
`

export const RiskTableHeader = styled(RiskTableListItem)`
  background-color: #c4c4c4;
  font-size: 14px;
  letter-spacing: 0.04em;
  padding: 10px 20px;
`

export const RiskTableBody = styled.div`
  padding: 8px 0px 18px;
`

export const RiskTableRow = styled(RiskTableListItem)`
  background-color: #ffffff;
`

export const RiskTableCol = styled.div`
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  margin-right: 16px;
`

export const RiskTableFlexCol = styled.div`
  display: flex;
  align-items: center;
`
export const RiskTableLabel = styled(RiskTableCol)`
  color: #fff;
  background-color: ${(props) => props.Color};
  border-radius: 12px;
  width: 116px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`

export const RiskTableCol1 = styled(RiskTableCol)`
  flex-basis: 200px;
  display: flex;
  align-items: center;
`

export const RiskTableCol2 = styled(RiskTableCol)`
  flex-basis: 180px;
`

export const RiskTableCol3 = styled(RiskTableCol)`
  flex-basis: 125px;
`

export const RiskTableCol4 = styled(RiskTableCol)`
  flex-basis: 125px;
`

export const RiskTableHeaderCol = styled(RiskTableCol)`
  font-size: 14px;
`

export const RiskTableHeaderCol1 = styled(RiskTableHeaderCol)`
  flex-basis: 200px;
`

export const RiskTableHeaderCol2 = styled(RiskTableHeaderCol)`
  flex-basis: 180px;
`

export const RiskTableHeaderCol3 = styled(RiskTableHeaderCol)`
  flex-basis: 125px;
`

export const RiskTableHeaderCol4 = styled(RiskTableHeaderCol)`
  flex-basis: 125px;
`

export const RiskTableFooter = styled.div`
  padding: 18px 0px 8px;
  border-top: 1px solid #c4c4c4;
`
