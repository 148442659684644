import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'

import { SummaryTopCard } from '../../Common/SummaryTopCard'
import { PolicyItemAssesedCard } from '../../Common/PolicyItemAssesedCard'
// import { DeviceFirmwareSecurityAssessmentDetails } from './DeviceFirmwareSecurityAssessmentDetails'
import { Footer } from '../../Common/Footer'

import { getDateAndTime, getValue } from '../../../../utils/commonMethods'
import { showSubSetting } from '../../../../utils/consts'
import { formatGroupDetails } from '../../../../utils/genericMethods'

import { useReports } from '../../../../hooks/useReports'
import { useGetPolicyDetails } from '../../../../hooks/useGetPolicyDetails'
import GeneralContext from '../../../../contexts/GeneralContext'

import * as Wrapper from './styles'

export const DevicesAssessedSummary = (props) => {
  const { deviceDetails, reportDetails } = props
  const { stack, locale } = useContext(GeneralContext)
  const { t } = useI18n()

  const { deviceId, reportId, deviceReportId } = useParams()
  const { downLoadingReports } = useReports()

  const [assessmentloading, setAssessmentLoading] = useState(true)
  const [assessmentReport, setAssessmentReport] = useState([])
  const [groupDetails, setGroupDetails] = useState(null)
  const { policyDetails, policyLoading } = useGetPolicyDetails(
    reportId,
    deviceId
  )
  const [customerName, setCustomerName] = useState('--')

  const reloadAssessmentDetails = () => {
    downLoadingReports(deviceReportId, stack)
      .then((response) => {
        const assessData = JSON.parse(response).detailedAssessment
        setCustomerName(JSON.parse(response)?.customerName)
        setGroupDetails(
          formatGroupDetails(JSON.parse(response)?.collectionInfo, t)
        )
        assessData.length &&
          setAssessmentReport(CreateAccordionData(assessData))
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setAssessmentLoading(false)
      })
  }

  const CreateAccordionData = (report) => {
    let accordianData = []
    report.forEach((assessmentOBJ: any, index) => {
      const assessment: any = Object.values(assessmentOBJ)[0]
      const category = assessment[0]?.category.split('-')

      const innerData = assessment.map((assessmentByPolicy: any) => {
        if (showSubSetting[assessmentByPolicy?.settingId])
          return {
            col1: t(
              `ecp-global-reports.settings.${assessmentByPolicy?.settingId}.name`,
              ' '
            ),
            col2: '',
            col3: '',
            risk: assessmentByPolicy?.complianceResult,
            subItems: assessmentByPolicy?.policyItemsAssessment.map(
              (setting) => {
                const commonSettingName = t(
                  `ecp-global-reports.settings.common.${
                    setting.policyItemId.split('.')[1]
                  }`,
                  ''
                )

                return {
                  col1:
                    commonSettingName === ''
                      ? t(
                          `ecp-global-reports.settings.${setting.policyItemId}`,
                          ''
                        )
                      : commonSettingName,
                  col2:
                    setting.assessmentObject.assessmentResult ===
                      'assessSucceeded' ||
                    setting.assessmentObject.assessmentResult ===
                      'remediateSucceeded'
                      ? t(`ecp-global-reports.risks.passed`, 'Passed')
                      : t(
                          `ecp-global-reports.deviceDetails.assessmentResultReason.${setting.assessmentObject.assessmentResultReason}`,
                          ''
                        ),
                  col3:
                    setting.assessmentObject.assessmentResultReason ===
                    'valueMismatch'
                      ? `${t(
                          'ecp-global-reports.tableHeader.policy',
                          'Policy'
                        )}: ${
                          getValue(
                            setting?.assessmentObject?.policyValue,
                            t,
                            setting.policyItemId
                          ) + ', '
                        }${t(
                          'ecp-global-reports.deviceDetails.deviceValue',
                          '"Device: "'
                        )}${getValue(
                          setting?.assessmentObject?.deviceValue,
                          t,
                          setting.policyItemId
                        )}`
                      : setting.assessmentObject.assessmentResult !==
                          'assessSucceeded' &&
                        setting.assessmentObject.assessmentResult !==
                          'remediateSucceeded' &&
                        t(
                          'ecp-global-reports.deviceDetails.assessmentResult.remediateFailed',
                          'Cannot Remediate'
                        )
                }
              }
            )
          }
        else {
          const object =
            assessmentByPolicy.policyItemsAssessment[0].assessmentObject
          return {
            col1: t(
              `ecp-global-reports.settings.${assessmentByPolicy.settingId}.name`,
              ''
            ),
            col2:
              object.assessmentResult === 'assessSucceeded' ||
              object.assessmentResult === 'remediateSucceeded'
                ? t(`ecp-global-reports.risks.passed`, 'Passed')
                : t(
                    `ecp-global-reports.deviceDetails.assessmentResultReason.${object.assessmentResultReason}`,
                    ''
                  ),
            col3:
              object.assessmentResultReason === 'valueMismatch'
                ? `${t(
                    'ecp-global-reports.deviceDetails.deviceValue',
                    '"Device: "'
                  )}
                  ${
                    getValue(object?.deviceValue, t, object.policyItemId) + ', '
                  }

                  ${t('ecp-global-reports.tableHeader.policy', 'Policy')}: 
                  ${getValue(object?.policyValue, t, object.policyItemId)}`
                : object.assessmentResult !== 'assessSucceeded' &&
                  object.assessmentResult !== 'remediateSucceeded' &&
                  t(
                    'ecp-global-reports.deviceDetails.assessmentResult.remediateFailed',
                    'Cannot Remediate'
                  ),
            risk: assessmentByPolicy.complianceResult
          }
        }
      })
      accordianData.push({
        centralArea: (
          <span
            data-testid={'device-report-policyname-' + index}
            id={'device-report-policyname-' + index}
          >
            {t(
              `ecp-global-reports.deviceDetails.accordionHeader.left.${category[0]}`,
              ''
            )}
            {category.length === 2 &&
              t(
                `ecp-global-reports.deviceDetails.accordionHeader.left.${category[1]}`,
                ''
              )}
          </span>
        ),
        endArea: (
          <>
            <span>
              {t(`ecp-global-reports.deviceDetails.accordionHeader.right`)}
              &nbsp;
            </span>
            <Wrapper.DateText
              data-testid={'device_Assessed_Summary_assessedTime' + index}
              id={'device_Assessed_Summary_assessedTime' + index}
            >
              {getDateAndTime(assessment[0].assessedTime || null, locale)}
            </Wrapper.DateText>
          </>
        ),
        expanded: true,
        innerItems: innerData
      })
    })
    return accordianData
  }

  useEffect(() => {
    setAssessmentLoading(true)
    reloadAssessmentDetails()
  }, [deviceId, reportId])

  return (
    <Wrapper.RDContainer
      data-testid="device_Assessed_Summary"
      id="device_Assessed_Summary"
    >
      <Wrapper.RDSubContainer
        data-testid="device_Assessed_Summary_TopCard"
        id="device_Assessed_Summary_TopCard"
      >
        {deviceDetails && reportDetails && groupDetails && (
          <SummaryTopCard
            data-testid="device_Assessed_Summary_TopCard_Info"
            id="device_Assessed_Summary_TopCard_Info"
            reportDetails={reportDetails}
            deviceDetails={deviceDetails}
            policyDetails={policyDetails}
            groupDetails={groupDetails}
            isLoading={assessmentloading || policyLoading}
            customerName={customerName}
          />
        )}
      </Wrapper.RDSubContainer>

      <Wrapper.RDSubContainer
        data-testid="device_Assessed_Summary_PolicyItemsAssessed"
        id="device_Assessed_Summary_PolicyItemsAssessed"
      >
        <PolicyItemAssesedCard
          data-testid="device_Assessed_Summary_PolicyItemsAssessed_Info"
          id="device_Assessed_Summary_PolicyItemsAssessed_Info"
          textInfo={t(
            `ecp-global-reports.deviceDetails.${reportDetails.reportName}`,
            ''
          )}
          data={assessmentReport}
          title={t(
            `ecp-global-reports.reportsType.${reportDetails.reportName}`,
            ''
          )}
          isLoading={assessmentloading}
        />
      </Wrapper.RDSubContainer>

      {/* <Wrapper.RDSubContainer data-testid="device_Assessed_Summary_deviceFirmwareSecurityAssessment">
        <DeviceFirmwareSecurityAssessmentDetails data-testid="device_Assessed_Summary_deviceFirmwareSecurityAssessment_Info"/>
      </Wrapper.RDSubContainer> */}
      <Wrapper.RDSubContainer data-testid="reports_Footer" id="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
