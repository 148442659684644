import React from 'react'
// import GeneralContext from '../../contexts/GeneralContext'
import { Colors } from '../../utils/consts'
import * as Wrapper from './style'
import { IconWarningAlt } from '@veneer/core'
import { Tablei18nText } from '../../utils/consts'
import HpLogo from './HpLogo'
import thirdColumn from './Helper/thirdColumn'

function SummaryHTML(data, t, locale) {
  const array = data.reportData

  const isData =
    array.length === 1
      ? array[0]?.assessmentStatus?.result &&
        array[0]?.deviceDetails?.modelname &&
        array[0]?.deviceDetails?.serialNumber
      : array.length > 0

  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />
        <Wrapper.ReportsBannerTitle>
          <Wrapper.BannerTextContainer>
            <>
              <span data-testid="reports-pdf-bannerTitle">
                <span>
                  {t(
                    'ecp-global-reports.pdfHeader',
                    'Command Center Security Report:'
                  )}
                </span>
              </span>
            </>
            <div style={{ flex: '1' }}>
              <Wrapper.ReportsBannerSubTitle data-testid="reports-pdf-bannerSubTitle">
                {t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')}{' '}
              </Wrapper.ReportsBannerSubTitle>
            </div>
          </Wrapper.BannerTextContainer>
          <Wrapper.ReportsBannerTitle3>
            <Wrapper.ReportsSummaryPDFGenerationTime data-testid="generationTime">
              {data.generatedTime}
            </Wrapper.ReportsSummaryPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 41
                  ? '20px'
                  : '0'
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: ${data.deviceGroup}`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p style={{ wordBreak: 'break-word' }}>
            {`${t('ecp-global-reports.reportDetails.customer', 'Customer')}: ${
              data.customerName
            }`}
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>

      <Wrapper.ReportsPDFIndicator>
        <Wrapper.ReportsDataNullCheck data-testid="reportData-null-check">
          {`${t(`ecp-global-reports.common.Devices`)} (${
            isData ? data.reportData.length : '0'
          })`}
        </Wrapper.ReportsDataNullCheck>

        <Wrapper.ReportsPDFRiskIndicatorText>
          <p data-testid="reports-riskIndicator-Passed">
            <Wrapper.ReportsRiskDot Color={Colors.passed} />
            {t(`ecp-global-reports.risks.passed`, 'Passed')}
          </p>

          <p data-testid="reports-riskIndicator-lowRisk">
            <Wrapper.ReportsRiskDot Color={Colors.low} />
            {t('ecp-global-reports.risks.lowRisk', 'Low Risk')}
          </p>

          <p data-testid="reports-riskIndicator-mediumRisk">
            <Wrapper.ReportsRiskDot Color={Colors.medium} />
            {t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')}
          </p>

          <p data-testid="reports-riskIndicator-highRisk">
            <Wrapper.ReportsRiskDot Color={Colors.high} />
            {t('ecp-global-reports.risks.highRisk', 'High Risk')}
          </p>
        </Wrapper.ReportsPDFRiskIndicatorText>
      </Wrapper.ReportsPDFIndicator>

      <Wrapper.ReportsActivitytable>
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData style={{ paddingLeft: '15px' }}>
              {t('ecp-global-reports.tableHeader.modelName')}
            </Wrapper.ReportsActivitytableData>
            <Wrapper.ReportsActivitytableData>
              {t('ecp-global-reports.tableHeader.serialNumber')}
            </Wrapper.ReportsActivitytableData>
            {data.reportType === 'devicesNotAssessed' ? (
              <Wrapper.ReportsActivitytableData>
                {t('ecp-global-reports.tableHeader.notAssessedReason')}
              </Wrapper.ReportsActivitytableData>
            ) : (
              <Wrapper.ReportsActivitytableData>
                {t(`ecp-global-reports.tableHeader.${data.reportType}`)}
              </Wrapper.ReportsActivitytableData>
            )}
          </tr>
        </Wrapper.ReportsActivitytableHead>

        <tbody>
          {isData ? (
            data.reportData.map((device) => {
              return (
                <tr>
                  <Wrapper.ReportsActivitytableData
                    data-testid="ReportsActivitytableData"
                    style={{
                      display: 'flex',
                      paddingBottom: '1px'
                    }}
                  >
                    <Wrapper.ReportRiskIndicator
                      risk={device?.assessmentStatus?.result}
                    />
                    <>{device.deviceDetails.modelname}</>
                  </Wrapper.ReportsActivitytableData>

                  <Wrapper.ReportsActivitytableData>
                    {device.deviceDetails.serialNumber}
                  </Wrapper.ReportsActivitytableData>

                  <Wrapper.ReportsActivitytableData>
                    {thirdColumn(data.reportType, device, t, locale)}
                  </Wrapper.ReportsActivitytableData>
                </tr>
              )
            })
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '5% 150%'
              }}
            >
              <span style={{ alignSelf: 'center' }}>
                <IconWarningAlt />
              </span>

              <p
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                {Tablei18nText(t).noItems}
              </p>
            </div>
          )}
        </tbody>
      </Wrapper.ReportsActivitytable>
    </Wrapper.ReportsPDFContent>
  )
}
export default SummaryHTML
