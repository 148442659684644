import React, { useMemo } from 'react'
import { RootProvider } from '@jarvis/react-portal-addons'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastProvider } from '@veneer/core'
import { ReportList } from '../ReportList'
import { GenerateDetails } from '../GenerateDetails'
import { ReportDetails } from '../ReportDetails'
import { EditReportDetails } from '../EditReports'
import { ReportSummary } from '../ReportDetails/Summary'
import { PDfbox } from '../PDFgenerator/index'
import { ExcelBox } from '../ExcelGenerator/ExcelBox'
import { ShellProps } from '../../../mfe/types/shell'
import { setProps } from '../../utils/commonMethods'
import GeneralContext from '../../contexts/GeneralContext'
import resources from '../../assets/locale/index'
import { ScopeProvider } from '../../scopes/ScopeContext'
import { GlobalHeader } from '../GlobalHeader'
import '../../styles/global.scss'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

function Reports(props) {
  setProps(props)
  const {
    stack,
    navigation,
    events,
    breadCrumbs: breadcrumbs,
    authProvider,
    localization
  } = props
  const basename = navigation.createHref({ pathname: '/' })
  const GeneralValue = useMemo(
    () => ({
      stack,
      navigation,
      breadcrumbs,
      events,
      authProvider,
      locale: localization?.locale
    }),
    [stack, navigation, events, authProvider, localization]
  )

  return (
    <RootProvider localization={localization} resources={resources}>
      <ScopeProvider accessControl={props.accessControl}>
        <ToastProvider data-testid="reports-toast-provider">
          <GeneralContext.Provider value={GeneralValue}>
            <Router basename={basename}>
              <Switch>
                <Route exact path="/reports">
                  {() => (
                    <>
                      <GlobalHeader
                        titleText={['ecp-global-reports.header', 'Reports']}
                      ></GlobalHeader>
                      <ReportList />
                    </>
                  )}
                </Route>

                <Route exact path="/reports/generate-report">
                  {() => (
                    <>
                      <GlobalHeader
                        titleText={[
                          'ecp-global-reports.generate.header',
                          'Generate Report'
                        ]}
                        disableReload={true}
                      ></GlobalHeader>
                      <GenerateDetails />
                    </>
                  )}
                </Route>

                <Route exact path="/reports/pdf">
                  {() => <PDfbox />}
                </Route>

                <Route exact path="/reports/excel">
                  {() => <ExcelBox />}
                </Route>

                <Route
                  exact
                  path="/reports/:reportCategory/:reportType/:reportId"
                >
                  {() => <ReportDetails />}
                </Route>

                <Route exact path="/reports/edit/:reportId">
                  {() => (
                    <>
                      <GlobalHeader
                        titleText={[
                          'ecp-global-reports.editReport.header',
                          'Edit Report'
                        ]}
                        disableReload={true}
                      ></GlobalHeader>
                      <EditReportDetails />
                    </>
                  )}
                </Route>

                <Route
                  exact
                  path="/reports/:reportCategory/:reportType/:reportId/:deviceId/:deviceReportId"
                >
                  {() => <ReportSummary />}
                </Route>
              </Switch>
            </Router>
          </GeneralContext.Provider>
        </ToastProvider>
      </ScopeProvider>
    </RootProvider>
  )
}

export default Reports
