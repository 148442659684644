import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'

import GeneralContext from '../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { getdownloadDateFormat } from '../../utils/commonMethods'
import { ScopeContext } from '../../scopes/ScopeContext'
import { ThemeProvider } from '@veneer/theme'

export const ContextualFooter = ({
  reportResponse,
  numberOfSelectedItems,
  onDelete,
  onCancel
}) => {
  const { t } = useI18n()
  const { WRITE_SCOPE, DELETE_SCOPE } = useContext(ScopeContext)
  const { navigation } = useContext(GeneralContext)
  const [disableContinue, setdisableContinue] = useState(true)
  const [selectValue, setSelectValue] = useState([])

  const getContextualFooterOptions = () => {
    if (WRITE_SCOPE && DELETE_SCOPE) {
      return [
        {
          label: t('ecp-global-reports.button.saveasPDF', 'Save as PDF'),
          value: 'saveasPDF'
        },
        {
          label: t('ecp-global-reports.button.saveasXLSX', 'Save as XLSX'),
          value: 'saveasXLSX'
        },
        {
          label: t('ecp-global-reports.button.edit', 'Edit'),
          value: 'edit'
        },
        {
          label: t('ecp-global-reports.button.delete', 'Delete'),
          value: 'delete'
        }
      ]
    } else if (WRITE_SCOPE && !DELETE_SCOPE) {
      return [
        {
          label: t('ecp-global-reports.button.saveasPDF', 'Save as PDF'),
          value: 'saveasPDF'
        },
        {
          label: t('ecp-global-reports.button.saveasXLSX', 'Save as XLSX'),
          value: 'saveasXLSX'
        },
        {
          label: t('ecp-global-reports.button.edit', 'Edit'),
          value: 'edit'
        }
      ]
    } else {
      return [
        {
          label: t('ecp-global-reports.button.delete', 'Delete'),
          value: 'delete'
        }
      ]
    }
  }
  const [contextualFooterOptions, setContextualFooterOptions] = useState(
    getContextualFooterOptions()
  )

  const handleBtnAction = () => {
    const leftPosition = window.screen.width / 2 - (612 / 2 + 10)
    const topPosition = window.screen.height / 2 - (266 / 2 + 50)
    switch (selectValue[0]) {
      case 'saveasPDF':
        onCancel()
        const url = `${window.location.origin}${navigation.createHref({
          pathname: '/reports/pdf'
        })}`
        const windowFeatures = `height=196,width=608,resizable=yes,top=${topPosition},left=${leftPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`

        const PDFDownloadWindow = window.open(url, '', windowFeatures)

        PDFDownloadWindow.onbeforeunload = (e) => {
          e.preventDefault()
          return 'Sure?'
        }
        PDFDownloadWindow.opener.data = numberOfSelectedItems.map((report) => {
          return {
            id: report.reportId,
            type: report.type,
            category: report.category,
            reportCategory: report.reportCategory,
            generatedTime: report.time,
            name: report.name,
            lastDays: report.numDays
          }
        })
        break
      case 'saveasXLSX':
        onCancel()
        const urlExcel = `${window.location.origin}${navigation.createHref({
          pathname: '/reports/excel'
        })}`
        const windowFeaturesExcel = `height=196,width=608,resizable=yes,top=${topPosition},left=${leftPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`

        const ExcelDownloadWindow = window.open(
          urlExcel,
          '',
          windowFeaturesExcel
        )

        ExcelDownloadWindow.onbeforeunload = (e) => {
          e.preventDefault()
          return 'Sure?'
        }
        ExcelDownloadWindow.opener.data = numberOfSelectedItems.map(
          (report) => {
            return {
              reportId: report.reportId,
              type: report.type,
              fileName:
                report?.name?.replace('.', '').substring(0, 185) +
                `_${getdownloadDateFormat(report.time)}`,
              name: report?.name
            }
          }
        )
        // const _addExport = numberOfSelectedItems.map((report, index) => (
        //   <ExcelDownload
        //     key={index}
        //     type={report.type}
        //     reportId={report.reportId}
        //     fileName={
        //       report?.name?.replace('.', '').substring(0, 185) +
        //       `_${getdownloadDateFormat(report.time)}`
        //     }
        //     setExcelExportDownload={setExcelExportDownload}
        //   />
        // ))

        // onCancel()
        // setExcelExportDownload(<>{_addExport}</>)

        // // setTimeout(() => {
        // //   setExcelExportDownload(<></>)
        // // }, 10000)

        // setTimeout(() => {
        //   setExcelExportDownload(<></>)
        // }, 2000)

        break
      case 'edit':
        const reportId = numberOfSelectedItems[0]?.reportId
        const selectedReport = reportResponse.filter(
          (obj) => obj.reportId === reportId
        )
        navigation.push(`/reports/edit/${reportId}`, selectedReport[0])
        break
      case 'delete':
        return onDelete()
      default:
        break
    }
    // onCancel()  close contexttual menu
  }

  const noOfItems = (value) => {
    const text =
      value === 1
        ? t('ecp-global-reports.selection.item', 'item')
        : t('ecp-global-reports.selection.items', 'items')
    return `${text} ${t('ecp-global-reports.selection.selected', 'selected')}`
  }

  const UpdateContextualMenu = () => {
    const selectedReportIds = numberOfSelectedItems.map((s) => s.reportId)
    return reportResponse.filter((item) =>
      selectedReportIds.includes(item.reportId)
    )
  }

  useEffect(() => {
    if (selectValue[0] == 'saveasPDF' || selectValue[0] == 'saveasXLSX') {
      for (var row of UpdateContextualMenu()) {
        if (row.status !== 'ready') {
          setdisableContinue(true)
          break
        }
        setdisableContinue(false)
      }
    } else if (selectValue.length == 0) {
      setdisableContinue(true)
    } else {
      setdisableContinue(false)
    }

    if (WRITE_SCOPE) {
      let _temp = [...contextualFooterOptions]
      for (var row of UpdateContextualMenu()) {
        if (
          row.reportName == 'executiveAssessmentSummary' ||
          row.reportName == 'executiveSummary'
        ) {
          _temp.filter((_item) => _item.value == 'saveasXLSX')[0]['disabled'] =
            true
          setContextualFooterOptions(_temp)
          if (selectValue[0] == 'saveasXLSX') setSelectValue(['saveasPDF'])
          break
        }
        _temp.filter((_item) => _item.value == 'saveasXLSX')[0]['disabled'] =
          false
        setContextualFooterOptions(_temp)
      }

      let _count = UpdateContextualMenu().length
      if (_count > 1) {
        _temp.filter((_item) => _item.value == 'edit')[0]['disabled'] = true
        setContextualFooterOptions(_temp)
        if (selectValue[0] == 'edit') setSelectValue(['saveasPDF'])
      } else {
        _temp.filter((_item) => _item.value == 'edit')[0]['disabled'] = false
        setContextualFooterOptions(_temp)
      }
    }
  }, [selectValue, numberOfSelectedItems])

  return (
    <>
      <Wrapper.ReportsContextualFooterMenu>
        <div>
          <li>
            <Wrapper.ReportsContextualLeftCancelButtonBox data-testid="reports-contextual-footer-cancel-button-box">
              <Wrapper.ReportsContextualLeftCancelButton
                id="reports-contextual-footer-cancel-button"
                appearance="secondary"
                onClick={() => onCancel()}
                data-testid="reports-contextual-footer-cancel-button"
              >
                {t('ecp-global-reports.button.cancel', 'Cancel')}
              </Wrapper.ReportsContextualLeftCancelButton>
            </Wrapper.ReportsContextualLeftCancelButtonBox>
            <Wrapper.ReportsContextualItemsSelectedBox>
              <label
                id="reports-contextual-footer-selected-label"
                data-testid="reports-contextual-footer-selected-label"
              >
                {numberOfSelectedItems.length}{' '}
                {noOfItems(numberOfSelectedItems.length)}
              </label>
            </Wrapper.ReportsContextualItemsSelectedBox>
            <Wrapper.ReportsContextualRightActionBox>
              <Wrapper.ReportsContextualRightActionFlexBox>
                <Wrapper.ReportsContextualRightActionSelectBox>
                  <ThemeProvider density="high">
                    <Wrapper.ReportsContextualRightActionSelect
                      options={contextualFooterOptions}
                      label={t(
                        'ecp-global-reports.button.selectAction',
                        'Select an Action'
                      )}
                      data-testid="reports-contextual-footer-select"
                      id="reports-contextual-footer-select"
                      clearIcon={false}
                      //placement='top'
                      value={selectValue}
                      onChange={({ value: v }) => {
                        setSelectValue([v.toString()])
                      }}
                    />
                  </ThemeProvider>
                </Wrapper.ReportsContextualRightActionSelectBox>
                <Wrapper.ReportsContextualRightContinueButtonBox>
                  <Wrapper.ReportsContextualRightContinueButton
                    id="reports-contextual-footer-continue-button"
                    appearance="primary"
                    onClick={() => {
                      handleBtnAction()
                    }}
                    disabled={selectValue.length === 0 || disableContinue}
                    data-testid="reports-contextual-footer-continue-button"
                  >
                    {t('ecp-global-reports.button.continue', 'Continue')}
                  </Wrapper.ReportsContextualRightContinueButton>
                </Wrapper.ReportsContextualRightContinueButtonBox>
              </Wrapper.ReportsContextualRightActionFlexBox>
            </Wrapper.ReportsContextualRightActionBox>
          </li>
        </div>
      </Wrapper.ReportsContextualFooterMenu>
    </>
  )
}
